import React, {useState, useEffect,useRef} from 'react';
import '../../components/courses/productdesing.css';
import { Link } from 'react-router-dom';
import Popup from '../popup/PopUp';
import testyimage from '../../images/testyimage.webp';
import uiuxwallpaper from '../../images/uiuxwallpaper.png';
import uiuxbackground from '../../images/uiuxbackground.png';
import uiuxbackground2 from '../../images/uiuxbackground2.webp';
import uiuxcheck from '../../images/uiuxcheck.png';
import uiuxapply from '../../images/uiuxapply.png';
import uiuxletter from '../../images/uiuxletter.png';
import uiuxcall from '../../images/uiuxcall.png';
import uiuxchat from '../../images/uiuxchat.png';
import ChevronRight from "../../images/chevron_right_blue.png";
import teststory1 from '../../images/teststory1.webp';
import teststory2 from '../../images/teststory2.webp';
import teststory3 from '../../images/teststory3.webp';
import teststory4 from '../../images/teststory4.webp';
import teststory5 from '../../images/teststory5.webp';
import teststory6 from '../../images/teststory6.webp';
import teststory7 from '../../images/teststory7.webp';
import previous from '../../images/backblack.png';
import next from '../../images/forwardblack.png';
import arrow_down from "../../images/arrow_down.png";

const BootcampProductDesign = () => {
    const [scrollPosition, setScrollPosition] = useState(0);
    const [isInView, setIsInView] = useState(false);
  
    
   
const testimonials = [
    {
      text: "My time at Deebug taking the Frontend Web Development course was worth it. The knowledgeable instructors were always helpful. I gained valuable skills, making it a rewarding learning experience.",
      name: "Ononyiwita Godsglory",
      class: "Class of 2024",
      image: teststory4
    },
    {
      text: "“It was a wonderful experience learning my first tech skill at Deebug Institute. What amazed me the most was how the tutors broke down every aspect of what was been taught. They made it seem so easy.”",
      name: "Tunde Oriyomi",
      class: "Class of 2024",
      image: teststory7
    },
    {
      text: "“My time at Deebug taking the Frontend Web Development course was worth it. The knowledgeable instructors were always helpful. I gained valuable skills, making it a rewarding learning experience.”",
      name: "Precious",
      class: "Class of 2024",
      image: teststory3
    },    
    {
      text: "The projects at Deebug helped me build a solid portfolio. I feel prepared to tackle any frontend development challenge.",
      name: "Michael Brown",
      class: "Class of 2024",
      image: teststory5
    }
  ];
  
  
    const [currentIndex, setCurrentIndex] = useState(0);
    const containerRef = useRef(null);
  
    const handleScroll = (direction) => {
      const container = containerRef.current;
      const containerWidth = container.clientWidth;
      const newIndex = direction === 'left'
        ? Math.max(currentIndex - 1, 0)
        : Math.min(currentIndex + 1, testimonials.length - 1);
  
      setCurrentIndex(newIndex);
      container.scrollTo({
        left: newIndex * containerWidth,
        behavior: 'smooth'
      });
    };



    const [showPopup, setShowPopup] = useState(false);

    const openPopup = () => {
        setShowPopup(true);
    };

    const closePopup = () => {
        setShowPopup(false);
    };

    const handleProceed = () => {
        window.location.href = '/BootcampRegistration';
        // Handle proceed action
    };

    const containerRef1 = useRef(null);
    useEffect(() => {
        const observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                entry.target.classList.add('animate');
              } else {
                entry.target.classList.remove('animate');
              }
            });
          },
          { threshold: 0.1 } // Adjust threshold as needed
        );
    
        if (containerRef1.current) {
          observer.observe(containerRef1.current);
        }
    
        return () => {
          if (containerRef1.current) {
            observer.unobserve(containerRef1.current);
          }
        };
      }, []);


        
    const AnimateText = ({ text }) => {
        const textRef = useRef(null);
        const [isInView, setIsInView] = useState(false);
    
        useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                setIsInView(true);
                } else {
                setIsInView(false); // Reset animation when out of view
                }
            });
            },
            { threshold: 0.1 } // Adjust the threshold as needed
        );
    
        if (textRef.current) {
            observer.observe(textRef.current);
        }
    
        return () => {
            if (textRef.current) {
            observer.unobserve(textRef.current);
            }
        };
        }, []);
    
        return (
        <div className="animate-container">
            <h1
            ref={textRef}
            className={`product-animatedText ${isInView ? 'product-animate' : ''}`}
            >
            {text.split('').map((char, index) => (
                <span key={index} style={{ '--char-index': index }}>
                {char}
                </span>
            ))}
            </h1>
        </div>
        );
    };
  

  return (
    <div className='courses-page-main-container'>
        <div className="backend-course-sub-navbar">
            <p>PRODUCT DESIGN</p>
                <div className='backend-nav-apply'>
                    <span  onClick={openPopup} className="backend-course-sub-navbar-selection">Apply</span>
                    {showPopup && (
                        <Popup
                        title="Bootcamp Training Registration"
                        content="Please note that during the registration process for our Bootcamp Training program, you will be required to provide some information."
                        onClose={closePopup}
                        onProceed={handleProceed}
                        />
                    )}
                </div>
        </div>
        <div className='network-course-background'>
            <div className='product-course-bgimg'>
                <div className="App">
                    <AnimateText text="PRODUCT" />
                    <AnimateText text="DESIGN" />
                </div>
                <Link to="/BootcampRegistration" className='apply-now-link'>
                    <div className='course-apply-now' ref={containerRef1}> <button >Apply now</button> 
                        <img
                            src={arrow_down}
                            width="35px"
                            height="35px"
                            alt='icon'
                        /> 
                    </div>                
                </Link>
            </div>
        </div>
        <div className='product-course-content'>
            <p>Course details</p>
            <h1>Everything you need to kick-start your journey</h1>
            <div className='product-price-content'>
                <div className='product-price-details'>
                    <h1>65<span>k</span></h1>
                    <span>Price(Regular)</span>
                </div>
                <div className='product-price-details'>
                    <h1>52<span>k</span></h1>
                    <span>Price(IT)</span>
                </div>
                <div className='product-price-details'>
                    <h1>3<span>:</span>4</h1>
                    <span>Months:hrs per week</span>
                </div>
            </div>
            <div className='product-course-about'>
                <h1>Overview</h1>
                <p>
                    This course teaches the key principles and practical skills
                    all UX/UI Designers use for creating engaging and user-friendly 
                    digital experiences.  Throughout the course, you'll explore the 
                    fundamentals of user experience (UX) and user interface (UI) design, 
                    gaining insights into user psychology, design thinking methodologies, 
                    and industry best practices. 
                </p>
                <p>
                    You will also gain hands-on experience with industry-standard tools 
                    like Figma, Adobe Illustrator, and more. You'll receive comprehensive 
                    training in Figma, a powerful collaborative interface design tool used 
                    by designers worldwide. Through practical exercises and real-world projects, 
                    you'll master essential Figma features and workflows, allowing you to design,
                    prototype, and collaborate with ease.
                </p>
            </div>
            <div className='ui-ux-background-image'>
                <img
                    src={uiuxbackground}
                    width="100%"
                    height="100%"
                    alt='icon'
                    className='course-background-attract'
                />
            </div>
            <div className='uiux-course-highlights'>
                <h1>Course Highlights</h1>
                <p>
                    You are about to embark on an exciting journey of 
                    learning how to program. For the duration of this course 
                    you will be learning;
                </p>
                <div className='uiux-course-content-highlight'>
                    <div className='course-content-ux'>
                        <img
                            src={uiuxcheck}
                            alt='icon'
                            width="20px"
                            height="20px"
                            className='checkmark-icon'                            
                        />
                        <p>Introduction to UX/UI Design/ User Research</p>
                    </div>
                    <div className='course-content-ux'>
                        <img
                            src={uiuxcheck}
                            alt='icon'
                            width="20px"
                            height="20px"
                            className='checkmark-icon'
                        />
                        <p>Human Centered Design</p>
                    </div>
                    <div className='course-content-ux'>
                        <img
                            src={uiuxcheck}
                            alt='icon'
                            width="20px"
                            height="20px"
                            className='checkmark-icon'
                        />
                        <p>Understanding User Persona</p>
                    </div>
                    <div className='course-content-ux'>
                        <img
                            src={uiuxcheck}
                            alt='icon'
                            width="20px"
                            height="20px"
                            className='checkmark-icon'
                        />
                        <p>Introduction to Figma as a design tool</p>
                    </div>
                    <div className='course-content-ux'>
                        <img
                            src={uiuxcheck}
                            alt='icon'
                            width="20px"
                            height="20px"
                            className='checkmark-icon'
                        />
                        <p>Introduction to user flow journey and Visual Design</p>
                    </div>
                    <div className='course-content-ux'>
                        <img
                            src={uiuxcheck}
                            alt='icon'
                            width="20px"
                            height="20px"
                            className='checkmark-icon'
                        />
                        <p>Interaction Design</p>
                    </div>
                    <div className='course-content-ux'>
                        <img
                            src={uiuxcheck}
                            alt='icon'
                            width="20px"
                            height="20px"
                            className='checkmark-icon'
                        />
                        <p>Deeper into Figma</p>
                    </div>
                    <div className='course-content-ux'>
                        <img
                            src={uiuxcheck}
                            alt='icon'
                            width="20px"
                            height="20px"
                            className='checkmark-icon'
                        />
                        <p>User Testing</p>
                    </div>
                    <div className='course-content-ux'>
                        <img
                            src={uiuxcheck}
                            alt='icon'
                            width="20px"
                            height="20px"
                            className='checkmark-icon'
                        />
                        <p>Building Your Portfolio</p>
                    </div>
                    <div className='course-content-ux'>
                        <img
                            src={uiuxcheck}
                            alt='icon'
                            width="20px"
                            height="20px"
                            className='checkmark-icon'
                        />
                        <p>Choosing a Platform (Behance, Dribble, or Personal Website)</p>
                    </div>
                    <div className='course-content-ux'>
                        <img
                            src={uiuxcheck}
                            alt='icon'
                            width="20px"
                            height="20px"
                            className='checkmark-icon'
                        />
                        <p>A day in the life of a UX designer</p>
                    </div>
                    <div className='course-content-ux'>
                        <img
                            src={uiuxcheck}
                            alt='icon'
                            width="20px"
                            height="20px"
                            className='checkmark-icon'
                        />
                        <p>Capstone Project</p>
                    </div>
                </div>
            </div>
            <div className='ui-ux-background-image'>
                <img
                    src={uiuxbackground2}
                    width="100%"
                    height="100%"
                    alt='icon'
                    className='course-background-attract'
                />
            </div>
            <div className='uiux-course-highlights1'>
                <div className='uiux-course-highlights'>
                    <h1>
                       Learning Outcome
                    </h1>
                    <p>Upon completion, graduates will be able to:</p>
                    <div className='uiux-course-content-highlight'>
                        <div className='course-content-ux'>
                            <img
                                src={uiuxcheck}
                                alt='icon'
                                width="20px"
                                height="20px"
                                className='checkmark-icon'
                            />
                            <p>Master the foundations of UX/UI design, research methodologies, and human-centered design psychology.</p>
                        </div>
                        <div className='course-content-ux'>
                            <img
                                src={uiuxcheck}
                                alt='icon'
                                width="20px"
                                height="20px"
                                className='checkmark-icon'
                            />
                            <p>Develop user personas and conduct user research effectively</p>
                        </div>
                        <div className='course-content-ux'>
                            <img
                                src={uiuxcheck}
                                alt='icon'
                                width="20px"
                                height="20px"                                
                                className='checkmark-icon'
                            />
                            <p>Utilize Figma for designing interactive prototypes and user flows</p>
                        </div>
                        <div className='course-content-ux'>
                            <img
                                src={uiuxcheck}
                                alt='icon'
                                width="20px"
                                height="20px"                                
                                className='checkmark-icon'
                            />
                            <p>Conduct user testing to gather feedback and iterate designs</p>
                        </div>
                        <div className='course-content-ux'>
                            <img
                                src={uiuxcheck}
                                alt='icon'
                                width="20px"
                                height="20px"                                
                                className='checkmark-icon'
                            />
                            <p>Build a professional portfolio showcasing their UX/UI design skills.</p>
                        </div>
                    </div>
                </div>
                <div className='ui-ux-course-content-highlight'>
                    <div className='course-content-ux3'>
                        <p>Ready to embark on this exciting journey? Take the first step toward shaping your future in tech.</p>
                        <img
                            src={uiuxapply}
                            alt='icon'
                            width="70px"
                            height="70px"
                            className='checkmark-apply'
                        />
                    </div>
                </div>
            </div>
        </div>
        <div className='experience-story'>
            <h1>The Experience</h1>
            <p>Don't just take our word for it! Hear what our past students have to say about their experience</p>
            <div className='testimonial-main-container'>
                <div className='testimonial-sub-main-container'>
                    <button className='testimonial-btn' onClick={() => handleScroll('left')}>
                        <img 
                            src={previous} 
                            width="100%" 
                            height="100%" 
                            alt='next'                             
                        />
                    </button>
                    <div className='testimonial-container' ref={containerRef}>
                        {testimonials.map((testimonial, index) => (
                            <div className='testimonial-story' key={index}>
                                <div className='testimonial-text'>
                                    <h1>{testimonial.text}</h1>
                                    <div className='testimonial-personal'>
                                    <h2>{testimonial.name}</h2>
                                    <p>{testimonial.class}</p>
                                    </div>
                                </div>
                                <div className='testimonial-image'>
                                    <img
                                    src={testimonial.image}
                                    width="100%"
                                    height="100%"
                                    alt='testimonial'
                                    className='testy-image'
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                    <button className='testimonial-btn' onClick={() => handleScroll('right')}>
                        <img 
                            src={next} 
                            width="100%" 
                            height="100%" 
                            alt='next'                             
                        />
                    </button>
                    <div className='testimonial-btn-prt-ctn'>
                        <button className='testimonial-btn1' onClick={() => handleScroll('left')}>
                            <img 
                                src={previous} 
                                width="100%" 
                                height="100%" 
                                alt='next'                             
                            />
                        </button>
                        <button className='testimonial-btn1' onClick={() => handleScroll('right')}>
                            <img 
                                src={next} 
                                width="100%" 
                                height="100%" 
                                alt='next'                             
                            />
                        </button>
                    </div>
                </div>
                <div className='testimonial-btn-container2'>
                    {testimonials.map((_, index) => (
                    <button
                        key={index}
                        className={`indicator-btn ${index === currentIndex ? 'active' : ''}`}
                        onClick={() => setCurrentIndex(index)}
                    >
                        <span className="indicator" />
                    </button>
                    ))}
                </div>
            </div>
            <div className='call-course-enquiries'>
                <h1>For More Inquiries</h1>
                    <div className='contact-course-container'>
                    <div className='contact-course1'>
                        <div className='call-course-image'>
                            <img
                                src={uiuxchat}
                                width="100%"
                                height="100%"
                                alt='icon'
                            />
                        </div>
                        <h3>chat</h3>
                        <p>deebug.linkedin</p>
                    </div>
                    <div className='contact-course1'>
                        <div className='call-course-image'>
                            <img
                                src={uiuxcall}
                                width="100%"
                                height="100%"
                                alt='icon'
                            />
                        </div>
                        <h3>Call</h3>
                        <p>+2349162627333</p>
                    </div>
                    <div className='contact-course1'>
                        <div className='call-course-image'>
                            <img
                                src={uiuxletter}
                                width="100%"
                                height="100%"
                                alt='icon'
                            />
                        </div>
                        <h3>Email</h3>
                        <p>info@deebug.org</p>
                    </div>
                </div>
            </div>
            <div className='course-inquiries'>
                <h1>Learn more about education <br /> at Deebug</h1>
                <div className="edu-learn-btn"><Link to="/IndustrialTrainingCourses" className="section_learn_more"> Go to Industrial Training Overview</Link><img src={ChevronRight} className="learn_more_icon" /></div>
                <div className="edu-learn-btn"><Link to="/RegularStudents" className="section_learn_more"> Go to Regular Students</Link><img src={ChevronRight} className="learn_more_icon" /></div>
            </div>
        </div>
    </div>
  )
}

export default BootcampProductDesign