import React from 'react'
import "../career_expedition/careerday.css";
import career from "../../images/careerdayhome.webp";
import video from '../../images/careerthumb.webp';
import ChevronRight from "../../images/chevron_right_blue.png";

const Innovative_Showcase = () => {
  return (
    <div className='career-main'>
        <div className='background-container1'>
            <div className='background-text'>
                <h1>Innovative Showcase</h1>
                <p>Engage in insightful discussions, gain valuable industry insights, and chart your career path in the tech world.</p>
            </div>
        </div>
        <div className='career-day-contents'>
            <p>
            A brilliant event that celebrates the creativity and problem-solving skills of our students. It's a platform where students present their innovative projects and ideas, ranging from technology advancements to coding breakthroughs to innovative app development and creative designs. The showcase offers an opportunity for our students to share their challenges, and insights gained during their learning journey. 
            </p>            
            <hr className='career-divider'/>
            {/* <div className='career-video'>
                <img
                    src={video}
                    width="100%"
                    height="100%"
                    alt='video'
                />
            </div>
            <div className='relive-career'>
                <h1>Watch the Showcase</h1>
                <p>Watch as our students present their cutting-edge projects, ideas and creations to a global audience</p>
            </div> */}
        </div>
        <div className='career-more'>
            <h1>Learn more about our <br /> Events</h1>
            <div className='more-events'>
                <a href='/events'>
                    Go to events
                </a>
                <img
                    src={ChevronRight}
                    className='learn_more_icon'
                    alt='icon'
                />
            </div>
        </div>
    </div>
  )
}

export default Innovative_Showcase