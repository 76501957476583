import React, {useState} from 'react';
import './ethics.css';
import responsivebtn from '../../images/hmbbtn.png';

const Third_Parties = () => {
    const [isOpen, setIsOpen] = useState(false);
    
    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };


  return (
    <div className='third-parties-main'>
        <div className='ethics-top-container'>
            <div className='ethics-topbar'>
                <h1>Ethics and Compliance</h1>
                <ul className={isOpen ? "ethics-menu active" : "ethics-menu"}>
                    <li> <a href='/ThirdParties' className='ethics-link'>Third Parties</a></li>
                    <li> <a href='/' className='ethics-link'>Speak Up</a></li>
                </ul>
                <div className="ethics-ham-btn">
                    <img 
                        src={responsivebtn}
                        width="100%"
                        height="100%"
                        alt='icon'
                        onClick={toggleMenu}
                    />
                </div>
            </div>
            <div className='third-parties-about'>
                <h1>Working with Deebug</h1>
                <p>
                    Deebug  expects that people it does business with  do so with respect, 
                    honesty, integrity, and compliance with it’s business and legal polices 
                    at all times. Read more below.
                </p>
            </div>
        </div>
            <div className='third-parties-content'>
                <div className='third-parties-child'>
                    <h1>Anti-corruption</h1>
                    <p>
                        Deebug is committed to maintaining the highest ethical 
                        standards, especially when it comes to business. Deebug 
                        strictly prohibit any form of corruption, or unethical 
                        practices in our interactions with external partners. 
                        Upholding transparency and integrity
                    </p>
                </div>
                <div className='third-parties-child'>
                    <h1>Due-Diligence</h1>
                    <p>
                        Deebug adheres to a rigorous due diligence process when engaging
                        with third parties. Deebug conducts thorough assessments to ensure 
                        that our partners share our values and comply with legal and ethical 
                        standards. This approach safeguards our integrity and reinforces our 
                        commitment to responsible business conduct
                    </p>
                </div>
            </div>
    </div>
  )
}

export default Third_Parties

