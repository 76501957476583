import React, { useEffect, useState } from "react";
import "./gametournament.css";
import esportbground from "../../images/esportbground.webp";
import gametourney1 from "../../images/gametourney1.png";
import gametourney2 from "../../images/gametourney2.png";
import gametourney3 from "../../images/gametourney3.png";
import gametourney4 from "../../images/gametourney4.webp";
import gametourney5 from "../../images/gametourney5.webp";
import gametourney6 from "../../images/gametourney6.webp";
import gamewinner1 from "../../images/gamewinner1.webp";
import gamewinner2 from "../../images/gamewinner2.webp";
import gamewinner3 from "../../images/gamewinner3.webp";
import gamewinner4 from "../../images/gamewinner4.webp";
import gamewinner5 from "../../images/gamewinner5.webp";
import gamewinner6 from "../../images/gamewinner6.webp";

const Game_Tournament = ({closeModal}) => {
  return (
    <div className="award-modal-overlay">
      <div className="award-modal-content">
      <button className="award-close-button" onClick={closeModal}>X</button>
        <div className='game-tournament-main'>
            <div className='game-tournament-bground'>
              <img
                src={esportbground}
                width="100%"
                height="100%"
                alt='icon'
              />            
            </div>
            <div className='game-tourny-content1'>
              <h1>A Battle for Supremacy</h1>
              <h2>Coming soon</h2>
              <p>
                “A chance to prove your worth and claim victory in an 
                ultimate gaming showdown. A perfect stage to showcase 
                your gaming skills.”
              </p>
            </div>
            <div className='game-tourny-content2'>
              <p>
                Connect and Have Fun
              </p>
              <h1>
                It's not just a competition, it's also a time to have fun and bond
              </h1>
            </div>
            <div className='game-tourny-content3'>
                <div className='game-tourny-image'>
                    <img
                        src={gametourney3}
                        width="100%"
                        height="100%"
                        alt='icon'
                    /> 
                </div>
                <div className='game-tourny-image'>
                    <img
                        src={gametourney2}
                        width="100%"
                        height="100%"
                        alt='icon'
                    /> 
                </div>
                <div className='game-tourny-image'>
                  <img
                    src={gametourney1}
                    width="100%"
                    height="100%"
                    alt='icon'
                  /> 
                </div>
                <div className='game-tourny-image'>
                  <img
                    src={gametourney4}
                    width="100%"
                    height="100%"
                    alt='icon'
                  /> 
                </div>
                <div className='game-tourny-image'>
                  <img
                    src={gametourney5}
                    width="100%"
                    height="100%"
                    alt='icon'
                  /> 
                </div>
                <div className='game-tourny-image'>
                  <img
                    src={gametourney6}
                    width="100%"
                    height="100%"
                    alt='icon'
                  /> 
                </div>
          </div> 
          <div className="game-tourny-content2">
            <p>Masters of the Game</p>
            <h1>
              Meet those who have overcome the odds and emerged at the top of their
              game.
            </h1>
          </div>
          <div className="game-tourny-content3">
              <div className="game-tourney-champ">
                <img 
                  src={gamewinner1} 
                  width="100%" 
                  height="100%" 
                  alt="icon"                     
                />
            </div>
              <div className="game-tourney-champ">
                <img 
                  src={gamewinner2} 
                  width="100%" 
                  height="100%" 
                  alt="icon"                     
                />
            </div>
              <div className="game-tourney-champ">
                <img 
                  src={gamewinner3} 
                  width="100%" 
                  height="100%" 
                  alt="icon"                     
                />
            </div>
              <div className="game-tourney-champ">
                <img 
                  src={gamewinner4} 
                  width="100%" 
                  height="100%" 
                  alt="icon"                     
                />
            </div>
              <div className="game-tourney-champ">
                <img 
                  src={gamewinner5} 
                  width="100%" 
                  height="100%" 
                  alt="icon"                     
                />
            </div>
              <div className="game-tourney-champ">
                <img 
                  src={gamewinner6} 
                  width="100%" 
                  height="100%" 
                  alt="icon"                     
                />
            </div>
          </div>
          <hr className="game-tourny-line" />
          <p className="game-tourny">
            {" "}
            <a href="/events" className="game-tourny-sm">
            See more of Deebug’s events
            </a>
          </p>    
        </div>
      </div>
    </div>
  );
};

export default Game_Tournament;
