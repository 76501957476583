import React from 'react';
import "./careerday.css";
// import career from "../../images/careerdayhome.webp";
import video from '../../images/careerthumb.webp';
import ChevronRight from "../../images/chevron_right_blue.png";

const Career_day = () => {
  return (
    <div className='career-main'>
        <div className='background-container'>
            <div className='background-text'>
                <h1>Career Expedition</h1>
                <p>Engage in insightful discussions, gain valuable industry insights, and chart your career path in the tech world.</p>
            </div>
        </div>
        <div className='career-day-contents'>
            <p>
            We place our students front and center of opportunity, unveiling the vast array of possibilities within the tech space. We unlock the secrets to building a thriving career in technology, offering invaluable insights directly from industry experts. Through interactive engagement and firsthand conversations, students gain unparalleled access to the knowledge and connections needed to thrive in today's dynamic tech industry.
            </p>            
            <hr className='career-divider'/>
            <div className='career-video'>
                <img
                    src={video}
                    width="100%"
                    height="100%"
                    alt='video'
                />
            </div>
            <div className='relive-career'>
                <h1>Relive the Experience</h1>
                <p>Capture the energy, insights, and inspiration from career expeditoin. From keynote speeches to interactive discussions</p>
            </div>
        </div>
        <div className='career-more'>
            <h1>Learn more about our <br /> Events</h1>
            <div className='more-events'>
                <a href='/events'>
                    Go to events
                </a>
                <img
                    src={ChevronRight}
                    className='learn_more_icon'
                    alt='icon'
                />
            </div>
        </div>
    </div>
  )
}

export default Career_day