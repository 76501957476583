import React, {useState, useEffect,useRef} from 'react';
import '../../components/courses/compnetworking.css';
import Popup from "../popup/PopUp";
import { Link } from 'react-router-dom';
import networkbground from '../../images/networkbground.webp';
import fullwebdev from '../../images/fullwebdev.webp';
import networkcheck from '../../images/networkcheck.png';
import networkapply from '../../images/networkapply.png';
import ChevronRight from "../../images/chevron_right_blue.png";
import networkletter from '../../images/networkletter.png';
import networkcall from '../../images/networkcall.png'; 
import networkchat from '../../images/networkchat.png';
import arrow_down from "../../images/arrow_down.png"


const BootcampComputerNetworking = () => {
    const [showPopup, setShowPopup] = useState(false);

    const openPopup = () => {
        setShowPopup(true);
    };

    const closePopup = () => {
        setShowPopup(false);
    };

    const handleProceed = () => {
        window.location.href = '/BootcampRegistration';
        // Handle proceed action
    };


    const containerRef = useRef(null);

    useEffect(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              entry.target.classList.add('animate');
            } else {
              entry.target.classList.remove('animate');
            }
          });
        },
        { threshold: 0.1 } // Adjust threshold as needed
      );
  
      if (containerRef.current) {
        observer.observe(containerRef.current);
      }
  
      return () => {
        if (containerRef.current) {
          observer.unobserve(containerRef.current);
        }
      };
    }, []);
  
    
   
const AnimateText = ({ text }) => {
    const textRef = useRef(null);
    const [isInView, setIsInView] = useState(false);
  
    useEffect(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setIsInView(true);
            } else {
              setIsInView(false); // Reset animation when out of view
            }
          });
        },
        { threshold: 0.1 } // Adjust the threshold as needed
      );
  
      if (textRef.current) {
        observer.observe(textRef.current);
      }
  
      return () => {
        if (textRef.current) {
          observer.unobserve(textRef.current);
        }
      };
    }, []);
  
    return (
      <div className="animate-container">
        <h1
          ref={textRef}
          className={`network-animatedText ${isInView ? 'network-animate' : ''}`}
        >
          {text.split('').map((char, index) => (
            <span key={index} style={{ '--char-index': index }}>
              {char}
            </span>
          ))}
        </h1>
      </div>
    );
  };
  


  
    
  return (
    <div>
        <div className='network-course-sub-navbar'>
            <p>COMPUTER NETWORKING</p>
            <div className='network-nav-apply'>
                <span  onClick={openPopup} className="network-course-sub-navbar-selection">Apply</span>
                {showPopup && (
                    <Popup
                    title="Bootcamp Registration"
                    content="Please note that during the registration process for our Bootcamp program, you will be required to provide some information."
                    onClose={closePopup}
                    onProceed={handleProceed}
                    />
                )}
            </div>
        </div>
        <div className='network-course-bground'>
            <div className='network-course-bgimg'>
                <div className="App">
                    <AnimateText text="COMPUTER" />
                    <AnimateText text="NETWORKING" />
                </div>
                <Link to="/RegularTrainingRegistration" className='apply-now-link'>
                    <div className='course-apply-now' ref={containerRef}> <button >Apply now</button> 
                        <img
                            src={arrow_down}
                            width="35px"
                            height="35px"
                            alt='icon'
                        /> 
                    </div>                
                </Link>
            </div>
        </div>
        <div className='network-sys-course-content'>
            <p>Course details</p>
            <h1>Everything you need to kick-start your journey</h1>
            <div className='network-sys-price-content'>
                <div className='network-sys-price-details'>
                    <h1>150<span>k</span></h1>
                    <span>Price(Regular)</span>
                </div>
                <div className='network-sys-price-details'>
                    <h1>99<span>k</span></h1>
                    <span>Price(IT)</span>
                </div>
                <div className='network-sys-price-details'>
                    <h1>3<span>:</span>4</h1>
                    <span>Months:hrs per week</span>
                </div>
            </div>
            <div className='network-sys-course-about'>
                <h1>Course overview</h1>
                <p>
                    The Computer Networking course offers a comprehensive foundation in the principles and practices of networking. This course is designed to equip students with the skills needed to design, implement, and manage robust networks. With a blend of theoretical knowledge and hands-on experience, students will learn about network protocols, architectures, security, and troubleshooting.
                </p>
                <p>
                    The course delivery includes lectures, lab exercises, and practical assignments. No specific
                    prerequisites are required. Completion of the course provides a solid foundation for
                    advanced networking certifications and further studies.
                </p>
            </div>
            <div className='ui-ux-background-image'>
                <img
                    src={networkbground}
                    width="100%"
                    height="100%"
                    alt='icon'
                    className='course-background-attract'
                />
            </div>
            <div className='network-course-highlights'>
                <h1>Course Highlights</h1>
                <p>
                    You are about to embark on an exciting journey of 
                    learning how to program. For the duration of this course 
                    you will be learning;
                </p>
                <div className='network-course-content-highlight'>
                    <div className='course-content-ux'>
                        <img
                            src={networkcheck}
                            alt='icon'
                            width="25px"
                            height="20px"
                        />
                        <p>Introduction to Computer Networking, Understanding Local Area Networks, Devices, and Data Transfer. Identifying Network topologies and standards</p>
                    </div>
                    <div className='course-content-ux'>
                        <img
                            src={networkcheck}
                            alt='icon'
                            width="25px"
                            height="20px"
                        />
                        <p>Understanding OSI and TCP/IP Models</p>
                    </div>
                    <div className='course-content-ux'>
                        <img
                            src={networkcheck}
                            alt='icon'
                            width="25px"
                            height="20px"
                        />
                        <p>Network Protocols and Standards</p>
                    </div>
                    <div className='course-content-ux'>
                        <img
                            src={networkcheck}
                            alt='icon'
                            width="25px"
                            height="20px"
                        />
                        <p>IP Addressing and Subnetting</p>
                    </div>
                    <div className='course-content-ux'>
                        <img
                            src={networkcheck}
                            alt='icon'
                            width="25px"
                            height="20px"
                        />
                        <p>Routing and Switching Fundamentals</p>
                    </div>
                    <div className='course-content-ux'>
                        <img
                            src={networkcheck}
                            alt='icon'
                            width="25px"
                            height="20px"
                        />
                        <p>Wireless Networking</p>
                    </div>
                    <div className='course-content-ux'>
                        <img
                            src={networkcheck}
                            alt='icon'
                            width="25px"
                            height="20px"
                        />
                        <p>Network Security Essentials</p>
                    </div>
                    <div className='course-content-ux'>
                        <img
                            src={networkcheck}
                            alt='icon'
                            width="25px"
                            height="20px"
                        />
                        <p>Virtual Private Networks (VPNs)</p>
                    </div>
                    <div className='course-content-ux'>
                        <img
                            src={networkcheck}
                            alt='icon'
                            width="25px"
                            height="20px"
                        />
                        <p>Network Troubleshooting and Diagnostics</p>
                    </div>
                    <div className='course-content-ux'>
                        <img
                            src={networkcheck}
                            alt='icon'
                            width="25px"
                            height="20px"
                        />
                        <p>Network Design and Implementation</p>
                    </div>
                    <div className='course-content-ux'>
                        <img
                            src={networkcheck}
                            alt='icon'
                            width="25px"
                            height="20px"
                        />
                        <p>Emerging Network Technologies (SDN, IoT)</p>
                    </div>
                </div>
            </div>
            <div className='ui-ux-background-image'>
                <img
                    src={fullwebdev}
                    width="100%"
                    height="100%"
                    alt='icon'
                    className='course-background-attract'
                />
            </div>
            <div className='network-course-highlights1'>
                <div className='network-course-highlights'>
                    <h1>
                    Learning Outcome
                    </h1>
                    <p>Upon completion, graduates will be able to:</p>
                    <div className='network-course-content-highlight'>
                        <div className='course-content-ux'>
                            <img
                                src={networkcheck}
                                alt='icon'
                                width="25px"
                                height="20px"
                            />
                            <p>Understand and explain the fundamental concepts of computer networking, including key protocols and models.</p>
                        </div>
                        <div className='course-content-ux'>
                            <img
                                src={networkcheck}
                                alt='icon'
                                width="25px"
                                height="20px"
                            />
                            <p>Design and implement efficient network architectures for various environments.</p>
                        </div>
                        <div className='course-content-ux'>
                            <img
                                src={networkcheck}
                                alt='icon'
                                width="25px"
                                height="20px"
                            />
                            <p>Configure and troubleshoot network devices, including routers, switches, and wireless access points.</p>
                        </div>
                        <div className='course-content-ux'>
                            <img
                                src={networkcheck}
                                alt='icon'
                                width="25px"
                                height="20px"
                            />
                            <p>Apply network security principles to protect data and ensure secure communication.</p>
                        </div>
                        <div className='course-content-ux'>
                            <img
                                src={networkcheck}
                                alt='icon'
                                width="25px"
                                height="20px"
                            />
                            <p>Utilize emerging networking technologies to enhance network performance and capabilities.</p>
                        </div>
                    </div>
                </div>
                <div className='ui-ux-course-content-highlight'>
                    <div className='course-content-ux1'>
                        <p>Establish connectivity with MySQL databases using NodeJS.</p>
                        <img
                            src={networkapply}
                            alt='icon'
                            width="70px"
                            height="70px"
                            className='checkmark-apply'
                            onClick={openPopup}
                        />
                    </div>
                </div>
            </div>
        </div>
        <div className='experience-story'>
            <div className='call-course-enquiries'>
                <h1>For More Inquiries</h1>
                    <div className='contact-course-container'>
                    <div className='contact-course1'>
                        <div className='call-course-image'>
                            <img
                                src={networkchat}
                                width="100%"
                                height="100%"
                                alt='icon'
                            />
                        </div>
                        <h3>chat</h3>
                        <p>deebug.linkedin</p>
                    </div>
                    <div className='contact-course1'>
                        <div className='call-course-image'>
                            <img
                                src={networkcall}
                                width="100%"
                                height="100%"
                                alt='icon'
                            />
                        </div>
                        <h3>Call</h3>
                        <p>+2349162627333</p>
                    </div>
                    <div className='contact-course1'>
                        <div className='call-course-image'>
                            <img
                                src={networkletter}
                                width="100%"
                                height="100%"
                                alt='icon'
                            />
                        </div>
                        <h3>Email</h3>
                        <p>Info@deebug.org</p>
                    </div>
                </div>
            </div>
            <div className='course-inquiries'>
                <h1>Learn more about education <br /> at Deebug</h1>
                <div className="edu-learn-btn"><Link to="/IndustrialTrainingCourses" className="section_learn_more"> Go to Industrial Training Overview</Link><img src={ChevronRight} className="learn_more_icon" /></div>
                <div className="edu-learn-btn"><Link to="/RegularStudents" className="section_learn_more"> Go to Regular Students</Link><img src={ChevronRight} className="learn_more_icon" /></div>
            </div>
        </div>
    </div>
  )
}

export default BootcampComputerNetworking