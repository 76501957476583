import ReadMore from "../../images/read_more.png";
import "./EduStories.css";
import { useEffect, useState } from "react";
import { useWindowWidth, useWindowHeight } from "@react-hook/window-size";
import Purpose1 from "../../images/Purpose1.jpg";
import Purpose2 from "../../images/Purpose2.JPG";
// import tes from "../../images/Pur"

export default function EduStories({ index }) {
  const [mobile, setMobile] = useState(false);
  const width = useWindowWidth();
  useEffect(() => {
    if (width <= 767) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, [width]);

  const data = [
    {
      title: "A Creative Journey to Success",
      color: "rgba(73, 31, 111, 1)",
    },
    {
      title: "Entrapreneur in the making",
      color: "rgba(190, 79, 41, 1)",
    },
    {
      title: "Building Confidence each Day",
      color: "rgba(27, 78, 117, 1)",
    },
    {
      title: "Engineering the Future",
      color: "rgba(250, 63, 134, 0.97)",
    },
  ];
  const main = {
    height: "450px",
    width: "400px",
    borderRadius: "20px",
    margin: "0 10px",
    // margin:"-20px"
  };
  const f_main = {
    height: "450px",
    width: "400px",
    borderRadius: "20px",
    margin: "0 10px 0 300px",
  };
  const main_mobile = {
    height: "450px",
    width: "300px",
    borderRadius: "20px",
    margin: "0",
    // margin:"-20px"
  };

  // Check if data[index] is defined before accessing its properties
  const currentData = data[index] || {};

  return (
    <div style={mobile ? main_mobile : index === 0 ? f_main : main}>
      <div className="stories-card-image">
        <img src={Purpose1} className="stories-card-image-style" alt="" />
      </div>

      <div
        className="stories-card-details"
        style={{ backgroundColor: currentData["color"] }}
      >
        <div className="stories-title-container">
          <p className="stories-title">{currentData["title"]}</p>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={ReadMore} className="stories-read-icon" />
            <a className="stories-read" href="#">
              Read
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
