import React, {useState, useEffect,useRef} from 'react';
import Popup from '../popup/PopUp';
import '../../components/courses/frontend.css';
import { Link, } from 'react-router-dom';
import frontendwallpaper from '../../images/frontendwallpaper.png';
import frontendbground from '../../images/frontendbground.webp';
import frontendbground2 from '../../images/frontendbground2.png';
import frontendcheck from '../../images/frontendcheck.png';
import frontendapply from '../../images/frontendapply.png';
import previous from '../../images/backblack.png';
import next from '../../images/forwardblack.png';
import frontendletter from '../../images/frontendletter.png';
import frontendcall from '../../images/frontendcall.png';
import frontendchat from '../../images/frontendchat.png';
import teststory1 from '../../images/teststory1.webp';
import teststory2 from '../../images/teststory2.webp';
import teststory3 from '../../images/teststory3.webp';
import teststory4 from '../../images/teststory4.webp';
import teststory5 from '../../images/teststory5.webp';
import teststory6 from '../../images/teststory6.webp';
import teststory7 from '../../images/teststory7.webp';
import ChevronRight from "../../images/chevron_right_blue.png";
import arrow_down from "../../images/arrow_down.png"


const ITFrontend = () => {
    const [scrollPosition, setScrollPosition] = useState(0);
    const [isInView, setIsInView] = useState(false);
  
    
   
const testimonials = [
    {
      text: "My time at Deebug taking the Frontend Web Development course was worth it. The knowledgeable instructors were always helpful. I gained valuable skills, making it a rewarding learning experience.",
      name: "Ononyiwita Godsglory",
      class: "Class of 2024",
      image: teststory4
    },
    {
      text: "“It was a wonderful experience learning my first tech skill at Deebug Institute. What amazed me the most was how the tutors broke down every aspect of what was been taught. They made it seem so easy.”",
      name: "Tunde Oriyomi",
      class: "Class of 2024",
      image: teststory7
    },
    {
      text: "“My time at Deebug taking the Frontend Web Development course was worth it. The knowledgeable instructors were always helpful. I gained valuable skills, making it a rewarding learning experience.”",
      name: "Precious",
      class: "Class of 2024",
      image: teststory3
    },    
    {
      text: "The projects at Deebug helped me build a solid portfolio. I feel prepared to tackle any frontend development challenge.",
      name: "Michael Brown",
      class: "Class of 2024",
      image: teststory5
    }
  ];
  
  
    const [currentIndex, setCurrentIndex] = useState(0);
    const containerRef = useRef(null);
  
    const handleScroll = (direction) => {
      const container = containerRef.current;
      const containerWidth = container.clientWidth;
      const newIndex = direction === 'left'
        ? Math.max(currentIndex - 1, 0)
        : Math.min(currentIndex + 1, testimonials.length - 1);
  
      setCurrentIndex(newIndex);
      container.scrollTo({
        left: newIndex * containerWidth,
        behavior: 'smooth'
      });
    };

  

    const containerRef1 = useRef(null);
    useEffect(() => {
        const observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                entry.target.classList.add('animate');
              } else {
                entry.target.classList.remove('animate');
              }
            });
          },
          { threshold: 0.1 } // Adjust threshold as needed
        );
    
        if (containerRef1.current) {
          observer.observe(containerRef1.current);
        }
    
        return () => {
          if (containerRef1.current) {
            observer.unobserve(containerRef1.current);
          }
        };
      }, []);

    
    const [showPopup, setShowPopup] = useState(false); 

    const openPopup = () => {
        setShowPopup(true);
    };

    const closePopup = () => {
        setShowPopup(false);
    };

    const handleProceed = () => {
        window.location.href = '/ITregistration';
        // Handle proceed action
    };



  

  return (
    <div className='frontend-main'>
        <div className="backend-course-sub-navbar">
            <p>FRONTEND DEVELOPMENT</p>
            <div className='backend-nav-apply'>
                <span onClick={openPopup} className="backend-course-sub-navbar-selection">Apply</span>
                {showPopup && (
                    <Popup
                        title="Industrial Training Registration"
                        content="Please note that during the registration process for our Industrial Training program, you will be required to provide some information."
                        onClose={closePopup}
                        onProceed={handleProceed}
                    />
                )}
            </div>
        </div>
        <div className='network-course-background'>
            <div className='frontend-course-bgimg'>
                <h1>FRONTEND WEB</h1>
                <h1>DEVELOPMENT</h1>
                <Link to="/ITregistration" className='apply-now-link'>
                    <div className='course-apply-now' ref={containerRef1}> <button >Apply now</button> 
                        <img
                            src={arrow_down}
                            width="35px"
                            height="35px"
                            alt='icon'
                        /> 
                    </div>                
                </Link>
            </div>
        </div>
        <div className='frontend-course-content'>
            <p>Course details</p>
            <h1>Everything you need to kick-start your journey</h1>
            <div className='frontend-price-content'>
                <div className='frontend-price-details'>
                    <h1>75<span>k</span></h1>
                    <span>Price(Regular)</span>
                </div>
                <div className='frontend-price-details'>
                    <h1>54<span>k</span></h1>
                    <span>Price(IT)</span>
                </div>
                <div className='frontend-price-details'>
                    <h1>3<span>:</span>4</h1>
                    <span>Months:hrs per week</span>
                </div>
            </div>
            <div className='frontend-course-about'>
                <h1>Overview</h1>
                <p>
                    Congratulations on considering the complete web development course with the React frame work.
                    This course which has no prerequisite is perfect for an absolute beginner looking to delve into the
                    world of website development. The course includes solid introductions to HTML, CSS and an in
                    depth look at JavaScript. All you need to take this course is adequate time to practice the topics
                    and concepts which will be explored during the course.
                </p>
                <p>
                    As powerful as React is, it simply wouldn't be possible without HTML so in this course we’ll be taking a
                    dive into HTML5. We will take you from working with basic texts, to working with complex forms, audio
                    & even video. This course also comprises an introduction to Version control using Git hub.
                </p>
            </div>
            <div className='ui-ux-background-image'>
                <img
                    src={frontendbground}
                    width="100%"
                    height="100%"
                    alt='icon'
                    className='course-background-attract'
                />
            </div>
            <div className='frontend-course-highlights'>
                <h1>Course Highlights</h1>
                <p>
                    You are about to embark on an exciting journey of 
                    learning how to program. For the duration of this course 
                    you will be learning;
                </p>
                <div className='frontend-course-content-highlight'>
                    <div className='course-content-ux'>
                        <img
                            src={frontendcheck}
                            alt='icon'
                            width="25px"
                            height="20px"
                        />
                        <p>The Web & The Internet, Introduction to HTML.</p>
                    </div>
                    <div className='course-content-ux'>
                        <img
                            src={frontendcheck}
                            alt='icon'
                            width="25px"
                            height="20px"
                        />
                        <p>HTML continued and Introduction to CSS.</p>
                    </div>
                    <div className='course-content-ux'>
                        <img
                            src={frontendcheck}
                            alt='icon'
                            width="25px"
                            height="20px"
                        />
                        <p>Cascading Style Sheets(CSS).</p>
                    </div>
                    <div className='course-content-ux'>
                        <img
                            src={frontendcheck}
                            alt='icon'
                            width="25px"
                            height="20px"
                        />
                        <p>Important CSS Concepts.</p>
                    </div>
                    <div className='course-content-ux'>
                        <img
                            src={frontendcheck}
                            alt='icon'
                            width="25px"
                            height="20px"
                        />
                        <p>JavaScript Fundamentals & Introduction to React.</p>
                    </div>
                    <div className='course-content-ux'>
                        <img
                            src={frontendcheck}
                            alt='icon'
                            width="25px"
                            height="20px"
                        />
                        <p>ReactJS: Understanding the Library & State and Lifecycle.</p>
                    </div>
                    <div className='course-content-ux'>
                        <img
                            src={frontendcheck}
                            alt='icon'
                            width="25px"
                            height="20px"
                        />
                        <p>Hooks(Deep Dive).</p>
                    </div>
                    <div className='course-content-ux'>
                        <img
                            src={frontendcheck}
                            alt='icon'
                            width="25px"
                            height="20px"
                        />
                        <p>ReactJS Deepdive.</p>
                    </div>
                    <div className='course-content-ux'>
                        <img
                            src={frontendcheck}
                            alt='icon'
                            width="25px"
                            height="20px"
                        />
                        <p>FireBase & Websocket(Socket.io).</p>
                    </div>
                    <div className='course-content-ux'>
                        <img
                            src={frontendcheck}
                            alt='icon'
                            width="25px"
                            height="20px"
                        />
                        <p>Security and Deployment.</p>
                    </div>
                    <div className='course-content-ux'>
                        <img
                            src={frontendcheck}
                            alt='icon'
                            width="25px"
                            height="20px"
                        />
                        <p> Web Performance Optimization, Web Accessibility & The Future of The Internet (WEB 3)</p>
                    </div>
                </div>
            </div>
            <div className='ui-ux-background-image'>
                <img
                    src={frontendbground}
                    width="100%"
                    height="100%"
                    alt='icon'
                    className='course-background-attract'
                />
            </div>
            <div className='frontend-course-highlights1'>
                <div className='frontend-course-highlights'>
                    <h1>
                    Learning Outcome
                    </h1>
                    <p>Upon completion, graduates will be able to:</p>
                    <div className='frontend-course-content-highlight'>
                        <div className='course-content-ux'>
                            <img
                                src={frontendcheck}
                                alt='icon'
                                width="25px"
                                height="20px"
                            />
                            <p>Design and develop responsive web applications.</p>
                        </div>
                        <div className='course-content-ux'>
                            <img
                                src={frontendcheck}
                                alt='icon'
                                width="25px"
                                height="20px"
                            />
                            <p>Implement real-time features and data synchronization.</p>
                        </div>
                        <div className='course-content-ux'>
                            <img
                                src={frontendcheck}
                                alt='icon'
                                width="25px"
                                height="20px"
                            />
                            <p>Ensure security, performance, and accessibility of web applications.</p>
                        </div>
                        <div className='course-content-ux'>
                            <img
                                src={frontendcheck}
                                alt='icon'
                                width="25px"
                                height="20px"
                            />
                            <p>Stay updated with evolving trends in web development for future-proofing skills.</p>
                        </div>
                    </div>
                </div>
                <div className='ui-ux-course-content-highlight'>
                    <div className='course-content-ux1'>
                        <p>Ready to embark on this exciting journey? Take the first step toward shaping your future in tech.</p>
                        <img
                            src={frontendapply}
                            alt='icon'
                            width="70px"
                            height="70px"
                            className='checkmark-apply'
                        />
                    </div>
                </div>
            </div>
        </div>
        <div className='experience-story'>
            <h1>The Experience</h1>
            <p>Don't just take our word for it! Hear what our past students have to say about their experience</p>
            <div className='testimonial-main-container'>
                <div className='testimonial-sub-main-container'>
                    <button className='testimonial-btn' onClick={() => handleScroll('left')}>
                        <img 
                            src={previous} 
                            width="100%" 
                            height="100%" 
                            alt='next'                             
                        />
                    </button>
                    <div className='testimonial-container' ref={containerRef}>
                        {testimonials.map((testimonial, index) => (
                            <div className='testimonial-story' key={index}>
                                <div className='testimonial-text'>
                                    <h1>{testimonial.text}</h1>
                                    <div className='testimonial-personal'>
                                    <h2>{testimonial.name}</h2>
                                    <p>{testimonial.class}</p>
                                    </div>
                                </div>
                                <div className='testimonial-image'>
                                    <img
                                    src={testimonial.image}
                                    width="100%"
                                    height="100%"
                                    alt='testimonial'
                                    className='testy-image'
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                    <button className='testimonial-btn' onClick={() => handleScroll('right')}>
                        <img 
                            src={next} 
                            width="100%" 
                            height="100%" 
                            alt='next'                             
                        />
                    </button>
                    <div className='testimonial-btn-prt-ctn'>
                        <button className='testimonial-btn1' onClick={() => handleScroll('left')}>
                            <img 
                                src={previous} 
                                width="100%" 
                                height="100%" 
                                alt='next'                             
                            />
                        </button>
                        <button className='testimonial-btn1' onClick={() => handleScroll('right')}>
                            <img 
                                src={next} 
                                width="100%" 
                                height="100%" 
                                alt='next'                             
                            />
                        </button>
                    </div>
                </div>
                <div className='testimonial-btn-container2'>
                    {testimonials.map((_, index) => (
                    <button
                        key={index}
                        className={`indicator-btn ${index === currentIndex ? 'active' : ''}`}
                        onClick={() => setCurrentIndex(index)}
                    >
                        <span className="indicator" />
                    </button>
                    ))}
                </div>
            </div>
            <div className='call-course-enquiries'>
                <h1>For More Inquiries</h1>
                    <div className='contact-course-container'>
                    <div className='contact-course1'>
                        <div className='call-course-image'>
                            <img
                                src={frontendchat}
                                width="100%"
                                height="100%"
                                alt='icon'
                            />
                        </div>
                        <h3>chat</h3>
                        <p>deebug.linkedin</p>
                    </div>
                    <div className='contact-course1'>
                        <div className='call-course-image'>
                            <img
                                src={frontendcall}
                                width="100%"
                                height="100%"
                                alt='icon'
                            />
                        </div>
                        <h3>Call</h3>
                        <p>+2349162627333</p>
                    </div>
                    <div className='contact-course1'>
                        <div className='call-course-image'>
                            <img
                                src={frontendletter}
                                width="100%"
                                height="100%"
                                alt='icon'
                            />
                        </div>
                        <h3>Email</h3>
                        <p>Info@deebug.org</p>
                    </div>
                </div>
            </div>
            <div className='course-inquiries'>
                <h1>Learn more about education <br /> at Deebug</h1>
                <div className="edu-learn-btn"><Link to="/IndustrialTrainingCourses" className="section_learn_more"> Go to Industrial Training Overview</Link><img src={ChevronRight} className="learn_more_icon" /></div>
                <div className="edu-learn-btn"><Link to="/RegularStudents" className="section_learn_more"> Go to Regular Students</Link><img src={ChevronRight} className="learn_more_icon" /></div>
            </div>
        </div>
    </div>
  )
}


export default ITFrontend