import "./IndustrialTraining.css";
import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import tick from "../../images/tick.png";
import react from "../../images/react.png";
import figma from "../../images/figma.png";
import node from "../../images/node.png";
import flutter from "../../images/flutter.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ChevronRight from "../../images/chevron_right_blue.png";
import P2 from "../../images/hack1.webp";
import P3 from "../../images/p3.png";
import P5 from "../../images/p5.webp";
import { useNavigate } from "react-router-dom";
import EduStories from "../../components/EducationComps/EduStories";
import { useWindowWidth } from "@react-hook/window-size";
// import "./AllCourses.css";
import IndusSection1 from "../../images/industrial.png";
import IndusSection1Mobile from "../../images/IndustrialMobile.png";
import responsivebtn from "../../images/hmbdwn.png";
import GDay from "../../images/GDay.webp";
import Hackathon from "../../images/HackDay.webp";
import CDay from "../../images/CDay.webp";
import innday from "../../images/innovatedu.webp";
import right from "../../images/blueright.png";
import reactnode from '../../images/reactnode.png';
import reactfigma from '../../images/reactfigma.png';
import flutterjs from '../../images/flutterjs.png';
import purpose from "../../images/Purpose1.jpg";
import ReadMore from "../../images/read_more.png";
import SamStory from "../../components/EducationComps/SamStory";
import ChizzyStory from "../../components/EducationComps/ChizzyStory";
import PurposeStory from "../../components/EducationComps/PurposeStory";
import sammy from "../../images/sammytopprt.webp"
import chizzy from "../../images/chizzytopprt.webp"

export default function IndustrialTraining() {
  const navigate = useNavigate();
  const [mobile, setMobile] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openModal1 = () => {
    setIsModalOpen1(true);
  };

  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = () => {
    setIsModalOpen2(true);
  };

  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const width = useWindowWidth();

  useEffect(() => {
    if (width <= 767) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, [width]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // const dialogContent = (
  //   <div>
  //     <h2>Your Dialog Content Goes Here</h2>
  //   </div>
  // );

  // const [isDialogOpen, setIsDialogOpen] = useState(false);

  // const openDialog = () => {
  //   setIsDialogOpen(true);

  //   document.body.style.overflow = "hidden";
  //   document.documentElement.style.overflow = "hidden"; // Additional measure
  // };

  // const closeDialog = () => {
  //   // console.log("tapped");
  //   setIsDialogOpen(false);
  //   document.body.style.overflow = "auto";
  //   document.documentElement.style.overflow = "auto"; // Additional measure
  // };

  // Generate an array of 8 items
  const items = Array.from({ length: 1 }, (_, index) => (
    <EduStories index={index} />
  ));

  const containerRef = useRef(null);
  const scrollLeft = () => {
    if (containerRef.current) {
      if (mobile) {
        containerRef.current.scrollBy({
          left: -window.innerWidth * 0.75, // Scroll 50% of the screen width to the left
          behavior: "smooth", // Optional: add smooth scrolling
        });
      } else {
        containerRef.current.scrollBy({
          left: -window.innerWidth * 0.2, // Scroll 50% of the screen width to the left
          behavior: "smooth", // Optional: add smooth scrolling
        });
      }
    }
  };

  const scrollRight = () => {
    if (containerRef.current) {
      if (mobile) {
        containerRef.current.scrollBy({
          left: window.innerWidth * 0.75, // Scroll 50% of the screen width to the left
          behavior: "smooth", // Optional: add smooth scrolling
        });
      } else {
        containerRef.current.scrollBy({
          left: window.innerWidth * 0.2, // Scroll 50% of the screen width to the left
          behavior: "smooth", // Optional: add smooth scrolling
        });
      }
    }
  };

 
  const currentLocation = useLocation();

  useEffect(() => {
    if (currentLocation.hash === "#industrial-training-courses") {
      const targetElement = document.querySelector('.industrial-training-courses');
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [currentLocation]);
  
  return (
    <div className="ind-tr-parent">
      <div className="rg-sub-navbar">
        <p>INDUSTRIAL TRAINING</p>
        <ul className={isOpen ? "intern-menu active" : "intern-menu"}>
          <li>
            <span onClick={() => navigate("/IndustrialTraining")}>
              Overview
            </span>
          </li>
          <li>
            <span onClick={() => navigate("/IndustrialTrainingCourses")}>
              Courses
            </span>
          </li>
          <li>
            <span
              className="backend-course-sub-navbar-selection2"
              onClick={() => navigate("/ITregistration")}
            >
              Apply
            </span>
          </li>
        </ul>
        <div className="intern-ham-btn">
          <img
            src={responsivebtn}
            width="100%"
            height="100%"
            alt="icon"
            onClick={toggleMenu}
          />
        </div>
        <span
          className="backend-course-sub-navbar-selection1"
          onClick={() => navigate("/ITregistration")}
        >
          Apply
        </span>
      </div>
      <div className="education_parent">
        <div className="ind-tr-section1">
          <div className="ind-tr-section1-item">
            <img
              src={mobile ? IndusSection1Mobile : IndusSection1}
              className="header-section1-img"
            />
          </div>
          {/* <p>Shaping Tomorrow's Pioneers</p> */}
        </div>

        <div className="ind-tr-section2-bg">
          <h1>Shaping Tomorrow's Pioneers</h1>
          <p>
            Shaping the next generation of industry experts through
            comprehensive training and hands-on experience, empowering them with
            the skills and knowledge needed to excel in a rapidly evolving
            professional landscape.
          </p>
        </div>

        <div className="intern-experience-about">
        <h1>The Deebug’s Experience.</h1>
          <p>
            <span className="intern-experience-about-black">
              {" "}
              Education is not just absorbed,{" "}
            </span>
            it's experienced, ensuring that every lesson leaves a lasting
            impact. Through interactive learning, we transform the educational
            journey into a dynamic experience where knowledge is not only gained
            but also lived.
          </p>
          <div className="ind-tr-section3-list">
            <div className="edu-column-layout">
              <p className="ind-tr-column-title">Immersive learning.</p>

              <p className="ind-tr-column-subtitle">
                Our learning spaces are designed to inspire the exchange of
                ideas, ignite curiosity, and cultivate an atmosphere where
                students only learn from each other.
              </p>
            </div>
            <div className="edu-column-layout">
              <p className="ind-tr-column-title">Guide by Experts.</p>
              <p className="ind-tr-column-subtitle">
                Our tutors not only impart knowledge but also inspire and guide
                ensuring that students not only learn theories but also gain a
                understanding of their practical applications.
              </p>
            </div>
            <div className="edu-column-layout">
              <p className="ind-tr-column-title">Get Help when Needed.</p>

              <p className="ind-tr-column-subtitle">
                We transform education into a dynamic and engaging journey
                through interactive learning methods, project-based exploration
                and momentous events
              </p>
            </div>
          </div>
        </div>
        {/*  */}
        <div>
          <p className="it-project-sub">Project</p>
          <p className="ind-tr-section4-title-gradient">Building Tomorrow's Leaders</p>
          <div className="edu-project-card-list">
            <div className="edu-project-car-item-main">
              <img src={P3} alt="build1" className="edu-project-card-item" />
            </div>
            <div className="edu-project-car-item-main1">
              <img src={P5} alt="build1" className="edu-project-card-item" />
            </div>
            <div className="edu-project-car-item-main">
              <img src={P2} alt="build1" className="edu-project-card-item" />
            </div>
          </div>
          <p className="ind-tr-section4-details">
            We bridge the gap between classroom knowledge and real-world
            applications by offering hands-on experience through live projects.
            Students not only learn but thrive, gaining practical insights that
            prepare them for success in their chosen fields.
          </p>
        </div>

        <div className="intern-week">
          <p>IT Level up week</p>
          <h1>
            Captivating events designed to enhance your learning experience.{" "}
          </h1>
          <div className="intern-week-activities">
            <div className="intern-week-ctn">
              <div className="intern-sprint-img">
                <img src={GDay} width="100%" height="100%" alt="event-img" />
              </div>
                <div className="intern-sprint-content">
                  <h1>E-Sport Tournament</h1>
                  <p>
                    See how student engage in epic game battles unleashing the
                    creativity and team spirit
                  </p>
                    <div className="education-learn-btn">
                      {/* <Link to="/gaming" className="edu-section_learn_more">
                        Learn more
                      </Link> */}
                      <img src={ChevronRight} className="edu-learn_more_icon" />
                    </div>
                </div>
            </div>
            <div className="intern-week-ctn">
              <div className="intern-sprint-img">
                <img src={Hackathon} width="100%" height="100%" alt="event-img" />
              </div>
                <div className="intern-sprint-content">
                  <h1>Hackathon</h1>
                  <p>
                    Engage in coding challenges and design sprints, where students collaborate, 
                    ideate, and showcase their skills in friendly competitions.
                  </p>
                    <div className="education-learn-btn">
                      {/* <Link to="/hackathon" className="edu-section_learn_more">
                        Learn more
                      </Link> */}
                      <img src={ChevronRight} className="edu-learn_more_icon" />
                    </div>
                </div>
            </div>
            <div className="intern-week-ctn">
              <div className="intern-sprint-img">
                <img src={CDay} width="100%" height="100%" alt="event-img" />
              </div>
                <div className="intern-sprint-content">
                  <h1>Career Expedition</h1>
                  <p>
                    Gain valuable insights into the evolving job space, from 
                    navigating applications for remote and freelance work to 
                    unlocking the entrepreneurial path.
                  </p>
                    <div className="education-learn-btn">
                      {/* <Link to="/CareerExpedition" className="edu-section_learn_more">
                        Learn more
                      </Link> */}
                      <img src={ChevronRight} className="edu-learn_more_icon" />
                    </div>
                </div>
            </div>
            <div className="intern-week-ctn">
              <div className="intern-sprint-img">
                <img src={innday} width="100%" height="100%" alt="event-img" />
              </div>
                <div className="intern-sprint-content">
                  <h1>Innovative Showcase</h1>
                  <p>
                    Discover groundbreaking projects and insights into students learning journeys.
                  </p>
                    <div className="education-learn-btn">
                      {/* <Link to="/InnovativeShowcase" className="edu-section_learn_more">
                        Learn more
                      </Link> */}
                      <img src={ChevronRight} className="edu-learn_more_icon" />
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>

      <div className="ind-tr-stor">
        <p className="ind-tr-section6-title">Explore our stories</p>
        <div className="ind-stories-props">
          <div className="ind-stories-ctn">
            <div className="ind-stories-img">
              <img
                src={purpose}
                width="100%"
                height="100%"
                alt='background'
              />
            </div>
            <div className="ind-stories-text">
              <h1>Purpose  Olikiabo</h1>
              <p>UI/UX Designer</p>
              <div className="ind-stories-read">
                <img
                  src={ReadMore}
                  width="35px"
                  height="35px"
                  alt='background'
                  onClick={openModal}
                />
                Read
              </div>
              {isModalOpen && <PurposeStory closeModal={closeModal} />}
            </div>
          </div>
          <div className="ind-stories-ctn1">
            <div className="ind-stories-img">
              <img
                src={sammy}
                width="100%"
                height="100%"
                alt='background'
              />
            </div>
            <div className="ind-stories-text">
              <h1>Samuel Oghamomwan</h1>
              <p>Backend Developer </p>
              <div className="ind-stories-read">
                <img
                  src={ReadMore}
                  width="35px"
                  height="35px"
                  alt='background'
                  onClick={openModal1}
                />
                Read
              </div>
              {isModalOpen1 && <SamStory closeModal1={closeModal1} />}
            </div>
          </div>
          <div className="ind-stories-ctn2">
            <div className="ind-stories-img">
              <img
                src={chizzy}
                width="100%"
                height="100%"
                alt='background'
              />
            </div>
            <div className="ind-stories-text">
              <h1>Chizitere Ofurum</h1>
              <p>Fullstack Web Developer</p>
              <div className="ind-stories-read">
                <img
                  src={ReadMore}
                  width="35px"
                  height="35px"
                  alt='background'
                  onClick={openModal1}
                />
                Read
              </div>
              {isModalOpen2 && <ChizzyStory closeModal2={closeModal2} />}
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      <div className="industrial-training-courses">
        <div className="ind-tr-black-bg">
            <h1 className="ind-tr-header">INDUSTRIAL TRAINING COURSES</h1>
          <p className="ind-tr-section7-subtitle">
            Gain new skills with our focused and practical courses, designed for maximum impact in a short time.
          </p>
          <p className="ind-tr-section7-title-gradient">SINGLE TRACKS</p>
          <br />
          <br />
          <br />
          <div className="intern-courses-main-container">
            <div className="intern-courses-card2">
              <p className="course-title">BACKEND DEVELOPMENT</p>
              <div className="course-image-and-title">
                <img
                  src={node}
                  width="100%"
                  height="100%"
                  alt="icon"
                  className="course-image"
                />
                <p>NodeJS</p>
              </div>
              <div className="amount-list">
                <span className="naira-symbol">&#x20A6;</span>
                <span className="amount-style">75K</span>
              </div>
              <Link to="/ITregistration" className="course-btn-style">
                Apply Now
              </Link>
              <div className="all-courses-program-container">
                <div className="all-courses-program">
                  <img src={tick} className="tick_style" />
                  <span>Includes Node js, Express, Javascript. Databases like MySQL and Mongo db</span>
                </div>
                <div className="all-courses-program">
                  <img src={tick} className="tick_style" />
                  <span>14 weeks Intensive + Practical Classes</span>
                </div>
                <div className="all-courses-program">
                  <img src={tick} className="tick_style" />
                  <span>
                    Full access to the interactive community to aid with
                    assignments
                  </span>
                </div>
                <div className="all-courses-program">
                  <img src={tick} className="tick_style" />
                  <span>
                    Certificates are awarded at the end of the program.
                  </span>
                </div>
                    <div className="education-learn-btn1">
                      <Link to="/IndustrialTraining/BackendDevelopment" className="edu-section_learn_more1">
                        Get Started
                      </Link>
                      <img src={right} className="edu-learn_more_icon" />
                    </div>
              </div>
            </div>
            <div className="intern-courses-card2">
              <p className="course-title">FRONTEND DEVELOPMENT</p>
              <div className="course-image-and-title">
                <img
                  src={react}
                  width="100%"
                  height="100%"
                  alt="icon"
                  className="course-image"
                />
                <p>React</p>
              </div>
              <div className="amount-list">
                <span className="naira-symbol">&#x20A6;</span>
                <span className="amount-style">75K</span>
              </div>
              <Link to="/ITregistration" className="course-btn-style">
                Apply Now
              </Link>
              <div className="all-courses-program-container">
                <div className="all-courses-program">
                  <img src={tick} className="tick_style" />
                  <span>Includes HTML, CSS, Javascript and React</span>
                </div>
                <div className="all-courses-program">
                  <img src={tick} className="tick_style" />
                  <span>14 weeks Intensive + Practical Classes</span>
                </div>
                <div className="all-courses-program">
                  <img src={tick} className="tick_style" />
                  <span>
                    Certificates are awarded at the end of the program.
                  </span>
                </div>
                  <div className="education-learn-btn1">
                    <Link to="/IndustrialTraining/FrontendDevelopment" className="edu-section_learn_more1">
                      Get Started
                    </Link>
                    <img src={right} className="edu-learn_more_icon" />
                  </div>
              </div>
            </div>
            <div className="intern-courses-card2">
              <p className="course-title">MOBILE APP DEVELOPMENT</p>
              <div className="course-image-and-title">
                <img
                  src={flutter}
                  width="100%"
                  height="100%"
                  alt="icon"
                  className="course-image"
                />
                <p>Flutter</p>
              </div>
              <div className="amount-list">
                <span className="naira-symbol">&#x20A6;</span>
                <span className="amount-style">80K</span>
              </div>
              <Link to="/ITregistration" className="course-btn-style">
                Apply Now
              </Link>
              <div className="all-courses-program-container">
                <div className="all-courses-program">
                  <img src={tick} className="tick_style" />
                  <span>Includes flutter development framework using the dart programming language.</span>
                </div>
                <div className="all-courses-program">
                  <img src={tick} className="tick_style" />
                  <span>14 weeks Intensive + Practical Classes</span>
                </div>
                <div className="all-courses-program">
                  <img src={tick} className="tick_style" />
                  <span>
                    Full access to the interactive community to aid with
                    assignments
                  </span>
                </div>
                <div className="all-courses-program">
                  <img src={tick} className="tick_style" />
                  <span>
                    Certificates are awarded at the end of the program.
                  </span>
                </div>
              </div>
                <div className="education-learn-btn1">
                  <Link to="/IndustrialTraining/MobileAppDevelopment" className="edu-section_learn_more1">
                    Get Started
                  </Link>
                  <img src={right} className="edu-learn_more_icon" />
                </div>
            </div>
            <div className="intern-courses-card2">
              <p className="course-title">PRODUCT DESIGN</p>
              <div className="course-image-and-title">
                <img
                  src={figma}
                  width="100%"
                  height="100%"
                  alt="icon"
                  className="course-image"
                />
                <p>Figma</p>
              </div>
              <div className="amount-list">
                <span className="naira-symbol">&#x20A6;</span>
                <span className="amount-style">70K</span>
              </div>
              <Link to="/ITregistration" className="course-btn-style">
                Apply Now
              </Link>
              <div className="all-courses-program-container">
                <div className="all-courses-program">
                  <img src={tick} className="tick_style" />
                  <span>Includes user research, design thinking principles, design tools like Figma, </span> 
                </div>
                <div className="all-courses-program">
                  <img src={tick} className="tick_style" />
                  <span>12 weeks Intensive + Practical Classes</span>
                </div>
                <div className="all-courses-program">
                  <img src={tick} className="tick_style" />
                  <span>
                    Certificates are awardddded at the end of the program.
                  </span>
                </div>
              </div>
              <div className="education-learn-btn1">
                <Link to="/IndustrialTraining/FullstackProductDesign" className="edu-section_learn_more1">
                  Get Started
                </Link>
                <img src={right} className="edu-learn_more_icon" />
              </div>
            </div>            
          </div>
          <div className="industrial-courses-view-more">
          </div>
        </div>
        <div className="ind-tr-grey-bg">
          <p className="ind-tr-section7-title-gradient">SINGLE TRACKS</p>
          <div className="intern-courses-main-container">
            <div className='intern-courses-card2'>
              <p className='course-title'>FULLSTACK WEB DEVELOPMENT</p>
              <div className='course-image-and-title'>
                <img 
                  src={reactnode}
                  width="100%"
                  height="100%"
                  alt='icon'
                  className='course-image'
                />
              </div>
              <div className='amount-list'>
                <span className="naira-symbol">&#x20A6;</span>
                <span className='amount-style'>99K</span>
              </div>
              <Link to="/IndustrialTraining/FullstackWebDevelopment" className='course-btn-style'>Get started</Link>
              <div className="all-courses-program-container">
                <div className="all-courses-program">
                  <img src={tick} className='tick_style' />
                  <span>Includes HTML, CSS, Javascript, React js, Node js, Express</span>
                </div>
                <div className="all-courses-program">
                  <img src={tick} className='tick_style' />
                  <span>8 weeks Intensive + Practical Classes</span>
                </div>
                <div className="all-courses-program">
                  <img src={tick} className='tick_style' />
                  <span>Full access to the interactive community to aid with assignments</span>
                </div>
                <div className="all-courses-program">
                  <img src={tick} className='tick_style' />
                  <span>Certificates are awarded at the end of the program.</span>
                </div>
              </div>
              <div className="education-learn-btn1">
                <Link to="/IndustrialTraining/FullstackProductDesign" className="edu-section_learn_more1">
                  Get Started
                </Link>
                <img src={right} className="edu-learn_more_icon" />
              </div>
            </div>
            <div className='intern-courses-card2'>
              <p className='course-title'>FULL STACK SOFTWARE PRODUCT DESIGN</p>
              <div className='course-image-and-title'>
                <img 
                  src={reactfigma}
                  width="100%"
                  height="100%"
                  alt='icon'
                  className='course-image'
                />
              </div>
              <div className='amount-list'>
                <span className="naira-symbol">&#x20A6;</span>
                <span className='amount-style'>97K</span>
              </div>
              <Link to="/IndustrialTraining/FullstackProductDesign" className='course-btn-style'>Get started</Link>
              <div className="all-courses-program-container">
                <div className="all-courses-program">
                  <img src={tick} className='tick_style' />
                  <span>Includes HTML, CSS, Javascript, React js, Figma</span>
                </div>
                <div className="all-courses-program">
                  <img src={tick} className='tick_style' />
                  <span>8 weeks Intensive + Practical Classes</span>
                </div>
                <div className="all-courses-program">
                  <img src={tick} className='tick_style' />
                  <span>Certificates are awarded at the end of the program.</span>
                </div>
              </div>
              <div className="education-learn-btn1">
                <Link to="/IndustrialTraining/FullstackProductDesign" className="edu-section_learn_more1">
                  Get Started
                </Link>
                <img src={right} className="edu-learn_more_icon" />
              </div>
            </div>
            <div className='intern-courses-card2'>
              <p className='course-title'>FULLSTACK MOBILE APP DEVELOPMENT</p>
              <div className='course-image-and-title'>
                <img 
                  src={flutterjs}
                  width="100%"
                  height="100%"
                  alt='icon'
                  className='course-image'
                  />
              </div>
              <div className='amount-list'>
                <span className="naira-symbol">&#x20A6;</span>
                <span className='amount-style'>99K</span>
              </div>
              <Link to="/IndustrialTraining/FullstackMobileApp" className='course-btn-style'>Get started</Link>
              <div className="all-courses-program-container">
                <div className="all-courses-program">
                  <img src={tick} className='tick_style' />
                  <span>Includes HTML, CSS, Javascript</span>
                </div>
                <div className="all-courses-program">
                  <img src={tick} className='tick_style' />
                  <span>8 weeks Intensive + Practical Classes</span>
                </div>
                <div className="all-courses-program">
                  <img src={tick} className='tick_style' />
                  <span>Certificates are awarded at the end of the program.</span>
                </div>
              </div>
              <div className="education-learn-btn1">
                <Link to="/IndustrialTraining/FullstackProductDesign" className="edu-section_learn_more1">
                  Get Started
                </Link>
                <img src={right} className="edu-learn_more_icon" />
              </div>
            </div> 
          </div>     
        </div>
      </div>
    </div>
  );
}
