import React, {useState} from 'react'
import "./ecourses.css";
import badge from "../../images/badgeeeee.png";
import backend from "../../images/backenddevfull.webp";
import backendprt from "../../images/backenddevfullprt.png"
import history from "../../images/history.png";
import cert from "../../images/smallcert.png";
import check from "../../images/check.png";
import frontend from "../../images/frontendprev.webp";
import user from "../../images/courseuser.png";
import yellow from "../../images/star.png";
import white from "../../images/ratewhite.png";
import mern from "../../images/mernprev.webp";

const E_Backend = () => {

    const [isYellow, setisYellow] = useState(true);

    const handleClick = () => {
        setisYellow(prevState => !prevState);
    };


    const [isYellow5, setisYellow5] = useState(true);

    const handleClick5 = () => {
        setisYellow5(prevState => !prevState);
    };
    
    const [isMobile1] = useState(window.innerWidth < 768);


  return (
    <div className='e-course-main'>
        <div className='courses-bground'>
            <div className='courses-bground-text'>
                <img
                    src={badge}
                    width="auto"
                    height='70px'
                    alt='icon'
                />
                <h1>Deebug Institute Certified: Backend (NodeJS) Software Developement</h1>
                <h2>Become a proficient Backend Software Developer with our comprehensive professional course.</h2>
                <button><a href='https://elearning.deebug.org'>Apply now</a></button>

            </div>
            <div className='courses-bground-img'>
                <img
                    src={isMobile1 ? backendprt : backend}
                    width="100%"
                    height='100%'
                    alt='icon'
                />
            </div>
        </div>
        <div className='course-details'>
            <h1>Course details</h1>
            <div className='course-duration'>
                <div className='course-duration-child'>
                    <div className='course-duration-props'>
                        <div className='course-duration-content'>
                            <img
                                src={history}
                                width="auto"
                                height="auto"
                                alt='icon'
                            />
                            <h1>Level</h1>
                        </div>
                        <div className='course-duration-props-child'>
                            <p>Beginner</p>
                        </div>
                    </div>
                    <div className='course-duration-props'>
                        <div className='course-duration-content'>
                            <img
                                src={history}
                                width="auto"
                                height="auto"
                                alt='icon'
                            />
                            <h1>Price</h1>
                        </div>
                        <div className='course-duration-props-child'>
                            <p> ₦75,000</p>
                        </div>
                    </div>
                </div>
                <div className='course-duration-child'>
                    <div className='course-duration-props'>
                        <div className='course-duration-content'>
                            <img
                                src={history}
                                width="auto"
                                height="auto"
                                alt='icon'
                            />
                            <h1>Duration</h1>
                        </div>
                        <div className='course-duration-props-child'>
                            <p>3 months @ 6 hrs per week</p>
                        </div>
                    </div>
                    <div className='course-duration-props'>
                        <div className='course-duration-content'>
                            <img
                                src={history}
                                width="auto"
                                height="auto"
                                alt='icon'
                            />
                            <h1>Prerequisites</h1>
                        </div>
                        <div className='course-duration-props-child'>
                            <p>No prior experience required</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='course-overview'>
            <h1>Course overview</h1>
            <p>
                This comprehensive course covers a wide range of topics essential for 
                backend development. Participants will delve into data structures and 
                algorithms, software development methodologies, backend frameworks, 
                containerization, cloud management, and more.
            </p>
            <p>
                Through hands-on projects and real-world examples, learners will 
                gain practical experience and develop the skills needed to build 
                robust, scalable, and secure backend systems.
            </p>
        </div>
        <div className="course-content">
            <h1>Course content</h1>
            <p>7 Modules</p>
            <div className='course-week'>
                <div className='course-header'>
                    <span>Foundations of Software Development</span>
                    <span>Backend Development Fundamentals</span>
                    <span>DevOps Practices</span>
                    <span>Database Management</span>
                    <span>Backend Testing and Security</span>
                    <span>Advanced Backend Development</span>
                    <span>Version Control and Collaboration</span>
                </div>
            </div>
        </div>
        <div className='course-certificate'>
            <div className='course-skill'>
                <h1>Learning outcome</h1>
                <p>By successfully completing this comprehensive program, you will be well-equipped to:</p>
                <div className='course-skill-content'>
                    <img
                        src={check}
                        width="auto"
                        height="auto"
                        alt='check'
                    />
                    <p>Create a basic API endpoint using a backend framework</p>
                </div>
                <div className='course-skill-content'>
                    <img
                        src={check}
                        width="auto"
                        height="auto"
                        alt='check'
                    />
                    <p>Write queries to retrieve and manipulate data using SQL or a document database like MongoDB.</p>
                </div>
                <div className='course-skill-content'>
                    <img
                        src={check}
                        width="auto"
                        height="auto"
                        alt='check'
                    />
                    <p>Creating repositories, committing changes, branching, and merging code using Git.</p>
                </div>
                <div className='course-skill-content'>
                    <img
                        src={check}
                        width="auto"
                        height="auto"
                        alt='check'
                    />
                    <p>API Testing</p>
                </div>
                <div className='course-skill-content'>
                    <img
                        src={check}
                        width="auto"
                        height="auto"
                        alt='check'
                    />
                    <p>Implement Basic Security</p>
                </div>
            </div>
            <div className='course-certificate-img'>
                <img
                    src={cert}
                    width="auto"
                    height="auto"
                    alt='icon'
                />
                <button><a href='https://elearning.deebug.org'>Apply now</a></button>
            </div>
        </div>
        <div className='course-related'>
            <h1>Related Courses</h1>
            <div className='e-courses-container'>
                <div className='explore-courses-ctn'>
                    <div className='explore-courses-ctn-img'>
                        <img
                            src={frontend}
                            width="100%"
                            height="100%"
                            alt='logo'
                        />
                    </div>
                    <div className='explore-courses-ctn-text'>
                        <h2> <a href='frontend-web-development'>Frontend Web Development</a></h2>
                        <p>Learn how to craft interactive, visually engaging websites using HTML, CSS, and ReactJS.</p>
                    </div>
                    <div className='explore-course-about'>
                        <p>₦ 75,000</p>
                        <div className='explore-course-review'>
                            <img
                                src={user}
                                width="22px"
                                height="22px"
                                alt='icon'
                            />
                            <p>210+</p>
                            <div className='wishlist-container' onClick={handleClick}>
                                <img
                                    src={isYellow ? white : yellow}
                                    alt={isYellow ? 'rating' : 'rating'}
                                    className={`rate-image ${isYellow ? 'rate-fade-in' : 'rate-fade-out'}`}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='explore-courses-ctn'>
                    <div className='explore-courses-ctn-img'>
                        <img
                            src={mern}
                            width="100%"
                            height="100%"
                            alt='logo'
                        />
                    </div>
                    <div className='explore-courses-ctn-text'>
                        <h2>Fullstack Web Development</h2>
                        <p>Learn MongoDB, Express.js, React, & Node.js, to create dynamic & scalable solutions.</p>
                    </div>
                    <div className='explore-course-about'>
                        <p>₦ 150,000</p>
                        <div className='explore-course-review'>
                            <img
                                src={user}
                                width="22px"
                                height="22px"
                                alt='icon'
                            />
                            <p>65+</p>
                            <div className='wishlist-container' onClick={handleClick5}>
                                <img
                                    src={isYellow5 ? white : yellow}
                                    alt={isYellow5 ? 'rating' : 'rating'}
                                    className={`rate-image ${isYellow5 ? 'rate-fade-in' : 'rate-fade-out'}`}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default E_Backend