import React, { useRef, useEffect, useState } from "react";
import M1 from "../images/m1.png";
import M2 from "../images/m2.png";
import { useWindowWidth, useWindowHeight } from "@react-hook/window-size";
import "./UnderMaintenance.css";
export default function Maintenance() {
  const [mobile, setMobile] = useState(false);

  const width = useWindowWidth();

  useEffect(() => {
    if (width <= 767) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, [width]);

  return (
    <div className="maintenance-parent">
      <div className="m-first">
        <img src={M1} className="m-first-img" alt=" " />
      </div>
      <div className="m-second">
        <div className="m-second-group">
          <p>MAINTENACE MODE</p>
          <p>Our website is currently undergoing scheduled maintenance</p>
          <img src={M2} className="m-second-img" alt=" " />
        </div>
      </div>
    </div>
  );
}
