import React, { useState } from 'react'
import "./ecourses.css";
import badge from "../../images/badgeeeee.png";
import uxdesign from "../../images/UXdesign.webp";
import uxdesignprt from "../../images/UXdesignprt.png"
import history from "../../images/history.png";
import cert from "../../images/smallcert.png";
import check from "../../images/check.png";
import frontend from "../../images/frontendprev.webp";
import user from "../../images/courseuser.png";
import yellow from "../../images/star.png";
import white from "../../images/ratewhite.png";
import fullprod from "../../images/fullprodprev.webp";

const E_UXDesign = () => {

    const [isYellow, setisYellow] = useState(true);

    const handleClick = () => {
        setisYellow(prevState => !prevState);
    };


    const [isYellow8, setisYellow8] = useState(true);

    const handleClick8 = () => {
        setisYellow8(prevState => !prevState);
    };

    const [isMobile1] = useState(window.innerWidth < 768);

    return (
        <div className='e-course-main'>
            <div className='courses-bground'>
                <div className='courses-bground-text'>
                    <img
                        src={badge}
                        width="auto"
                        height='70px'
                        alt='icon'
                    />
                    <h1>Deebug Institute Certified: UX/UI Design</h1>
                    <h2>Become a proficient UX/UI Designer with our comprehensive professional course.</h2>
                    <button><a href='https://elearning.deebug.org'>Apply now</a></button>
                </div>
                <div className='courses-bground-img'>
                    <img
                        src={isMobile1 ? uxdesignprt : uxdesign}
                        width="100%"
                        height='100%'
                        alt='icon'
                    />
                </div>
            </div>
            <div className='course-details'>
                <h1>Course details</h1>
                <div className='course-duration'>
                    <div className='course-duration-child'>
                        <div className='course-duration-props'>
                            <div className='course-duration-content'>
                                <img
                                    src={history}
                                    width="auto"
                                    height="auto"
                                    alt='icon'
                                />
                                <h1>Level</h1>
                            </div>
                            <div className='course-duration-props-child'>
                                <p>Beginner</p>
                            </div>
                        </div>
                        <div className='course-duration-props'>
                            <div className='course-duration-content'>
                                <img
                                    src={history}
                                    width="auto"
                                    height="auto"
                                    alt='icon'
                                />
                                <h1>Price</h1>
                            </div>
                            <div className='course-duration-props-child'>
                                <p> ₦65,000</p>
                            </div>
                        </div>
                    </div>
                    <div className='course-duration-child'>
                        <div className='course-duration-props'>
                            <div className='course-duration-content'>
                                <img
                                    src={history}
                                    width="auto"
                                    height="auto"
                                    alt='icon'
                                />
                                <h1>Duration</h1>
                            </div>
                            <div className='course-duration-props-child'>
                                <p>3 months @ 6 hrs per week</p>
                            </div>
                        </div>
                        <div className='course-duration-props'>
                            <div className='course-duration-content'>
                                <img
                                    src={history}
                                    width="auto"
                                    height="auto"
                                    alt='icon'
                                />
                                <h1>Prerequisites</h1>
                            </div>
                            <div className='course-duration-props-child'>
                                <li>A passion for design and a commitment to mastering user experience principles.</li>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='course-overview'>
                <h1>Course overview</h1>
                <p>
                    Advance your career with our comprehensive UX/UI Design Certification, designed
                    to equip you with the expertise needed to excel in the dynamic field of user
                    experience design.This course delves into all critical aspects of UX/UI design,
                    from foundational principles of user research, information architecture, and
                    interaction design, to advanced practices in usability testing, prototyping,
                    and user interface design.

                </p>
                <p>
                    You will learn to create intuitive and engaging user experiences using Figma an
                    industry-standard tools while mastering methodologies such as Design Thinking and
                    Lean UX. With hands-on projects, real-world case studies, and expert mentorship,
                    you will develop the skills and confidence to succeed as a UX/UI Designer in today’s competitive market.
                </p>
            </div>
            <div className="course-content">
                <h1>Course content</h1>
                <p>12 Modules</p>
                <div className='course-week'>
                    <div className='course-header'>
                        <span>Introduction to UX Design</span>
                        <span>User Research</span>
                        <span>User Experience Design</span>
                        <span>Usability and Accessibility</span>
                        <span>Interaction Design</span>
                        <span>Visual Design</span>
                        <span>Design Tools and Software</span>
                        <span>Designing for Digital Health</span>
                        <span>Designing for Business</span>
                        <span>UX Design Best Practices</span>
                    </div>
                </div>
            </div>
            <div className='course-certificate'>
                <div className='course-skill'>
                    <h1>Learning outcome</h1>
                    <p>By successfully completing this comprehensive program, you will be well-equipped to:</p>
                    <div className='course-skill-content'>
                        <img
                            src={check}
                            width="auto"
                            height="auto"
                            alt='check'
                        />
                        <p>Understand core UX design principles and user-centered design methodologies.</p>
                    </div>
                    <div className='course-skill-content'>
                        <img
                            src={check}
                            width="auto"
                            height="auto"
                            alt='check'
                        />
                        <p>Conduct effective user research and usability testing.</p>
                    </div>
                    <div className='course-skill-content'>
                        <img
                            src={check}
                            width="auto"
                            height="auto"
                            alt='check'
                        />
                        <p>Develop wireframes, prototypes, and high-fidelity designs using leading design tools.</p>
                    </div>
                    <div className='course-skill-content'>
                        <img
                            src={check}
                            width="auto"
                            height="auto"
                            alt='check'
                        />
                        <p>Create responsive and accessible interfaces that enhance user satisfaction.</p>
                    </div>
                    <div className='course-skill-content'>
                        <img
                            src={check}
                            width="auto"
                            height="auto"
                            alt='check'
                        />
                        <p>Implement best practices for information architecture and interaction design.</p>
                    </div>
                    <div className='course-skill-content'>
                        <img
                            src={check}
                            width="auto"
                            height="auto"
                            alt='check'
                        />
                        <p>Collaborate efficiently within cross-functional teams.</p>
                    </div>
                    <div className='course-skill-content'>
                        <img
                            src={check}
                            width="auto"
                            height="auto"
                            alt='check'
                        />
                        <p>Confidently apply for UX Design positions and advance your career in the tech industry.</p>
                    </div>
                    <div className='course-skill-content'>
                        <img
                            src={check}
                            width="auto"
                            height="auto"
                            alt='check'
                        />
                        <p>Build a design portfolio</p>
                    </div>
                </div>
                <div className='course-certificate-img'>
                    <img
                        src={cert}
                        width="auto"
                        height="auto"
                        alt='icon'
                    />
                    <button><a href='https://elearning.deebug.org'>Apply now</a></button>
                </div>
            </div>
            <div className='course-related'>
                <h1>Related Courses</h1>
                <div className='e-courses-container'>
                    <div className='explore-courses-ctn'>
                        <div className='explore-courses-ctn-img'>
                            <img
                                src={frontend}
                                width="100%"
                                height="100%"
                                alt='logo'
                            />
                        </div>
                        <div className='explore-courses-ctn-text'>
                            <h2> <a href='frontend-web-development'>Frontend Web Development</a></h2>
                            <p>Learn how to craft interactive, visually engaging websites using HTML, CSS, and ReactJS.</p>
                        </div>
                        <div className='explore-course-about'>
                            <p>₦ 75,000</p>
                            <div className='explore-course-review'>
                                <img
                                    src={user}
                                    width="22px"
                                    height="22px"
                                    alt='icon'
                                />
                                <p>210+</p>
                                <div className='wishlist-container' onClick={handleClick}>
                                    <img
                                        src={isYellow ? white : yellow}
                                        alt={isYellow ? 'rating' : 'rating'}
                                        className={`rate-image ${isYellow ? 'rate-fade-in' : 'rate-fade-out'}`}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='explore-courses-ctn'>
                        <div className='explore-courses-ctn-img'>
                            <img
                                src={fullprod}
                                width="100%"
                                height="100%"
                                alt='logo'
                            />
                        </div>
                        <div className='explore-courses-ctn-text'>
                            <h2>Fullstack Product Design</h2>
                            <p>Learn to connect devices and systems, enabling seamless communication, data sharing, and collaboration across vast distances.</p>
                        </div>
                        <div className='explore-course-about'>
                            <p>₦ 140,000</p>
                            <div className='explore-course-review'>
                                <img
                                    src={user}
                                    width="22px"
                                    height="22px"
                                    alt='icon'
                                />
                                <p>35   +</p>
                                <div className='wishlist-container' onClick={handleClick8}>
                                    <img
                                        src={isYellow8 ? white : yellow}
                                        alt={isYellow8 ? 'rating' : 'rating'}
                                        className={`rate-image ${isYellow8 ? 'rate-fade-in' : 'rate-fade-out'}`}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default E_UXDesign