import React, { useEffect, useState, useRef } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import ChevronRight from "../../images/chevron_right_blue.png";
// import ChevronRight from "../../images/chevron_right_blue.png";
import ChevronDown from "../../images/arrow_down.png";
import RegSection1 from "../../images/regularpagebground.webp";
import RegSection1Mobile from "../../images/regularpagebgroundprt.webp";
import ELearning from "../../images/e_learning.png";
import HandsOn from "../../images/hands_on.png";
import HandsOn1 from "../../images/hack1.webp";
import regsect1 from "../../images/regsect1.png";
import regfun from "../../images/regularcoursefun.webp";
import grad from "../../images/regulargrad.png";
import regsect from "../../images/p3.png";
import { useWindowWidth, useWindowHeight } from "@react-hook/window-size";
import responsivebtn from '../../images/hmbdwn.png';
import tick from "../../images/tick.png";
import react from "../../images/react.png";
import figma from "../../images/figma.png";
import node from "../../images/node.png";
import flutter from "../../images/flutter.png";
import reactnode from '../../images/reactnode.png';
import reactfigma from '../../images/reactfigma.png';
import flutterjs from '../../images/flutterjs.png';
import right from "../../images/blueright.png";
import teststory9 from '../../images/teststory9.webp';
import teststory8 from '../../images/teststory8.webp';
import teststory3 from '../../images/teststory3.webp';
import teststory5 from '../../images/teststory5.webp';
import teststory10 from '../../images/teststory10.webp'; 
import previous from '../../images/eduprevious.png';
import next from '../../images/forwardblack.png';   
import "./RegularStudents.css";

export default function RegularStudents() {
  const navigate = useNavigate();
  const [mobile, setMobile] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const width = useWindowWidth();

  useEffect(() => {
    if (width <= 767) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, [width]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  
  const testimonials = [
    {
        text: "“I had a great experience at Deebug Institute learning full-stack web development. The curriculum was comprehensive, and the instructors were knowledgeable and supportive. I highly recommend Deebug Institute for advancing your tech career.”",
        name: "Daniel Success Daudu",
        class: "Class of 2024",
        image: teststory8
    },
    {
        text: "“Learning at Deebug has been a milestone in my tech journey. Using industry tools, sharing ideas, and working with mentors and colleagues has been an amazing experience. Step out of your comfort zone and join the Deebug family.”",
        name: "Kayode Obiora",
        class: "Class of 2024",
        image: teststory10
    },
    {
        text: "“Deebug Institute sets the gold standard for tech education with top-notch knowledge, expert guidance, hands-on learning, and unparalleled support. Deebug provides the perfect platform to realize your aspirations in the dynamic world of technology.”",
        name: "Thomas Tunde",
        class: "Class of 2024",
        image: teststory9
    },    
    {
        text: "“I had the privilege of learning at Debug Institute, and it was an incredible experience. The team felt like a big family, supportive and encouraging. Debug Institute offers great opportunities for growth and development. I highly recommend it.”",
        name: "Becky",
        class: "Class of 2024",
        image: teststory5
    },
    {
        text: "“My time at Deebug Institute was amazing! The well-organized courses and experienced instructors were incredibly helpful. The practical training provided valuable, immediately usable skills. I highly recommend Deebug Institute.”",
        name: "Precious",
        class: "Class of 2024",
        image: teststory3
    }
];

const [currentIndex, setCurrentIndex] = useState(0);
const containerRef = useRef(null);
const containerRef1 = useRef(null);

const handleScroll = (direction) => {
  const container = containerRef.current;
  const containerWidth = container.clientWidth;
  const newIndex = direction === 'left'
    ? Math.max(currentIndex - 1, 0)
    : Math.min(currentIndex + 1, testimonials.length - 1);

  setCurrentIndex(newIndex);
  container.scrollTo({
    left: newIndex * containerWidth,
    behavior: 'smooth'
  });
};


const currentLocation = useLocation();

useEffect(() => {
  if (currentLocation.hash === "#skill-sprint-course") {
    const targetElement = document.querySelector('.skill-sprint-course');
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth" });
    }
  }
}, [currentLocation]);


  

  return (
    <div className="regular-main-page">
      <div className="rg-sub-navbar">
        <p>Skill Sprints</p>
          <ul className={isOpen ? "intern-menu active" : "intern-menu"}>
            <li><span onClick={() => navigate("/RegularStudents")}>Overview</span></li>
            <li><span onClick={() => navigate("/allcourses")}>Courses</span></li>
            <li><span className="backend-course-sub-navbar-selection2" onClick={() => navigate("/RegularTrainingRegistration")}>Apply</span></li>
          </ul>
        <div className="intern-ham-btn">
          <img 
            src={responsivebtn}
            width="100%"
            height="100%"
            alt='icon'
            onClick={toggleMenu}
          />
        </div>
        <span className="backend-course-sub-navbar-selection1" onClick={() => navigate("/RegularTrainingRegistration")}>Apply</span>
      </div>
      <div className="bootcamp-section1-item">
        <img
          src={mobile ? RegSection1Mobile : RegSection1}
          className="header-section1-img"
        />
      </div>
      <div className="regular-section2-container">
        <p className="reg-section2-main-title">Teach. Innovate. Inspire</p>
        <p className="reg-section2-subtitle">
          In the realm of education, our mantra is simple yet powerful. We believe 
          in not just transferring knowledge but sparking innovation and igniting 
          inspiration. Our commitment is to foster an environment where teaching 
          Dynamic, innovation fuels curiosity, and inspiration becomes the driving 
          force for lifelong learning.
        </p>
        <div style={{ display: "flex", justifyContent: "center", paddingBottom: "2rem", }}>
          <a className="hackathon_more" href="/allcourses">
            Explore courses
          </a>
          <img src={ChevronDown} className="hackathon_more_icon" />
        </div>
      </div>
      <div className="regular-student-container1">
        <div className="reg-section3-bg">
          <p className="rg-section3-title">Teaching and Coaching</p>
          <p className="rg-section3-subtitle">
            Teaching goes beyond imparting knowledge. It's a journey of
            empowerment. Our dedicated educators are not just instructors but
            mentors, guiding students towards a deeper understanding and
            application of concepts. Through coaching, we nurture individual
            strengths, fostering a dynamic learning environment.
          </p>
        </div>
        <div className="reg-section4-container">
          <div className="reg-section4-info-group">
            <p>
              <span style={{ color: "rgba(200, 0, 69, 1)" }}>
                Online Learning.
              </span>{" "}
              Dive into a world where education knows no boundaries, and your
              quest for knowledge is just a click away.
            </p>
          </div>
          <img src={ELearning} className="reg-section4-img" alt="" />
        </div>
        <div className="reg-section1-bg">
          <div className="reg-section7-bg">
            <p className="rg-section3-title">Gain Hands-on Practical Experience in your chosen course of study.</p>
            <div className="reg-section7-img">
              <img 
                src={HandsOn1}
                width="100%"
                height="100%"
                alt="icon"
                />
            </div>
          </div>
          <div className="reg-section8-bg">
            <div className="reg-section8-img">
              <img 
                src={regsect}
                width="100%"
                height="100%"
                alt="icon"
                />
            </div>
            <p>We are committed to a <span>practical learning approach</span> ensuring that you not only grasp the theory but also develop the practical expertise needed by engaging you in practical exercises.</p>
          </div>
        </div>
      </div>
      <div className="regular-student-container2">
        <div className="reg-section2-bg">
          <p className="rg-section2-title">Explore Beyond Academics</p>
          <p className="rg-section2-subtitle">
            Our commitment goes beyond traditional teaching methods. 
            We believe in providing immersive experiences that foster 
            creativity, collaboration, and innovation. We offer a diverse 
            range of events designed to ignite passion, broaden horizons, 
            and unleash the full potential of our students.
          </p>
        </div>
        <div className="reg-section4-container">
          <div className="reg-section2-info-group">
            <p>
              Unforgettable events like Game day, <span>Hackathon, 
              Career expedition,</span> and<span> Innovation showcase </span> 
              are designed to enhance your learning journey.
            </p>
          </div>

          <img src={regfun} className="reg-section4-img" alt="" />
        </div>
        <div className="reg-section1-bg">
          <div className="reg-section7-bg">
            <p className="rg-section2-title">Curriculum designed to empower you with the skills needed to excel. </p>
            <div className="reg-section7-img">
              <img 
                src={HandsOn}
                width="90%"
                height="100%"
                alt="icon"
                />
            </div>
          </div>
          <div className="reg-section9-bg">
            <div className="reg-section8-img">
              <img 
                src={regsect1}
                width="100%"
                height="100%"
                alt="icon"
                />
            </div>
            <p>
              Our program is designed to equip you with the <span>essential skills </span>
              and <span>knowledge</span> required to not just succeed, but thrive. From basic principles,
              each module is designed to help you develop the right skill set to thrive.
            </p>
          </div>
        </div>
      </div>
      <div className="regular-student-container5">
        <div className="reg-section3-bg">
          <p
            className="rg-section3-title"
            style={{ color: "rgba(103, 140, 178, 1)" }}
          >
            Bringing out the Best.
          </p>
          <p className="rg-section3-subtitle">
            We pride ourselves on producing graduates who are not only well-prepared but truly exceptional, as our rigorous academic programs, teaching methods, and emphasis on real-world experience ensure that our graduates stand out in the competitive tech landscape, reflecting our commitment to nurturing talent and fostering excellence.
          </p>
        </div>     
        <div className="reg-section4-container">
          <div className="reg-section4-info-group">
            <p>
              <span style={{ color: "rgba(103, 140, 178, 1)" }}>Certification.</span>{" "}
              Upon successful completion of your course, you'll be awarded a
              prestigious certificate. This testament to your achievements not
              only acknowledges your dedication but also opens doors to new
              opportunities in the tech world.
            </p>
          </div>

          <img src={grad} className="reg-section5-img" alt="" />
        </div>
      </div>
      <div className="skill-sprint-course">
        <div className="ind-tr-black-bg">
            <h1 className="ind-tr-header">SKILL SPRINT COURSES</h1>
          <p className="ind-tr-section7-subtitle">
            Gain new skills with our focused and practical courses, designed for maximum impact in a short time.
          </p>
          <p className="ind-tr-section7-title-gradient">SINGLE TRACKS</p>
          <br />
          <br />
          <br />
          <div className="intern-courses-main-container">
            <div className="intern-courses-card2">
              <p className="course-title">BACKEND DEVELOPMENT</p>
              <div className="course-image-and-title">
                <img
                  src={node}
                  width="100%"
                  height="100%"
                  alt="icon"
                  className="course-image"
                />
                <p>NodeJS</p>
              </div>
              <div className="amount-list">
                <span className="naira-symbol">&#x20A6;</span>
                <span className="amount-style">75K</span>
              </div>
              <Link to="/RegularTrainingRegistration" className="course-btn-style">
                Apply Now
              </Link>
              <div className="all-courses-program-container">
                <div className="all-courses-program">
                  <img src={tick} className="tick_style" />
                  <span>Includes Node js, Express, Javascript. Databases like MySQL and Mongo db</span>
                </div>
                <div className="all-courses-program">
                  <img src={tick} className="tick_style" />
                  <span>14 WEEKS Intensive + Practical Classes</span>
                </div>
                <div className="all-courses-program">
                  <img src={tick} className="tick_style" />
                  <span>
                    Full access to the interactive community to aid with
                    assignments
                  </span>
                </div>
                <div className="all-courses-program">
                  <img src={tick} className="tick_style" />
                  <span>
                    Certificates are awarded at the end of the program.
                  </span>
                </div>
                    <div className="education-learn-btn1">
                      <Link to="/BackendDevelopment" className="edu-section_learn_more1">
                        Get Started
                      </Link>
                      <img src={right} className="edu-learn_more_icon" />
                    </div>
              </div>
            </div>
            <div className="intern-courses-card2">
              <p className="course-title">FRONTEND DEVELOPMENT</p>
              <div className="course-image-and-title">
                <img
                  src={react}
                  width="100%"
                  height="100%"
                  alt="icon"
                  className="course-image"
                />
                <p>React</p>
              </div>
              <div className="amount-list">
                <span className="naira-symbol">&#x20A6;</span>
                <span className="amount-style">75K</span>
              </div>
              <Link to="/RegularTrainingRegistration" className="course-btn-style">
                Apply Now
              </Link>
              <div className="all-courses-program-container">
                <div className="all-courses-program">
                  <img src={tick} className="tick_style" />
                  <span>Includes HTML, CSS, Javascript and React</span>
                </div>
                <div className="all-courses-program">
                  <img src={tick} className="tick_style" />
                  <span>14 WEEKS Intensive + Practical Classes</span>
                </div>
                <div className="all-courses-program">
                  <img src={tick} className="tick_style" />
                  <span>
                    Certificates are awarded at the end of the program.
                  </span>
                </div>
                  <div className="education-learn-btn1">
                    <Link to="/FrontendDevelopment" className="edu-section_learn_more1">
                      Get Started
                    </Link>
                    <img src={right} className="edu-learn_more_icon" />
                  </div>
              </div>
            </div>
            <div className="intern-courses-card2">
              <p className="course-title">MOBILE APP DEVELOPMENT</p>
              <div className="course-image-and-title">
                <img
                  src={flutter}
                  width="100%"
                  height="100%"
                  alt="icon"
                  className="course-image"
                />
                <p>Flutter</p>
              </div>
              <div className="amount-list">
                <span className="naira-symbol">&#x20A6;</span>
                <span className="amount-style">80K</span>
              </div>
              <Link to="/RegularTrainingRegistration" className="course-btn-style">
                Apply Now
              </Link>
              <div className="all-courses-program-container">
                <div className="all-courses-program">
                  <img src={tick} className="tick_style" />
                  <span>Includes flutter development framework using the dart programming language.</span>
                </div>
                <div className="all-courses-program">
                  <img src={tick} className="tick_style" />
                  <span>14 WEEKS Intensive + Practical Classes</span>
                </div>
                <div className="all-courses-program">
                  <img src={tick} className="tick_style" />
                  <span>
                    Full access to the interactive community to aid with
                    assignments
                  </span>
                </div>
                <div className="all-courses-program">
                  <img src={tick} className="tick_style" />
                  <span>
                    Certificates are awarded at the end of the program.
                  </span>
                </div>
              </div>
                <div className="education-learn-btn1">
                  <Link to="/MobileAppDevelopment" className="edu-section_learn_more1">
                    Get Started
                  </Link>
                  <img src={right} className="edu-learn_more_icon" />
                </div>
            </div>
            <div className="intern-courses-card2">
              <p className="course-title">PRODUCT DESIGN</p>
              <div className="course-image-and-title">
                <img
                  src={figma}
                  width="100%"
                  height="100%"
                  alt="icon"
                  className="course-image"
                />
                <p>Figma</p>
              </div>
              <div className="amount-list">
                <span className="naira-symbol">&#x20A6;</span>
                <span className="amount-style">70K</span>
              </div>
              <Link to="/RegularTrainingRegistration" className="course-btn-style">
                Apply Now
              </Link>
              <div className="all-courses-program-container">
                <div className="all-courses-program">
                  <img src={tick} className="tick_style" />
                  <span>Includes user research, design thinking principles, design tools like Figma, </span> 
                </div>
                <div className="all-courses-program">
                  <img src={tick} className="tick_style" />
                  <span>12 WEEKS Intensive + Practical Classes</span>
                </div>
                <div className="all-courses-program">
                  <img src={tick} className="tick_style" />
                  <span>
                    Certificates are awardddded at the end of the program.
                  </span>
                </div>
              </div>
              <div className="education-learn-btn1">
                <Link to="/FullstackProductDesign" className="edu-section_learn_more1">
                  Get Started
                </Link>
                <img src={right} className="edu-learn_more_icon" />
              </div>
            </div>            
          </div>
          <div className="industrial-courses-view-more">
            {/* <div className="edu-learn-btn-it2">
              <a
                href="Courses"
                className="section_learn_more"
              >
                {" "}
                View all
              </a>
              <img src={ChevronRight} className="learn_more_icon" />
            </div> */}
          </div>
        </div>
        <div className="ind-tr-grey-bg">
          <p className="ind-tr-section7-title-gradient">SINGLE TRACKS</p>
          <div className="intern-courses-main-container">
                <div className='intern-courses-card2'>
                    <p className='course-title'>FULLSTACK WEB DEVELOPMENT</p>
                    <div className='course-image-and-title'>
                        <img 
                            src={reactnode}
                            width="100%"
                            height="100%"
                            alt='icon'
                            className='course-image'
                        />
                    </div>
                    <div className='amount-list'>
                        <span className="naira-symbol">&#x20A6;</span>
                        <span className='amount-style'>150K</span>
                    </div>
                    <Link to="/FullstackWebDevelopment" className='course-btn-style'>Get started</Link>
                    <div className="all-courses-program-container">
                        <div className="all-courses-program">
                            <img src={tick} className='tick_style' />
                            <span>Includes Node js, Express, Javascript. Databases like MySQL and Mongo db</span>
                        </div>
                        <div className="all-courses-program">
                            <img src={tick} className='tick_style' />
                            <span>14 WEEKS Intensive + Practical Classes</span>
                        </div>
                        <div className="all-courses-program">
                            <img src={tick} className='tick_style' />
                            <span>Full access to the interactive community to aid with assignments</span>
                        </div>
                        <div className="all-courses-program">
                            <img src={tick} className='tick_style' />
                            <span>Certificates are awarded at the end of the program.</span>
                        </div>
                    </div>
              <div className="education-learn-btn1">
                <Link to="/FullstackProductDesign" className="edu-section_learn_more1">
                  Get Started
                </Link>
                <img src={right} className="edu-learn_more_icon" />
              </div>
                </div>
            <div className='intern-courses-card2'>
                <p className='course-title'>FULL STACK SOFTWARE PRODUCT DESIGN</p>
                <div className='course-image-and-title'>
                    <img 
                        src={reactfigma}
                        width="100%"
                        height="100%"
                        alt='icon'
                        className='course-image'
                    />
                </div>
                <div className='amount-list'>
                    <span className="naira-symbol">&#x20A6;</span>
                    <span className='amount-style'>140K</span>
                </div>
                <Link to="/FullstackProductDesign" className='course-btn-style'>Get started</Link>
                <div className="all-courses-program-container">
                    <div className="all-courses-program">
                        <img src={tick} className='tick_style' />
                        <span>Includes HTML, CSS, Javascript, React js, Figma</span>
                    </div>
                    <div className="all-courses-program">
                        <img src={tick} className='tick_style' />
                        <span>14 WEEKS Intensive + Practical Classes</span>
                    </div>
                    <div className="all-courses-program">
                        <img src={tick} className='tick_style' />
                        <span>Certificates are awarded at the end of the program.</span>
                    </div>
                </div>
              <div className="education-learn-btn1">
                <Link to="/FullstackProductDesign" className="edu-section_learn_more1">
                  Get Started
                </Link>
                <img src={right} className="edu-learn_more_icon" />
              </div>
            </div>
                <div className='intern-courses-card2'>
                    <p className='course-title'>FULLSTACK MOBILE APP DEVELOPMENT</p>
                    <div className='course-image-and-title'>
                        <img 
                            src={flutterjs}
                            width="100%"
                            height="100%"
                            alt='icon'
                            className='course-image'
                        />
                    </div>
                    <div className='amount-list'>
                        <span className="naira-symbol">&#x20A6;</span>
                        <span className='amount-style'>155K</span>
                    </div>
                    <Link to="/FullstackMobileApp" className='course-btn-style'>Get started</Link>
                    <div className="all-courses-program-container">
                        <div className="all-courses-program">
                            <img src={tick} className='tick_style' />
                            <span>Includes flutter development framework using the dart programming language.</span>
                        </div>
                        <div className="all-courses-program">
                            <img src={tick} className='tick_style' />
                            <span>14 WEEKS Intensive + Practical Classes</span>
                        </div>
                        <div className="all-courses-program">
                            <img src={tick} className='tick_style' />
                            <span>Certificates are awarded at the end of the program.</span>
                        </div>
                    </div>
              <div className="education-learn-btn1">
                <Link to="/IndustrialTraining/FullstackProductDesign" className="edu-section_learn_more1">
                  Get Started
                </Link>
                <img src={right} className="edu-learn_more_icon" />
              </div>
                </div> 

          </div>     
        </div>
      </div>
      <div className='testimonial-main-container1'>
          <h1>Success Stories</h1>
          <div className='testimonial-sub-main-container1'>
              <button className='testimonial-btn1' onClick={() => handleScroll('left')}>
                  <img 
                      src={previous} 
                      width="100%" 
                      height="100%" 
                      alt='next'                             
                  />
              </button>
              <div className='testimonial-container1' ref={containerRef}>
                  {testimonials.map((testimonial, index) => (
                      <div className='testimonial-story1' key={index}>
                          <div className='testimonial-text1'>
                              <h1>{testimonial.text}</h1>
                              <div className='testimonial-personal1'>
                              <h2>{testimonial.name}</h2>
                              <p>{testimonial.class}</p>
                              </div>
                          </div>
                          <div className='testimonial-image1'>
                              <img
                              src={testimonial.image}
                              width="100%"
                              height="100%"
                              alt='testimonial'
                              className='testy-image'
                              />
                          </div>
                      </div>
                  ))}
              </div>
              <button className='testimonial-btn1' onClick={() => handleScroll('right')}>
                  <img 
                      src={next} 
                      width="100%" 
                      height="100%" 
                      alt='next'                             
                  />
              </button>
              <div className='testimonial-btn-prt-ctn'>
                  <button className='testimonial-btn1' onClick={() => handleScroll('left')}>
                      <img 
                          src={previous} 
                          width="100%" 
                          height="100%" 
                          alt='next'                             
                      />
                  </button>
                  <button className='testimonial-btn1' onClick={() => handleScroll('right')}>
                      <img 
                          src={next} 
                          width="100%" 
                          height="100%" 
                          alt='next'                             
                      />
                  </button>
              </div>
          </div>
          <div className='testimonial-btn-container3'>
              {testimonials.map((_, index) => (
              <button
                  key={index}
                  className={`indicator-btn1 ${index === currentIndex ? 'active' : ''}`}
                  onClick={() => setCurrentIndex(index)}
              >
                  <span className="indicator1" />
              </button>
              ))}
          </div>
      </div>      
    </div>
  );
}