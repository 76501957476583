import React from "react";
import "./Engineering.css";
import EngineeringServiceCard from "../components/EngineeringComps/EngineeringServiceCard";
import ChevronRight from "../images/chevron_right_blue.png";
import EngineeringSection1 from "../images/engineering.png";
import Heurekka from "../videos/heurekka_vid.mp4";
import NaijaPharms from "../videos/naijapharms_vid.mp4";
import Medplan from "../videos/medplan_vid.mp4";

import S1 from "../images/s1.png";
import S2 from "../images/s2.png";
import S3 from "../images/s3.png";
import engmail from '../images/engmail.png';
import engcall from '../images/engcall.png';
import engchat from '../images/engchat.png';

import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useWindowWidth, useWindowHeight } from "@react-hook/window-size";

export default function Engineering() {
  const navigate = useNavigate();
  const [mobile, setMobile] = useState(false);
  const width = useWindowWidth();

  useEffect(() => {
    if (width <= 767) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, [width]);

  return (
    <div className="engr-parent-container">
      <div className="engr-section1-container">
        <p className="engr-section1-title">Build Elegant Solutions</p>
        <p className="engr-section1-subtitle">
          We are not just developing solutions; we are shaping the future. With
          a commitment to innovation and a focus on real-world impact, our team
          is dedicated to crafting solutions that transcend today's challenges.
        </p>

        <div className="engr-section1-card">
          <div className="engr-section1-image">
            <img
              src={EngineeringSection1}
              width="100%"
              height="100%"
              className="header-section1-img"
              alt="engineering"
            />
          </div>

          <div className="engr-section1-content">
            <p>Building app for real world experience</p>
            <p>
              From elegant solutions that streamline daily tasks to
              transformative applications that address real-world challenges, we
              are dedicated to crafting apps that resonate with practicality and
              purpose.
            </p>
          </div>
        </div>
      </div>
      {/*  */}

      <div className="engr-section2-container">
        <div className="engr-section2-title-container">
          <p>Our Services</p>
          <p>What we know how to do best</p>
        </div>
        <EngineeringServiceCard
          title={"Website Development"}
          subtitle={
            "We understand what scalability is and how to harness the principles and standards from design right through implementation of your website."
          }
          image={S1}
        />
        <EngineeringServiceCard
          title={"Mobile App Development"}
          subtitle={
            "Our team of highly capable developers have a breadth of experience developing apps for many industries and can build any type of functionality for your app."
          }
          image={S2}
        />
        <EngineeringServiceCard
          title={"UI/UX Design"}
          subtitle={
            "Design is not just what it looks like and feels like. Design is how it works. We pride ourselves in designing apps that are both visually stunning and highly intuitive."
          }
          image={S3}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "50px",
          }}
        >
          <a className="section_learn_more" href="/EngineeringServices">
            View All
          </a>
          <img src={ChevronRight} className="learn_more_icon" />
        </div>
      </div>
      {/*  */}

      <div className="engr-section3-container">
        <div className="engr-section3-title-container">
          <h3>Our Products</h3>
          <h3>We unleash excellence across every project.</h3>
        </div>
        {/*  */}
        <div className="engr-section3-project-card">
          <div className="engr-section3-project-card-heurekka">
            <video autoPlay loop muted className="engr-video-style-custom">
              <source src={Heurekka} type="video/mp4" />
            </video>
          </div>
          <div className="engr-section3-project-card-details-container">
            <div className="engr-section3-project-card-details-title">
              Heurekka
            </div>
            <div className="engr-section3-project-card-details-description-container">
              <div>
                A platform designed to ignite collaboration, knowledge-sharing
                within the tech community and also help people connect with
                career opportunities seamlessly.
              </div>
              <span>
                <button
                  onClick={() => navigate("/Heurekka")}
                  className="engr-section3-project-card-button"
                >
                  + Learn more about this app
                </button>
              </span>
            </div>
          </div>
        </div>
        {/*  */}

        <div className="engr-section3-project-card">
          <div className="engr-section3-project-card-medplan">
            <video autoPlay loop muted className="engr-video-style-custom">
              <source src={Medplan} type="video/mp4" />
            </video>
          </div>
          <div className="engr-section3-project-card-details-container">
            <div className="engr-section3-project-card-details-title">
              Medplan
            </div>
            <div className="engr-section3-project-card-details-description-container">
              <div>
                Providing tools and resources which empower individuals to better 
                manage their own health. From Telemedicine to e-pharmacy, we got 
                you covered.
              </div>
              <span>
                <button
                  onClick={() => navigate("/maintenance")}
                  className="engr-section3-project-card-button"
                >
                  + Learn more about this app
                </button>
              </span>
            </div>
          </div>
        </div>
        {/*  */}

        <div className="engr-section3-project-card">
          <div className="engr-section3-project-card-naijapharms">
            <video autoPlay loop muted className="engr-video-style-custom">
              <source src={NaijaPharms} type="video/mp4" />
            </video>
          </div>
          <div className="engr-section3-project-card-details-container">
            <div className="engr-section3-project-card-details-title">
              NaijaPharms
            </div>
            <div className="engr-section3-project-card-details-description-container">
              <div>
                Connect & Collaborate with Pharmacists Nationwide. Share knowledge, 
                Find Jobs, and stay up-to-date with all Pharma activities.
              </div>
              <span>
                <button
                  onClick={() => navigate("/maintenance")}
                  className="engr-section3-project-card-button"
                >
                  + Learn more about this app
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
      {/*  */}

      <div className="engr-section4-container">
        <h2 className="engr-section4-title">Let’s make the magic happen</h2>
        <p>
          Ready to start something incredible? We're all ears and eager
          to hear from you! So share your ideas and collaborate with us. 
          Whether you have a project in mind or simply want to brainstorm,
          drop us a line and let's start a conversation.
        </p>
        <div className='eng-contact-container'>
          <div className='eng-contact-course1'>
            <div className='eng-contact-image'>
              <img
                src={engchat}
                width="100%"
                height="100%"
                alt='icon'
              />
            </div>
            <h3>Send a message</h3>
            <p>Contact Us</p>
        </div>
        <div className='eng-contact-course1'>
        <div className='eng-contact-image'>
          <img
              src={engcall}
              width="100%"
              height="100%"
              alt='icon'
          />
        </div>
        <h3>Place a call</h3>
        <p>+2349162627333</p>
        </div>
        <div className='eng-contact-course1'>
        <div className='eng-contact-image'>
          <img
              src={engmail}
              width="100%"
              height="100%"
              alt='icon'
          />
        </div>
        <h3>Send an email</h3>
        <p>Info@deebug.org</p>
        </div>
        </div>

      </div>
    </div>
  );
}

const EngineeringApps = ({ title, description }) => {
  return (
    <div className="engr-section3-project-card">
      <div
        className={
          title == "Heurekka"
            ? "engr-section3-project-card-heurekka"
            : title == "Medplan"
            ? "engr-section3-project-card-medplan"
            : "engr-section3-project-card-naijapharms"
        }
      >
        {title == "Medplan" ? (
          <video autoPlay loop muted className="video-style-custom">
            <source src={Medplan} type="video/mp4" />
          </video>
        ) : title == "Heurekka" ? (
          <video autoPlay loop muted className="video-style-custom">
            <source src={Heurekka} type="video/mp4" />
          </video>
        ) : (
          <video autoPlay loop muted className="video-style-custom">
            <source src={NaijaPharms} type="video/mp4" />
          </video>
        )}
      </div>

      <div className="engr-section3-project-card-details-container">
        <div className="engr-section3-project-card-details-title">{title}</div>
        <div className="engr-section3-project-card-details-description-container">
          <div>{description}</div>
          <span>
            <button className="engr-section3-project-card-button">
              + Learn more about this app
            </button>
          </span>
        </div>
      </div>
    </div>
  );
};
