import React from 'react';
import "./award.css";
import awardbground from "../../images/awardbground.webp";
import awardbground2 from "../../images/awardbground2.webp";
import awardbground3 from "../../images/awardbgroundprt2.webp";
import awardbgroundprt from "../../images/awardbgroundprt.webp";

const Award_Night = ({closeModal}  ) => {
  return (
    <div className='award-modal-overlay'>
        <div className='award-modal-content'>
            <div className='award-night-main'>
                <button className="award-close-button" onClick={closeModal}>X</button>
                <div className='award-bground'>
                    <img
                        src={awardbground}
                        width="100%"
                        height="100%"
                        alt='icon'
                    />            
                </div>
                <div className='award-bground1'>
                    <img
                        src={awardbgroundprt}
                        width="100%"
                        height="100%"
                        alt='icon'
                    />            
                </div>
                <div className='award-content1'>
                    <h1>Dinner & Award Ceremony</h1>
                    <h3>December 24th, 2023.</h3>
                    <h2>
                        “The future belongs to those who believe in the 
                        beauty of their dreams. (Eleanor Roosevelt)”
                    </h2>
                    <p>
                        Our annual dinner and award ceremony is not just a 
                        celebration, it's a cornerstone of who we are. 
                        It's a time for us, as a company, to come together, 
                        not just to celebrate the wins and the milestones 
                        achieved, but to  also acknowledge the missteps, the 
                        challenges overcome, and the areas where we fell short 
                        and truly examine the landscape of the past year. These 
                        successes are a testament to the collective effort, the 
                        ingenuity, and the unwavering dedication of every single 
                        member of the Deebug team.
                    </p>
                    <p>
                        But here's the thing, we don't dwell on these failures, 
                        but we use them as stepping stones. They become lessons 
                        learned, opportunities to refine our approach, and fuel 
                        for future innovation. By acknowledging these moments, we 
                        demonstrate not only our capacity for success, but our 
                        commitment to continuous improvement.
                    </p>
                </div>
                <div className='award-bground2'>
                    <img
                        src={awardbground2}
                        width="100%"
                        height="100%"
                        alt='icon'
                    />  
                </div>
                <div className='award-bground3'>
                    <img
                        src={awardbground3}
                        width="100%"
                        height="100%"
                        alt='icon'
                    />  
                </div>
                <div className='award-content1'>
                    <p>
                        More than just a celebration of our achievements, we also 
                        honor the very fabric of our team. This event is a chance 
                        to unwind, share laughter, and create lasting memories that 
                        solidify the spirit of collaboration and camaraderie we've 
                        fostered.
                    </p>
                    <p>
                        Also in celebrating, we acknowledge the outstanding individuals 
                        of the team who went above and beyond. Their exceptional 
                        contributions is acknowledged and celebrated at our 
                        annual awards ceremony.
                    </p>
                </div>
                <hr className='game-tourny-line' />
                <p className='game-tourny'> <a href='/events' className='game-tourny-sm'>See more of deebug events</a></p>
            </div>
        </div>
    </div>
  )
}

export default Award_Night