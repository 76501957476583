import React from "react";
import "./Hackathon.css";
import hcnthm from "../../images/hcnthm.webp"
import ChevronRight from "../../images/chevron_right_blue.png";
import { useNavigate, Link } from "react-router-dom";
import Hack1 from "../../images/hack1.webp";
import Hack2 from "../../images/hack2.webp";
import Hack3 from "../../images/hack3.webp";
import Hack4 from "../../images/hack4.webp";

export default function Hackathon() {
  const navigate = useNavigate();

  return (
    <>
      <p className="hack-title-style">Hackathon</p>
      <div className="hack-section1-bg">
        <div>
          <p className="hack-section1-main-title">
            A Battle for Brilliance.
            <br />
            Where Ideas Ignite.
          </p>
          <p className="hack-section-1-subtitle">
            An exhilarating competition, where creative flair meets innovation.
          </p>
        </div>
      </div>
      <div className="hackathon-testimonials">
        <div className="hackathon-testimonial-ctn">
          <h1>Learn new skills.</h1>
          <div className="hackathon-testimonial-box">
            <div className="hackathon-test-image">
              <img
                src={Hack1}
                width="100%"
                height="100%"
                alt="video"
                />
            </div>
            <div className="hackathon-testimonial-content">
              <p>
                It is not merely a competition, it is an unparalleled 
                opportunity that provides participants with the chance 
                to learn, grow, and transform their ideas into impactful solutions.
              </p>
            </div>
          </div>
          <h2>It wasn't just a competition, it was a journey of discovery. As a designer, I not only conquered the challenges but also learned innovative ways to enhance my UI/UX skills. It's more than an event, it's an opportunity to level up in the ever-evolving world of design</h2>
          <p>Samuel Orhuamen</p>
        </div>
        <div className="hackathon-testimonial-ctn2">
          <h1>Meet new people.</h1>
          <div className="hackathon-testimonial-box1">
            <div className="hackathon-test-image1">
              <img
                src={Hack2}
                width="100%"
                height="100%"
                alt="video"
                />
            </div>
            <div className="hackathon-testimonial-content">
              <p>
                An opportunity to connect with a diverse network of talented, 
                like-minded individuals, to share ideas, and to forge new 
                friendships that will fuel your future endeavors.
              </p>
            </div>
          </div>
          <h2>I never thought I'd find such a supportive network at a the hackathon. 
            The competition brought together brilliant minds, and we ended up forming 
            a fantastic team during the competition. It was amazing because I got to 
            learn so much from my teammates.
          </h2>
          <p>Kayode Obiora</p>
        </div>
        <div className="hackathon-testimonial-ctn3">
          <h1>Have fun.</h1>
          <div className="hackathon-testimonial-box">
            <div className="hackathon-test-image">
              <img
                src={Hack3}
                width="100%"
                height="100%"
                alt="video"
                />
            </div>
            <div className="hackathon-testimonial-content">
              <p>
                This event is not just about coding or designing. It's an 
                opportunity to unlock your creativity and problem-solving 
                skills. Immerse yourself in a vibrant and stimulating environment 
                filled with collaboration, innovation, creativity, and excitement.
              </p>
            </div>
          </div>
          <h2>The hackathon was truly an amazing experience. It was so much fun to work in a team, collaborating and sharing ideas with each other. The experience was incredible, and seeing everyone collaborate and compete made it fun and an unforgettable experience.</h2>
          <p>Hope Afolayan</p>
        </div>
        <div className="hackathon-testimonial-ctn4">
          <h1>Win amazing prizes</h1>
          <div className="hackathon-testimonial-box">
            <div className="hackathon-test-image">
              <img
                src={Hack4}
                width="100%"
                height="100%"
                alt="video"
                />
            </div>
            <div className="hackathon-testimonial-content">
              <p>
                Compete for a chance to win incredible prizes that recognize your skills 
                offering you the chance to gain recognition and valuable prizes that can 
                propel your career forward.
              </p>
            </div>
          </div>
          <h2>When I came to the hackathon, I didn't expect to walk away a winner.  The support from the amazing instructors truly made this possible. The feeling of seeing our hard work pay off and winning an amazing prize was truly unforgettable.</h2>
          <p>Abija Joyce</p>
        </div>
      </div>

      <div className="hackathon-vid-ctn">
        <h1>An insight into our students experience</h1>
        <div className="hackathon-vid">
          <img
            src={hcnthm}
            width="100%"
            height="100%"
            alt="video"
            />
        </div> 
        <p>Hear directly from some of our students as they share their insights on the experience, offering candid perspectives on the challenges they faced, and the invaluable lessons they learned along the way.</p>
      </div>

      <div className="hack-section1-bg">
        <div>
          <p className="hack-section3-more">
            Learn more about education at Deebug
          </p>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingBottom: "20px",
            }}
          >
            <a className="hackathon_more" href="/IndustrialTraining">
              Go to Industrial Training overview
            </a>
            <img src={ChevronRight} className="hackathon_more_icon" />
          </div>

          <div style={{ display: "flex", justifyContent: "center" }}>
            <a className="hackathon_more" href="/RegularStudents">
              Go to regular students
            </a>
            <img src={ChevronRight} className="hackathon_more_icon" />
          </div>
        </div>
      </div>
    </>
  );
}
