import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "../components/Footer/Footer";
import NavBar from "../components/NavBar/NavBar";
import Navigationbar from "../components/NavBar/Navigationbar";

export default function Home(){
    return (
        <div>
            <NavBar/>
            {/* <Navigationbar /> */}
            <Outlet/>
            <Footer/>
        </div>
    );
}