import React from "react";
import "./gaming.css";
import soldiers from "../../images/soldier.webp";
import esport from "../../images/esp1.webp";
import arrow from "../../images/chevron_right_blue.png";
import next from "../../images/next.png";
import letter from "../../images/gamingletter.png";
import call from "../../images/gamingcall.png";
import chat from "../../images/gamingchat.png";

const Gaming = () => {
  return (
    <div className="gaming-main-ctn">
      <div className="gaming-bground">
        <h1>Ghost Fury: Face the Unknown</h1>
        <p>Coming soon.</p>
        <button>Watch Trailer</button>
      </div>
      <div className="gaming-content">
        <p>
          Gaming is more than just entertainment, it's a culture fueled by
          passion and creativity. At Deebug, that passion drives us to create
          immersive and engaging games that captivate players and leave a
          lasting impression. We focus on creating experiences that are not only
          visually stunning but also deeply engaging and fun to play.
        </p>
      </div>
      <div className="gaming-will">
        <div className="gaming-will-img1">
          <img src={esport} width="90%" height="80%" alt="Esport" />
        </div>
        <div className="gaming-will-content">
          <h1>Test Your Will</h1>
          <p>
            Unleash your competitive spirit. Gear up for intense matches,
            nail-biting finishes, and the chance to earn bragging rights.
          </p>
          <button>
            {" "}
            <a href="/GameTournament">Learn more</a>
          </button>
        </div>
        <div className="gaming-will-img2">
          <img src={soldiers} width="80%" height="80%" alt="millitary" />
        </div>
      </div>
      <div className="gaming-learn">
        <p>Learning at Deebug</p>
        <h1>Gaming in Education</h1>
        <h2>
          As a company passionate about games, we believe they are powerful
          tools that can transform education by enhancing learning experiences
        </h2>
        <a href="/gaming">
          Learn More
          <img src={arrow} width="22px" height="22px" alt="arrow" />
        </a>
      </div>
      <div className="gaming-bonds">
        <p>
          Using games, we forge bonds, build team collaboration, and enhance the
          overall learning experience for our students.
        </p>
        <a href="/education">
          Learn more about education at deebug
          <img src={next} width="15px" height="15px" alt="arrow" />
        </a>
      </div>
      <div className="gaming-dream">
        <h1>
          Let's build your <span>dream game</span> together
        </h1>
        <p>
          Are you ready to bring your game ideas to life. We are dedicated to
          transforming your vision into a captivating gaming experience
        </p>
        <div className="gaming-contact">
          <div className="gaming-contact-props">
            <img src={chat} width="40px" height="40px" alt="chat" />
            <h1>Send a message</h1>
            <p>Contact us</p>
          </div>
          <div className="gaming-contact-props">
            <img src={call} width="40px" height="40px" alt="call" />
            <h1>Place a call</h1>
            <p>+2349162627333</p>
          </div>
          <div className="gaming-contact-props">
            <img src={letter} width="40px" height="40px" alt="letter" />
            <h1>Send an email</h1>
            <p>Info@deebug.org</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gaming;
