import React, { useState } from 'react'
import "./ecourses.css";
import badge from "../../images/badgeeeee.png";
import flutter from "../../images/fluttersingle.webp";
import flutterprt from "../../images/fluttersingleprt.png";
import history from "../../images/history.png";
import cert from "../../images/smallcert.png";
import check from "../../images/check.png";
import backend from "../../images/backendprev.webp"
import user from "../../images/courseuser.png";
import yellow from "../../images/star.png";
import white from "../../images/ratewhite.png";
import mern from "../../images/mernprev.webp";


const E_Flutter = () => {

    const [isYellow3, setisYellow3] = useState(true);

    const handleClick3 = () => {
        setisYellow3(prevState => !prevState);
    };


    const [isYellow5, setisYellow5] = useState(true);

    const handleClick5 = () => {
        setisYellow5(prevState => !prevState);
    };


    const [isMobile1] = useState(window.innerWidth < 768);



    return (
        <div className='e-course-main'>
            <div className='courses-bground'>
                <div className='courses-bground-text'>
                    <img
                        src={badge}
                        width="auto"
                        height='70px'
                        alt='icon'
                    />
                    <h1>Deebug Institute Certified: Flutter Application Development</h1>
                    <h2>Become a proficient Flutter Mobile App Developer with our  complete comprehensive  course.</h2>
                    <button><a href='https://elearning.deebug.org'>Apply now</a></button>

                </div>
                <div className='courses-bground-img'>
                    <img
                        src={isMobile1 ? flutterprt : flutter}
                        width="100%"
                        height='100%'
                        alt='icon'
                    />
                </div>
            </div>
            <div className='course-details'>
                <h1>Course details</h1>
                <div className='course-duration'>
                    <div className='course-duration-child'>
                        <div className='course-duration-props'>
                            <div className='course-duration-content'>
                                <img
                                    src={history}
                                    width="auto"
                                    height="auto"
                                    alt='icon'
                                />
                                <h1>Level</h1>
                            </div>
                            <div className='course-duration-props-child'>
                                <p>Beginner</p>
                            </div>
                        </div>
                        <div className='course-duration-props'>
                            <div className='course-duration-content'>
                                <img
                                    src={history}
                                    width="auto"
                                    height="auto"
                                    alt='icon'
                                />
                                <h1>Price</h1>
                            </div>
                            <div className='course-duration-props-child'>
                                <p> ₦80,000</p>
                            </div>
                        </div>
                    </div>
                    <div className='course-duration-child'>
                        <div className='course-duration-props'>
                            <div className='course-duration-content'>
                                <img
                                    src={history}
                                    width="auto"
                                    height="auto"
                                    alt='icon'
                                />
                                <h1>Duration</h1>
                            </div>
                            <div className='course-duration-props-child'>
                                <p>3 months @ 6 hrs per week</p>
                            </div>
                        </div>
                        <div className='course-duration-props'>
                            <div className='course-duration-content'>
                                <img
                                    src={history}
                                    width="auto"
                                    height="auto"
                                    alt='icon'
                                />
                                <h1>Prerequisites</h1>
                            </div>
                            <div className='course-duration-props-child'>
                                <p>No prior experience required</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='course-overview'>
                <h1>Course overview</h1>
                <p>
                    The Flutter Mobile Application Developer Course is designed to equip
                    learners with the skills and knowledge necessary to become proficient
                    Flutter developers. This comprehensive program covers everything from
                    the basics of Flutter to advanced topics in app development. By the
                    end of the course, learners will be able to develop cross-platform mobile
                    applications using Flutter, a popular UI toolkit from Google.
                </p>
            </div>
            <div className="course-content">
                <h1>Course content</h1>
                <p>10 Modules</p>
                <div className='course-week'>
                    <div className='course-header'>
                        <span>Introduction to Flutter Development</span>
                        <span>Building User Interfaces with Flutter</span>
                        <span>State Management in Flutter</span>
                        <span>Navigation and Routing in Flutter</span>
                        <span>Networking and Data Management</span>
                        <span>Firebase Integration</span>
                        <span>Push Notifications and Cloud Messaging</span>
                        <span>Handling Device Features and Sensors</span>
                        <span>Testing and Debugging Techniques</span>
                        <span>Internationalization and Localization</span>
                        {/* <span>Introduction to Data Structures and Algorithms</span>
                    <span>Agile Development and DevOps Practices</span>
                    <span>Automation Testing</span>
                    <span>Bash (Unix Shell)</span>
                    <span>Security Considerations</span>
                    <span>Deployment and Continuous Integration</span>
                    <span>Version Control and Collaboration</span>
                    <span>Advanced Topics and Integration</span> */}
                    </div>
                </div>
            </div>
            <div className='course-certificate'>
                <div className='course-skill'>
                    <h1>Learning outcome</h1>
                    <p>By successfully completing this comprehensive program, you will be well-equipped to:</p>
                    <div className='course-skill-content'>
                        <img
                            src={check}
                            width="auto"
                            height="auto"
                            alt='check'
                        />
                        <p>Create a simple app with UI elements (using Widgets), user interaction (Buttons, Text Fields), and state management </p>
                    </div>
                    <div className='course-skill-content'>
                        <img
                            src={check}
                            width="auto"
                            height="auto"
                            alt='check'
                        />
                        <p>Make an HTTP request using Flutter to retrieve data from an API and display it within your app</p>
                    </div>
                    <div className='course-skill-content'>
                        <img
                            src={check}
                            width="auto"
                            height="auto"
                            alt='check'
                        />
                        <p>Set up Firebase and implement a feature like user authentication or real-time data synchronization </p>
                    </div>
                    <div className='course-skill-content'>
                        <img
                            src={check}
                            width="auto"
                            height="auto"
                            alt='check'
                        />
                        <p>Create a unit test to ensure a specific Flutter widget behaves as expected </p>
                    </div>
                    <div className='course-skill-content'>
                        <img
                            src={check}
                            width="auto"
                            height="auto"
                            alt='check'
                        />
                        <p>Prepare and deploy a Flutter app to an app store (Android or iOS) using tools like Git and CI/CD</p>
                    </div>
                </div>
                <div className='course-certificate-img'>
                    <img
                        src={cert}
                        width="auto"
                        height="auto"
                        alt='icon'
                    />
                    <button><a href='https://elearning.deebug.org'>Apply now</a></button>
                </div>
            </div>
            <div className='course-related'>
                <h1>Related Courses</h1>
                <div className='e-courses-container'>
                    <div className='explore-courses-ctn'>
                        <div className='explore-courses-ctn-img'>
                            <img
                                src={backend}
                                width="100%"
                                height="100%"
                                alt='logo'
                            />
                        </div>
                        <div className='explore-courses-ctn-text'>
                            <h2> <a href='/backend-development'>Backend Development</a></h2>
                            <p>Learn to create server-side logic, databases, and APIs that make applications function efficiently behind the scenes.</p>
                        </div>
                        <div className='explore-course-about'>
                            <p>₦ 75,000</p>
                            <div className='explore-course-review'>
                                <img
                                    src={user}
                                    width="22px"
                                    height="22px"
                                    alt='icon'
                                />
                                <p>210+</p>
                                <div className='wishlist-container' onClick={handleClick3}>
                                    <img
                                        src={isYellow3 ? white : yellow}
                                        alt={isYellow3 ? 'rating' : 'rating'}
                                        className={`rate-image ${isYellow3 ? 'rate-fade-in' : 'rate-fade-out'}`}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='explore-courses-ctn'>
                        <div className='explore-courses-ctn-img'>
                            <img
                                src={mern}
                                width="100%"
                                height="100%"
                                alt='logo'
                            />
                        </div>
                        <div className='explore-courses-ctn-text'>
                            <h2><a href='/fullstack-web-development'>Fullstack Web Development</a></h2>
                            <p>Learn MongoDB, Express.js, React, & Node.js, to create dynamic & scalable solutions.</p>
                        </div>
                        <div className='explore-course-about'>
                            <p>₦ 150,000</p>
                            <div className='explore-course-review'>
                                <img
                                    src={user}
                                    width="22px"
                                    height="22px"
                                    alt='icon'
                                />
                                <p>65+</p>
                                <div className='wishlist-container' onClick={handleClick5}>
                                    <img
                                        src={isYellow5 ? white : yellow}
                                        alt={isYellow5 ? 'rating' : 'rating'}
                                        className={`rate-image ${isYellow5 ? 'rate-fade-in' : 'rate-fade-out'}`}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default E_Flutter