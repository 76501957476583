import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import deebug from "../images/landeduhome.jpg";
import deebugprt from "../images/landeduhomeprt.webp";
import react from "../images/reactdrk.png";
import node from "../images/nodejsdrk.png";
import git from "../images/github.png";
import mongo from "../images/mongodb.png";
import figma from "../images/Figmadrk.png";
import embed from "../images/embeddrk.png";
import frontend from "../images/frontendprev.webp"
import backend from "../images/backendprev.webp"
import fullapp from "../images/fullapprev.webp";
import fullprod from "../images/fullprodprev.webp";
import mern from "../images/mernprev.webp";
import data from "../images/dataprev.webp";
import flutter from "../images/flutterprev.webp";
import user from "../images/courseuser.png";
import figview from "../images/figmapreview.webp";
import yellow from "../images/star.png";
import white from "../images/ratewhite.png";
import P2 from "../images/hack1.webp";
import P3 from "../images/p3.png";
import P5 from "../images/p5.webp";
import GDay from "../images/GDay.webp";
import Hackathon from "../images/HackDay.webp";
import CDay from "../images/CDay.webp";
import innday from "../images/innovatedu.webp";
import standardcoursebground from "../images/standardcoursebground.webp";
import portrait from "../images/standardcoursebgroundprt.webp";
import why1 from "../images/why1.webp";
import why2 from "../images/why2.webp";
import why3 from "../images/why3.webp";
import ReadMore from "../images/read_more.png";
import SamStory from "../components/EducationComps/SamStory";
import ChizzyStory from "../components/EducationComps/ChizzyStory";
import PurposeStory from "../components/EducationComps/PurposeStory";
import prev1 from "../images/purposeprev.webp";
import prev2 from "../images/sammyprev.webp";
import prev3 from "../images/chizzyprev.webp";





const LandingHome = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpen1, setIsModalOpen1] = useState(false);
    const [isModalOpen2, setIsModalOpen2] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const openModal1 = () => {
        setIsModalOpen1(true);
    };

    const closeModal1 = () => {
        setIsModalOpen1(false);
    };

    const openModal2 = () => {
        setIsModalOpen2(true);
    };

    const closeModal2 = () => {
        setIsModalOpen2(false);
    };



    const slides = ['Accessible', 'Accessible'];
    const [currentSlide, setCurrentSlide] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
        }, 3000);
        return () => clearInterval(interval);
    }, [slides.length]);


    const [isYellow, setisYellow] = useState(true);

    const handleClick = () => {
        setisYellow(prevState => !prevState);
        let value = localStorage.getItem('from_flutter');
        console.log(value);

    };

    const [isYellow2, setisYellow2] = useState(true);

    const handleClick2 = () => {
        let value = localStorage.getItem('is_logged_in');
        localStorage.setItem('is_logged_in', true);
        console.log("clicked");
        setisYellow2(prevState => !prevState);
        value = localStorage.getItem('is_logged_in');
        console.log(value);
    };


    const [isYellow3, setisYellow3] = useState(true);

    const handleClick3 = () => {
        setisYellow3(prevState => !prevState);
    };

    const [isYellow4, setisYellow4] = useState(true);

    const handleClick4 = () => {
        setisYellow4(prevState => !prevState);
    };

    const [isYellow5, setisYellow5] = useState(true);

    const handleClick5 = () => {
        setisYellow5(prevState => !prevState);
    };

    const [isYellow6, setisYellow6] = useState(true);

    const handleClick6 = () => {
        setisYellow6(prevState => !prevState);
    };

    const [isYellow7, setisYellow7] = useState(true);

    const handleClick7 = () => {
        setisYellow7(prevState => !prevState);
    };

    const [isYellow8, setisYellow8] = useState(true);

    const handleClick8 = () => {
        setisYellow8(prevState => !prevState);
    };

    const [isMobile1] = useState(window.innerWidth < 768);



    return (
        <div className='landhome-course-main'>
            <div className='explore-top'>
                <div className='explore-top-text'>
                    <h1>Launch Your </h1>
                    <h1>Career in  <span>TECH</span></h1>
                    <p>
                        Build new skills, Invest in your future, Become Tech Relevant. Explore our
                        diverse courses and master in-demand skills with our expert-led training.
                    </p>
                    <div className="education-page-btn2">
                        <Link to='https://elearning.deebug.org' className='standard-course-btn'>Apply Now</Link>
                        {/* <Link to='https://john-mote-s-school.teachable.com/?preview=logged_out' className='standard-course-btn2'>Explore courses</Link> */}
                    </div>
                    {/* <div className="explore-search-container">
                    <input
                        type="text"
                        className="explore-search-input"
                        placeholder="What do you want to learn?"
                    />
                    <img
                        src={search}
                        width="44px"
                        height="44px"
                        alt='icon'
                    />
                </div> */}
                </div>
                <div className='explore-top-img'>
                    <img
                        src={isMobile1 ? deebugprt : deebug}
                        width="100%"
                        height="100%"
                        alt='Deebug'
                    />
                </div>
            </div>
            <div className='explore-course-learn'>
                <h1>Learn Everything You Need</h1>
                <p>In-demand courses, Guided Projects, Specializations, and Professional Certificates</p>
                <div className='exp-cse-lrn-img'>
                    <img
                        src={react}
                        width="55px"
                        height="55px"
                        alt='logo'
                    />
                    <img
                        src={node}
                        width="55px"
                        height="55px"
                        alt='logo'
                    />
                    <img
                        src={git}
                        width="55px"
                        height="55px"
                        alt='logo'
                    />
                    <img
                        src={mongo}
                        width="55px"
                        height="55px"
                        alt='logo'
                    />
                    <img
                        src={figma}
                        width="55px"
                        height="55px"
                        alt='logo'
                    />
                    <img
                        src={embed}
                        width="55px"
                        height="55px"
                        alt='logo'
                    />
                </div>
            </div>
            <div className='landinghome-courses'>
                <div className='landing-course-content'>
                    <div className='explore-sec-cse1'>
                        <div className='explore-sec-cse1-row'>
                            <h1>Master In demand Skills</h1>
                            <Link to="https://elearning.deebug.org" className='masterskills-link'>Apply Now</Link>
                        </div>
                        <p>
                            Our courses offer concise, expert-led trainings designed to help you master new
                            skills in record time and advance your career.
                        </p>
                        <div className='explore-courses-ctn-props'>
                            <div className='explore-courses-ctn'>
                                <div className='explore-courses-ctn-img'>
                                    <img
                                        src={frontend}
                                        width="100%"
                                        height="100%"
                                        alt='logo'
                                    />
                                </div>
                                <div className='explore-courses-ctn-text'>
                                    <h2> <a href='frontend-web-development'>Frontend Web Development</a></h2>
                                    <p>Learn how to craft interactive, visually engaging websites using HTML, CSS, and ReactJS.</p>
                                </div>
                                <div className='explore-course-about'>
                                    <p>₦ 75,000</p>
                                    <div className='explore-course-review'>
                                        <img
                                            src={user}
                                            width="22px"
                                            height="22px"
                                            alt='icon'
                                        />
                                        <p>210+</p>
                                        <div className='wishlist-container' onClick={handleClick}>
                                            <img
                                                src={isYellow ? white : yellow}
                                                alt={isYellow ? 'rating' : 'rating'}
                                                className={`rate-image ${isYellow ? 'rate-fade-in' : 'rate-fade-out'}`}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='explore-courses-ctn'>
                                <div className='explore-courses-ctn-img'>
                                    <img
                                        src={figview}
                                        width="100%"
                                        height="100%"
                                        alt='logo'
                                    />
                                </div>
                                <div className='explore-courses-ctn-text'>
                                    <h2><a href='/ux-ui-design'> UX/UI Design</a></h2>
                                    <p>Learn to create intuitive and user-friendly interfaces for exceptional user experiences.</p>
                                </div>
                                <div className='explore-course-about'>
                                    <p>₦ 65,000</p>
                                    <div className='explore-course-review'>
                                        <img
                                            src={user}
                                            width="22px"
                                            height="22px"
                                            alt='icon'
                                        />
                                        <p>124+</p>
                                        <div className='wishlist-container' onClick={handleClick2}>
                                            <img
                                                src={isYellow2 ? white : yellow}
                                                alt={isYellow2 ? 'rating' : 'rating'}
                                                className={`rate-image ${isYellow2 ? 'rate-fade-in' : 'rate-fade-out'}`}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='explore-courses-ctn'>
                                <div className='explore-courses-ctn-img'>
                                    <img
                                        src={backend}
                                        width="100%"
                                        height="100%"
                                        alt='logo'
                                    />
                                </div>
                                <div className='explore-courses-ctn-text'>
                                    <h2> <a href='/backend-development'>Backend Development</a></h2>
                                    <p>Learn to create server-side logic, databases, and APIs that make applications function efficiently behind the scenes.</p>
                                </div>
                                <div className='explore-course-about'>
                                    <p>₦ 75,000</p>
                                    <div className='explore-course-review'>
                                        <img
                                            src={user}
                                            width="22px"
                                            height="22px"
                                            alt='icon'
                                        />
                                        <p>210+</p>
                                        <div className='wishlist-container' onClick={handleClick3}>
                                            <img
                                                src={isYellow3 ? white : yellow}
                                                alt={isYellow3 ? 'rating' : 'rating'}
                                                className={`rate-image ${isYellow3 ? 'rate-fade-in' : 'rate-fade-out'}`}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='explore-courses-ctn'>
                                <div className='explore-courses-ctn-img'>
                                    <img
                                        src={flutter}
                                        width="100%"
                                        height="100%"
                                        alt='logo'
                                    />
                                </div>
                                <div className='explore-courses-ctn-text'>
                                    <h2><a href='/flutter-app-development'> Mobile App Development</a></h2>
                                    <p>Flutter empowers developers to create high-performance, visually stunning apps for both iOS and Android from a single codebase</p>
                                </div>
                                <div className='explore-course-about'>
                                    <p>₦ 80,000</p>
                                    <div className='explore-course-review'>
                                        <img
                                            src={user}
                                            width="22px"
                                            height="22px"
                                            alt='icon'
                                        />
                                        <p>70+</p>
                                        <div className='wishlist-container' onClick={handleClick4}>
                                            <img
                                                src={isYellow4 ? white : yellow}
                                                alt={isYellow4 ? 'rating' : 'rating'}
                                                className={`rate-image ${isYellow4 ? 'rate-fade-in' : 'rate-fade-out'}`}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='explore-courses-ctn'>
                                <div className='explore-courses-ctn-img'>
                                    <img
                                        src={mern}
                                        width="100%"
                                        height="100%"
                                        alt='logo'
                                    />
                                </div>
                                <div className='explore-courses-ctn-text'>
                                    <h2><a href='/fullstack-web-development'>Fullstack Web Development</a></h2>
                                    <p>Learn MongoDB, Express.js, React, & Node.js, to create dynamic & scalable solutions.</p>
                                </div>
                                <div className='explore-course-about'>
                                    <p>₦ 75,000</p>
                                    <div className='explore-course-review'>
                                        <img
                                            src={user}
                                            width="22px"
                                            height="22px"
                                            alt='icon'
                                        />
                                        <p>65+</p>
                                        <div className='wishlist-container' onClick={handleClick5}>
                                            <img
                                                src={isYellow5 ? white : yellow}
                                                alt={isYellow5 ? 'rating' : 'rating'}
                                                className={`rate-image ${isYellow5 ? 'rate-fade-in' : 'rate-fade-out'}`}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='explore-courses-ctn'>
                                <div className='explore-courses-ctn-img'>
                                    <img
                                        src={data}
                                        width="100%"
                                        height="100%"
                                        alt='logo'
                                    />
                                </div>
                                <div className='explore-courses-ctn-text'>
                                    <h2>Data Science/ Analytics</h2>
                                    <p>Learn to transform raw data into actionable insights, helping businesses make informed decisions and uncover trends.</p>
                                </div>
                                <div className='explore-course-about'>
                                    <p>₦ 75,000</p>
                                    <div className='explore-course-review'>
                                        <img
                                            src={user}
                                            width="22px"
                                            height="22px"
                                            alt='icon'
                                        />
                                        <p>70+</p>
                                        <div className='wishlist-container' onClick={handleClick6}>
                                            <img
                                                src={isYellow6 ? white : yellow}
                                                alt={isYellow6 ? 'rating' : 'rating'}
                                                className={`rate-image ${isYellow6 ? 'rate-fade-in' : 'rate-fade-out'}`}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='explore-courses-ctn'>
                                <div className='explore-courses-ctn-img'>
                                    <img
                                        src={fullapp}
                                        width="100%"
                                        height="100%"
                                        alt='logo'
                                    />
                                </div>
                                <div className='explore-courses-ctn-text'>
                                    <h2>Fullstack Mobile App Dev.</h2>
                                    <p>Learn key concepts in threat detection, fullprod security, and risk management, to safeguard systems against fullapp threats.</p>
                                </div>
                                <div className='explore-course-about'>
                                    <p>₦ 155,000</p>
                                    <div className='explore-course-review'>
                                        <img
                                            src={user}
                                            width="22px"
                                            height="22px"
                                            alt='icon'
                                        />
                                        <p>45+</p>
                                        <div className='wishlist-container' onClick={handleClick7}>
                                            <img
                                                src={isYellow7 ? white : yellow}
                                                alt={isYellow7 ? 'rating' : 'rating'}
                                                className={`rate-image ${isYellow7 ? 'rate-fade-in' : 'rate-fade-out'}`}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='explore-courses-ctn'>
                                <div className='explore-courses-ctn-img'>
                                    <img
                                        src={fullprod}
                                        width="100%"
                                        height="100%"
                                        alt='logo'
                                    />
                                </div>
                                <div className='explore-courses-ctn-text'>
                                    <h2>Fullstack Product Design</h2>
                                    <p>Learn to connect devices and systems, enabling seamless communication, data sharing, and collaboration across vast distances.</p>
                                </div>
                                <div className='explore-course-about'>
                                    <p>₦ 140,000</p>
                                    <div className='explore-course-review'>
                                        <img
                                            src={user}
                                            width="22px"
                                            height="22px"
                                            alt='icon'
                                        />
                                        <p>35   +</p>
                                        <div className='wishlist-container' onClick={handleClick8}>
                                            <img
                                                src={isYellow8 ? white : yellow}
                                                alt={isYellow8 ? 'rating' : 'rating'}
                                                className={`rate-image ${isYellow8 ? 'rate-fade-in' : 'rate-fade-out'}`}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="education-why">
                <h1>Why choose Deebug?</h1>
                <div className="education-why-details">
                    <div className="education-why-content">
                        <img
                            src={why1}
                            width="163px"
                            height="138px"
                            alt='icon'
                        />
                        <p>
                            FLEXIBLE TUITION PAYMENT{" "}
                            <span>
                                {" "}
                                We don’t think financial capacity should be a barrier.
                                Our flexible installmental tuition option ensures you
                                focus on your learning, not tuition.
                            </span>
                        </p>
                    </div>
                    <div className="education-why-content">
                        <img
                            src={why2}
                            width="163px"
                            height="138px"
                            alt='icon'
                        />
                        <p>
                            HYBRID LEARNING{" "}
                            <span>
                                {" "}
                                No matter the problem you face in your journey to mastery, we are always there
                                to help you to the next level with our blended online and weekly live classes.
                            </span>
                        </p>
                    </div>
                    <div className="education-why-content">
                        <img
                            src={why3}
                            width="245px"
                            height="131px"
                            alt='icon'
                        />
                        <p>
                            PROJECT BASED LEARNING{" "}
                            <span>
                                {" "}
                                Our hands-on projects empower students to apply theoretical knowledge,
                                fostering practical skills and innovative thinking for real-world success.
                            </span>
                        </p>
                    </div>
                </div>
            </div>
            <div className='education-leaders'>
                <p className="ind-tr-section4-title-gradient">Building Tomorrow's Leaders</p>
                <p className="ind-tr-section4-details">
                    We bridge the gap between classroom knowledge and real-world
                    applications by offering hands-on experience through live projects.
                    Students not only learn but thrive, gaining practical insights that
                    prepare them for success in their chosen fields.
                </p>
                <div className="edu-project-card-list">
                    <div className="edu-project-car-item-main">
                        <img src={P3} alt="build1" className="edu-project-card-item" />
                    </div>
                    <div className="edu-project-car-item-main1">
                        <img src={P5} alt="build1" className="edu-project-card-item" />
                    </div>
                    <div className="edu-project-car-item-main">
                        <img src={P2} alt="build1" className="edu-project-card-item" />
                    </div>
                </div>
            </div>
            <div className='standard-title'>
                <div className='standard-about'>
                    <h2>DEEBUG FREE BOOTCAMP</h2>
                    <h1>Making Learning</h1>
                    <h3>Accessible</h3>
                    {/* <div className="standard-slide-container">
                    {slides.map((slide, index) => (
                        <h1
                            key={index}
                            className={`standard-slide ${index === currentSlide ? 'standard-slide-active' : ''}`}
                        >
                        {slide}
                        </h1>
                    ))}
                </div> */}
                    <h1>at every level</h1>
                    <p>
                        Join us for our annual free tech bootcamp, where we
                        empower you with the latest skills to thrive in
                        the digital world!
                    </p>
                    <div className="education-page-btn2">
                        <button className='standard-course-btn1'>Applications open 25/05/2025</button>
                    </div>
                </div>
                <div className='standard-pictures-bground'>
                    <img
                        src={standardcoursebground}
                        width="100%"
                        height="100%"
                        alt='icon'
                    />
                </div>
                <img
                    src={portrait}
                    width="100%"
                    height="100%"
                    alt='icon'
                />
            </div>
            <div className="intern-week">
                <p>The Deebug Experience</p>
                <h1>
                    Captivating events designed to enhance your learning experience.{" "}
                </h1>
                <div className="intern-week-activities">
                    <div className="intern-week-ctn">
                        <div className="intern-sprint-img">
                            <img src={GDay} width="100%" height="100%" alt="event-img" />
                        </div>
                        <div className="intern-sprint-content">
                            <h1>E-Sport Tournament</h1>
                            <p>
                                See how student engage in epic game battles unleashing the
                                creativity and team spirit
                            </p>
                            {/* <div className="education-learn-btn">
                        <Link to="/gaming" className="edu-section_learn_more">
                            Learn more
                        </Link>
                        <img src={ChevronRight} className="edu-learn_more_icon" />
                        </div> */}
                        </div>
                    </div>
                    <div className="intern-week-ctn">
                        <div className="intern-sprint-img">
                            <img src={Hackathon} width="100%" height="100%" alt="event-img" />
                        </div>
                        <div className="intern-sprint-content">
                            <h1>Hackathon</h1>
                            <p>
                                Engage in coding challenges and design sprints, where students collaborate,
                                ideate, and showcase their skills in friendly competitions.
                            </p>
                            {/* <div className="education-learn-btn">
                        <Link to="/hackathon" className="edu-section_learn_more">
                            Learn more
                        </Link>
                        <img src={ChevronRight} className="edu-learn_more_icon" />
                        </div> */}
                        </div>
                    </div>
                    <div className="intern-week-ctn">
                        <div className="intern-sprint-img">
                            <img src={CDay} width="100%" height="100%" alt="event-img" />
                        </div>
                        <div className="intern-sprint-content">
                            <h1>Career Expedition</h1>
                            <p>
                                Gain valuable insights into the evolving job space, from
                                navigating applications for remote and freelance work to
                                unlocking the entrepreneurial path.
                            </p>
                            {/* <div className="education-learn-btn">
                        <Link to="/CareerExpedition" className="edu-section_learn_more">
                            Learn more
                        </Link>
                        <img src={ChevronRight} className="edu-learn_more_icon" />
                        </div> */}
                        </div>
                    </div>
                    <div className="intern-week-ctn">
                        <div className="intern-sprint-img">
                            <img src={innday} width="100%" height="100%" alt="event-img" />
                        </div>
                        <div className="intern-sprint-content">
                            <h1>Innovative Showcase</h1>
                            <p>
                                Discover groundbreaking projects and insights into students learning journeys.
                            </p>
                            {/* <div className="education-learn-btn">
                            <Link to="/InnovativeShowcase" className="edu-section_learn_more">
                                Learn more
                            </Link>
                            <img src={ChevronRight} className="edu-learn_more_icon" />
                        </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className='testimonial-main-container1'>
                <h1>Success Stories</h1>
                <div className="ind-stories-props">
                    <div className="ind-stories-ctn">
                        <div className="ind-stories-img">
                            <img
                                src={prev1}
                                width="100%"
                                height="100%"
                                alt='background'
                            />
                        </div>
                        <div className="ind-stories-text">
                            <h1>Purpose  Olikiabo</h1>
                            <p>UI/UX Designer</p>
                            <div className="ind-stories-read">
                                <img
                                    src={ReadMore}
                                    width="auto"
                                    height="auto"
                                    alt='background'
                                    onClick={openModal}
                                />
                                Read
                            </div>
                            {isModalOpen && <PurposeStory closeModal={closeModal} />}
                        </div>
                    </div>
                    <div className="ind-stories-ctn1">
                        <div className="ind-stories-img">
                            <img
                                src={prev2}
                                width="100%"
                                height="100%"
                                alt='background'
                            />
                        </div>
                        <div className="ind-stories-text">
                            <h1>Samuel Oghamomwan</h1>
                            <p>Backend Developer </p>
                            <div className="ind-stories-read">
                                <img
                                    src={ReadMore}
                                    width="auto"
                                    height="auto"
                                    alt='background'
                                    onClick={openModal1}
                                />
                                Read
                            </div>
                            {isModalOpen1 && <SamStory closeModal1={closeModal1} />}
                        </div>
                    </div>
                    <div className="ind-stories-ctn2">
                        <div className="ind-stories-img">
                            <img
                                src={prev3}
                                width="100%"
                                height="100%"
                                alt='background'
                            />
                        </div>
                        <div className="ind-stories-text">
                            <h1>Chizitere Ofurum</h1>
                            <p>Fullstack Web Developer</p>
                            <div className="ind-stories-read">
                                <img
                                    src={ReadMore}
                                    width="auto"
                                    height="auto"
                                    alt='background'
                                    onClick={openModal2}
                                />
                                Read
                            </div>
                            {isModalOpen2 && <ChizzyStory closeModal2={closeModal2} />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LandingHome