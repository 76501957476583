import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// TODO:
// heading: 68px = 3rem
// title: 30px = 2.5rem
// subtitleM: 20px = 1.4rem
// subtitle: 18px = 1.12rem


// ** Become a Master and Personalized learning should be black ✔
// ** Remove deebug.netlify.app on the contact us page ✔
// ** info@deebug.org should be created: password "De3bug!$" ✔
// ** Under play for coin and glory, add "learn more"  ✔
// ** Deebug website fixes: The board in mobile does not have red like the web... ✔
// ** The line height on most text is too much on mobile: homepage ✔
// ** Bootcamp: needs to be more on the right ✔
// ** Things appearing too big with alot of spacing on the bootcamp page ✔
// ** Work on view stories on mobile ✔
// **>> Contact us: mobile ✔
// **>> Engineering: mobile ✔


// Enginnering page still need some little adjustments on mobile: copy skillsprint events
// **>> Navbar is a focus also
// ** Jude should adjust the ghost fury character, raise the guy up and take the text up also

// ** Ethics page
// ** While the page is loading on mobile, something's don't appear well.





// MOBILE VIEW
//- engineering
//- leadershipview: use apples
//- it courses
//- contact us
//- homepage
//- ethics

// hovering on navbar doesn't show items in homepage again
// When in dark page, navbardropdown should be dark
// fix mobile overflow: nav bar causing resp. issues
// ____






// CHEATSHEET
// import { useNavigate } from "react-router-dom";
// const navigate = useNavigate();
// onClick={() => navigate("/bootcamp")}

{
  /* <a href="/education" style={{ color: linkColor }}> */
}

{
  /* <img src={Game} className="game-img" alt="game-img" /> */
}

// import { useEffect, useState } from "react";
// import { useWindowWidth, useWindowHeight } from "@react-hook/window-size";
// const [mobile, setMobile] = useState(false);
// const width = useWindowWidth();

// useEffect(() => {
//   if (width <= 767) {
//     setMobile(true);
//   } else {
//     setMobile(false);
//   }
// }, [width]);

// return (
//     <>
//       {mobile ? (
//         <div className="grid-container">
//           <div className="grid-item">Column 1</div>
//           <div className="grid-item">Column 2</div>
//         </div>
//       ) : null}
//     </>
//   );

/* Media query for screens smaller than 768px (Phones)*/
// @media(max-width: 767px){}
/* Media query for screens between 768px and 1023px (Tablets)*/
// @media(min-width: 768px) and (max-width: 1023px){
// }
/* Media query for screens larger than 1024px */
// @media (min-width: 1024px){

// }
