import React from 'react';
import "./Engineering.css";
import EngineeringServiceCard from '../components/EngineeringComps/EngineeringServiceCard';
import S1 from "../images/s1.png";
import S2 from "../images/s2.png";
import S3 from "../images/s3.png";
import S4 from "../images/s4.png";
import S5 from "../images/s5.png";
import S6 from "../images/s6.png";



const Engineering_Services = () => {
  return (
    <div>
      <div className="engr-section2-container">
        <div className="engr-section2-title-container">
          <p>Our Services</p>
          <p>What we know how to do best</p>
        </div>
        <EngineeringServiceCard
          title={"Website Development"}
          subtitle={
            "We understand what scalability is and how to harness the principles and standards from design right through implementation of your website."
          }
          image={S1}
        />
        <EngineeringServiceCard
          title={"Mobile App Development"}
          subtitle={
            "Our team of highly capable developers have a breadth of experience developing apps for many industries and can build any type of functionality for your app."
          }
          image={S2}
        />
        <EngineeringServiceCard
          title={"UI/UX Design"}
          subtitle={
            "Design is not just what it looks like and feels like. Design is how it works. We pride ourselves in designing apps that are both visually stunning and highly intuitive."
          }
          image={S3}
        />
        <EngineeringServiceCard
          title={"Game Development"}
          subtitle={
            "However big or small your need, we'll build you a beautiful and enjoyable mobile or video game that is tailored specifically to your business needs."
          }
          image={S4}
        />
        <EngineeringServiceCard
          title={"Video Editing & Production"}
          subtitle={
            "Don't spend an arm and a leg on video editing software. We take pride in producing high-quality videos at affordable rates. Our experienced editors handle your projects with a personal touch."
          }
          image={S5}
        />
        <EngineeringServiceCard
          title={"3D Modeling & Animation"}
          subtitle={
            "Get your ideas created in few days. We create custom 3D Models and Animations in rapid time."
          }
          image={S6}
        />
        
      </div>
    </div>
  )
}

export default Engineering_Services