import { useNavigate } from "react-router-dom";
import ChevronRight from "../../images/chevron_right_blue.png";
import Gday from "../../images/sse1.png";
import Hackathon from "../../images/sse2.png";
import CDay from "../../images/sse3.png";
import { useEffect, useState } from "react";
import { useWindowWidth, useWindowHeight } from "@react-hook/window-size";
import "./EngineeringServiceCard.css";

export default function EngineeringServiceCard({ title, subtitle, image }) {
  const navigate = useNavigate();
  const [mobile, setMobile] = useState(false);
  const width = useWindowWidth();

  useEffect(() => {
    if (width <= 767) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, [width]);

  const section_title_style = {
    fontSize: "2.5rem",
    fontWeight: "300",
    fontColor: "#444",
    lineHeight: "150%",
    letterSpacing: "-0.76px",
    margin: "20px 20px 10px 20px",
  };
  const mobile_section_title_style = {
    fontSize: "2rem",
    fontWeight: "500",
    fontColor: "#444",
    lineHeight: "150%",
    letterSpacing: "-0.76px",
    margin: "0 1px",
  };
  const section_subtitle_style = {
    fontSize: "1.3rem",
    fontColor: "#444",
    lineHeight: "150%",
    letterSpacing: "-0.342px",
    margin: "0",
    margin: "0 20px 0 20px",
  };
  const mobile_section_subtitle_style = {
    fontSize: "20px",
    fontColor: "#444",
    lineHeight: "150%",
    letterSpacing: "-0.342px",
    margin: "0",
    marginBottom: "30px",
  };
  return (
    <div className="engr-service-container">
      {" "}
      <div className="engr-service-image">
        <img src={image} alt="img" />
      </div>
      {/* <div> */}
      <div className="engr-service-content">
        <header
          style={mobile ? mobile_section_title_style : section_title_style}
        >
          {title}
        </header>
        <p
          style={
            mobile ? mobile_section_subtitle_style : section_subtitle_style
          }
        >
          {subtitle}
        </p>
      </div>
    </div>
  );
}
