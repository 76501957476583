const formatAmount = (amount) => {
    // Convert the amount to a string
    let amountStr = amount.toString();

    // Use a regular expression to add commas every three digits
    let formattedAmount = amountStr.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // Prepend the Naira sign
    return "₦" + formattedAmount;
}

module.exports = {formatAmount}
