import React from 'react'
import './popup.css';
import apply from "../../images/applycourseicon.png";
import { useLocation } from 'react-router-dom';

const PopUp = ({ title, content, onClose, onProceed }) => {      
    
  return (
    <div className="popup">
        <div className="popup-inner">
            <div className='popup-apply'>
                <img
                    src={apply}
                    width="100%"
                    height="100%"
                    alt='icon'
                />
            </div>
            <div className="popup-header">
                <h2>{title}</h2>
            </div>
            <div className="popup-content">
                <p>{content}</p>
            </div>
            <div className="popup-footer">
                <button className='popup-close-btn' onClick={onClose}>Back</button>
                <button className='popup-proceed-btn' onClick={onProceed}>Proceed</button>
            </div>
        </div>
    </div>
  )
}

export default PopUp