import React from 'react';
import "./indstories.css";
import chizzy from "../../images/chizzytop.webp"
import chizzy1 from "../../images/chizzytopprt.webp"
import chizzybtm from "../../images/chizzybtm.webp"
import chizzybtm1 from "../../images/chizzybtmprt.webp";

const ChizzyStory = ({closeModal2}) => {
  return (
    <div className='story-modal-overlay'>
        <div className="story-modal-content">
            <button className="story-close-button" onClick={closeModal2}>X</button>
            <div className="story-content-main">
                <div className='story-bground'>
                    <img
                        src={chizzy}
                        width="100%"
                        height="100%"
                        alt='background'
                    />
                </div>
                <img
                    src={chizzy1}
                    width="100%"
                    height="100%"
                    alt='background'
                />
                <div className='story-content-text'>
                    <h5 className='chizzy-title'>
                        Chizitere Ofurum
                    </h5>
                    <p className='chizzy-expert'>
                        Fullstack Web Developer
                    </p>
                    <h4>Studied computer science</h4>
                    <h4>University of Benin, class 2023</h4>
                    <h1>
                        “Deebug Institute illuminated my path from shadows to confidence. Here, I 
                        learned that It's not just about mastering languages, it's about translating 
                        complexities and making meaningful connections.”
                    </h1>
                    <h2>Starting as a guy in the shadows, Chizitere initially found himself navigating the intricate world of Fullstack Web development, silently absorbing the wealth of knowledge around him.</h2>
                    <h2>During his early days at Deebug Institute, Chizzy was more of an observer. His reserved nature didn't really hinder his progress in learning, it just reduced his pace in personal development. As he delved more into learning his course of choice, he found solace in the lines of code, gradually translating his uncertainties into a language he could comprehend. The institute provided a supportive environment where he could ask questions, seek guidance, and gradually emerge from the shadows.</h2>
                </div>
                <div className='story-bground1'>
                        <img
                            src={chizzybtm}
                            width="100%"
                            height="100%"
                            alt='background'
                        />
                </div>
                <img
                    src={chizzybtm1}
                    width="100%"
                    height="100%"
                    alt='background'
                />
                <h2>Chizy has now nearly mastered fullstack web development, having successfully handled over 10 projects. This remarkable progress is a testament to the dedication and support provided by Debug Institute. </h2>
                <h2>The institute's mentorship has not only honed Chizy's technical skills but has also significantly boosted their confidence and interpersonal relationship abilities, preparing them for even greater achievements in the tech industry.</h2>
                <hr className="story-tourny-line" />
                <a href="https://www.instagram.com/alfredo_chizzy/" className="story-tourny-sm">
                    https://www.instagram.com/alfredo_chizzy/
                </a>
            </div>
        </div>
    </div>
  )
}

export default ChizzyStory