import React from "react";
import "./LeadershipView.css";

import MrP from "../../images/exe_mrp.png";
import DrLisa from "../../images/exe_lisa.png";
import KingMote from "../../images/exe_king.png";
// import DirectorM from "../../images/exe_martins.png";
export default function LeadershipView() {
  return (
    <div className="lead-parent">
      <div className="lead-sub-nav-bar-container">
        <div className="lead-sub-nav-bar">Deebug Leadership</div>
      </div>
      <div className="lead-profile-container">
        <div className="lead-profile-container-first">
          <div className="lead-profile-container-first-group">
            <p>Dr. Lisa Mote</p>
            <p>
              Senior Executive Assistant, HOD Administration, Chief Healthcare
              Officer
            </p>
          </div>
        </div>
        <div className="lead-profile-container-second">
          <img src={DrLisa} className="lead-profile-image" alt="executive" />
        </div>
      </div>

      <div className="lead-bio-container">
        <div>
          Lisa Mote is serving as a distinguished member of the board of
          directors at Deebug. Lisa brings valuable expertise in merging
          technology and healthcare, with a special focus on digital health.
          With a keen passion for creating impactful solutions, Dr. Lisa Mote
          has developed and published two successful medical apps on Google's
          Playstore called MedPlan and NaijaPharms respectively.
          <br />
          <br />
          Furthermore, Dr. Lisa Mote holds online certifications in mHealth
          Basics and mHealth Innovation from John Hopkins Bloomberg School of
          Public Health, showcasing her commitment to continuous learning and
          staying abreast of the latest advancements in the field.
        </div>
        <div>
          Dr. Lisa Mote is an accomplished professional at the forefront of
          healthcare and digital technology. Graduating as the overall best
          student from the Faculty of Pharmacy, UNIBEN 2016/2017 set.
          <br />
          <br /> Over the years, Dr. Lisa Mote has developed a deep
          understanding of the healthcare industry and she has contributed
          significantly to the field of digital health through groundbreaking
          research. Her published research papers in digital health demonstrates
          her dedication to pushing boundaries and addressing critical
          healthcare challenges.
        </div>
      </div>
    </div>
  );
}
