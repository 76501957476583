import React from 'react'
import "./indstories.css"
import purpose from "../../images/purposetop.webp"
import purpose1 from "../../images/purposetop.webp"
import purposebtm from "../../images/purposebtm.webp"
import purposebtm1 from "../../images/purposebtmprt.webp";

const PurposeStory = ({closeModal}) => {
  return (
    <div className='story-modal-overlay'>
        <div className="story-modal-content">
            <button className="story-close-button" onClick={closeModal}>X</button>
            <div className="story-content-main">
                <div className='story-bground'>
                    <img
                        src={purpose}
                        width="100%"
                        height="100%"
                        alt='background'
                    />
                </div>
                <img
                    src={purpose1}
                    width="100%"
                    height="100%"
                    alt='background'
                />
                <div className='story-content-text'>
                    <h5 className='purpose-title'>
                        Purpose  Olikiabo
                    </h5>
                    <p className='purpose-expert'>
                        UI/UX Designer
                    </p>
                    <h4>Studied computer science</h4>
                    <h4>University of Benin, class 2023</h4>
                    <h1>
                        “Deebug’s innovative education approach shaped my tech skills and fueled 
                        continuous creativity. Grateful for the transformative journey.”
                    </h1>
                    <h2>From a quiet observer to a design virtuoso, Purpose transformation is nothing short of extraordinary. Once reserved, she embarked on her journey at Deebug Institute that not only polished her design skills but also brought her out of her shell. </h2>
                    <h2>In the beginning, Purpose was a girl who preferred the solitude of her thoughts, but During her time at the institute, Purpose not only honed her technical design expertise but also discovered the power of effective communication. Through engaging projects, collaborative workshops, and mentorship, Purpose developed not only as a skilled designer but also as a confident communicator.</h2>
                </div>
                <div className='story-bground'>
                        <img
                            src={purposebtm}
                            width="100%"
                            height="100%"
                            alt='background'
                        />
                </div>
                <img
                    src={purposebtm1}
                    width="100%"
                    height="100%"
                    alt='background'
                />
                <h2>Upon graduation from the institute, Purpose has undergone an evident transformation. Armed with a robust portfolio and newfound communication skills, she delved into the professional world. Purpose creative prowess and ability to articulate design concepts set her apart in the industry.</h2>
                <h2>Since then, Purpose has contributed to several design projects, leaving her mark on diverse creative landscapes. Her success story stands as a testament to the transformative power of education and mentorship. </h2>
                <h2>Below are links to some of the projects Purpose has played a pivotal role in designing</h2>
                <hr className="story-tourny-line" />
                <a href="https://www.instagram.com/onye_sota_/" className="story-tourny-sm">
                    https://www.instagram.com/onye_sota_/
                </a>
            </div>
        </div>
    </div>
  )
}

export default PurposeStory