import React, { useState } from 'react';
import "./ecourses.css";
import badge from "../../images/badgeeeee.png";
import frontend from "../../images/frontendangular.webp";
import frontendprt from "../../images/frontendangularprt.png";
import backend from "../../images/backendprev.webp"
import history from "../../images/history.png";
import cert from "../../images/smallcert.png";
import check from "../../images/check.png";
import user from "../../images/courseuser.png";
import figview from "../../images/figmapreview.webp";
import yellow from "../../images/star.png";
import white from "../../images/ratewhite.png";


const E_Frontend = () => {
    const [isYellow2, setisYellow2] = useState(true);

    const handleClick2 = () => {
        setisYellow2(prevState => !prevState);
    };


    const [isYellow3, setisYellow3] = useState(true);

    const handleClick3 = () => {
        setisYellow3(prevState => !prevState);
    };
    
    const [isMobile1] = useState(window.innerWidth < 768);

    return (
        <div className='e-course-main'>
            <div className='courses-bground'>
                <div className='courses-bground-text'>
                    <img
                        src={badge}
                        width="auto"
                        height="auto"
                        alt='icon'
                    />
                    <h1>Deebug Institute Certified: Frontend (ReactJS) Software Development</h1>
                    <h2>Become a proficient Frontend Software Developer with our comprehensive professional course.</h2>
                    <button><a href='https://elearning.deebug.org'>Apply now</a></button>

                </div>
                <div className='courses-bground-img'>
                    <img
                        src={isMobile1 ? frontendprt : frontend}
                        width="100%"
                        height='100%'
                        alt='icon'
                    />
                </div>
            </div>
            <div className='course-details'>
                <h1>Course details</h1>
                <div className='course-duration'>
                    <div className='course-duration-child'>
                        <div className='course-duration-props'>
                            <div className='course-duration-content'>
                                <img
                                    src={history}
                                    width="20px"
                                    height="20px"
                                    alt='icon'
                                />
                                <h1>Level</h1>
                            </div>
                            <div className='course-duration-props-child'>
                                <p>Beginner</p>
                            </div>
                        </div>
                        <div className='course-duration-props'>
                            <div className='course-duration-content'>
                                <img
                                    src={history}
                                    width="20px"
                                    height="20px"
                                    alt='icon'
                                />
                                <h1>Price</h1>
                            </div>
                            <div className='course-duration-props-child'>
                                <p> ₦75,000</p>
                            </div>
                        </div>
                    </div>
                    <div className='course-duration-child'>
                        <div className='course-duration-props'>
                            <div className='course-duration-content'>
                                <img
                                    src={history}
                                    width="20px"
                                    height="20px"
                                    alt='icon'
                                />
                                <h1>Duration</h1>
                            </div>
                            <div className='course-duration-props-child'>
                                <p>3 months @ 6 hrs per week</p>
                            </div>
                        </div>
                        <div className='course-duration-props'>
                            <div className='course-duration-content'>
                                <img
                                    src={history}
                                    width="20px"
                                    height="20px"
                                    alt='icon'
                                />
                                <h1>Prerequisites</h1>
                            </div>
                            <div className='course-duration-props-child'>
                                <p>No prior experience required</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='course-overview'>
                <h1>Course overview</h1>
                <p>
                    This Frontend Web Development course offers a deep dive into the intersection
                    of software development, frontend engineering, and emerging technologies.
                    Students will gain proficiency in advanced JavaScript techniques, frontend
                    frameworks like React, and essential development tools and methodologies.
                </p>
                <p>
                    The course covers key concepts such as data structures and algorithms,
                    agile development, automation testing, and version control with Git.
                </p>
            </div>
            <div className="course-content">
                <h1>Course content</h1>
                <p>7 Modules</p>
                <div className='course-week'>
                    <div className='course-header'>
                        <span>Fundamentals of Web Development</span>
                        <span>Frontend Development Essentials</span>
                        <span>Data Structures and Algorithms</span>
                        <span>Testing and Quality Assurance</span>
                        <span>Agile Development and DevOps Practices</span>
                        <span>Advanced Frontend Development</span>
                        <span>Emerging Technologies and Specializations</span>
                    </div>
                </div>
            </div>
            <div className='course-certificate'>
                <div className='course-skill'>
                    <h1>Learning outcome</h1>
                    <p>By successfully completing this comprehensive program, you will be well-equipped to:</p>
                    <div className='course-skill-content'>
                        <img
                            src={check}
                            width="auto"
                            height="auto"
                            alt='check'
                        />
                        <p>Build a simple webpage using HTML, CSS, and JavaScript to demonstrate DOM manipulation.</p>
                    </div>
                    <div className='course-skill-content'>
                        <img
                            src={check}
                            width="auto"
                            height="auto"
                            alt='check'
                        />
                        <p>Write unit tests for a frontend component using Jest and a testing library</p>
                    </div>
                    <div className='course-skill-content'>
                        <img
                            src={check}
                            width="auto"
                            height="auto"
                            alt='check'
                        />
                        <p>Implement a basic data structure (e.g., linked list) and analyze its time/space complexity.</p>
                    </div>
                    <div className='course-skill-content'>
                        <img
                            src={check}
                            width="auto"
                            height="auto"
                            alt='check'
                        />
                        <p>Configure a Git repository and demonstrate branching, committing, and merging.</p>
                    </div>
                    <div className='course-skill-content'>
                        <img
                            src={check}
                            width="auto"
                            height="auto"
                            alt='check'
                        />
                        <p>Explain key concepts of Agile development (e.g., Scrum) and CI/CD pipelines.</p>
                    </div>
                </div>
                <div className='course-certificate-img'>
                    <img
                        src={cert}
                        width="100%"
                        height="100%"
                        alt='icon'
                    />
                    <button><a href='https://elearning.deebug.org'>Apply now</a></button>
                </div>
            </div>
            <div className='course-related'>
                <h1>Related Courses</h1>
                <div className='e-courses-container'>
                    <div className='explore-courses-ctn'>
                        <div className='explore-courses-ctn-img'>
                            <img
                                src={figview}
                                width="100%"
                                height="100%"
                                alt='logo'
                            />
                        </div>
                        <div className='explore-courses-ctn-text'>
                            <h2><a href='/ux-ui-design'> UX/UI Design</a></h2>
                            <p>Learn to create intuitive and user-friendly interfaces for exceptional user experiences.</p>
                        </div>
                        <div className='explore-course-about'>
                            <p>₦ 65,000</p>
                            <div className='explore-course-review'>
                                <img
                                    src={user}
                                    width="22px"
                                    height="22px"
                                    alt='icon'
                                />
                                <p>124+</p>
                                <div className='wishlist-container' onClick={handleClick2}>
                                    <img
                                        src={isYellow2 ? white : yellow}
                                        alt={isYellow2 ? 'rating' : 'rating'}
                                        className={`rate-image ${isYellow2 ? 'rate-fade-in' : 'rate-fade-out'}`}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='explore-courses-ctn'>
                        <div className='explore-courses-ctn-img'>
                            <img
                                src={backend}
                                width="100%"
                                height="100%"
                                alt='logo'
                            />
                        </div>
                        <div className='explore-courses-ctn-text'>
                            <h2> <a href='/backend-development'>Backend Development</a></h2>
                            <p>Learn to create server-side logic, databases, and APIs that make applications function efficiently behind the scenes.</p>
                        </div>
                        <div className='explore-course-about'>
                            <p>₦ 75,000</p>
                            <div className='explore-course-review'>
                                <img
                                    src={user}
                                    width="22px"
                                    height="22px"
                                    alt='icon'
                                />
                                <p>210+</p>
                                <div className='wishlist-container' onClick={handleClick3}>
                                    <img
                                        src={isYellow3 ? white : yellow}
                                        alt={isYellow3 ? 'rating' : 'rating'}
                                        className={`rate-image ${isYellow3 ? 'rate-fade-in' : 'rate-fade-out'}`}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default E_Frontend