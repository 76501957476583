import React, {useState} from 'react';
import './healthcare.css';
import hmbdwn from '../../images/hmbdwn.png';
import healthpic from '../../images/healthpic.png';
import healthpic1 from '../../images/healthpic1.png';
import healthpic2 from '../../images/healthpic2.png';
import healthpic3 from '../../images/healthpic3.png';
import healthpic4 from '../../images/healthpic4.png';
import ChevronRight from '../../images/chevron_right_blue.png';
import healthmb from '../../images/healthmb.png';


const Healthcare = () => {
    const [isOpen, setIsOpen] = useState(false);

  
    const toggleMenu = () => {
        setIsOpen(!isOpen);
      };

  return (
    <div className='healthcare-main'>
        <div className='health-top-container'>
            <div className='health-topbar'>
                <h1>Healthcare</h1>
                <ul className={isOpen ? "health-menu active" : "health-menu"}>
                    <li> <a href='/maintenance' className='health-link'>Overview</a></li>
                    <li> <a href='/maintenance' className='health-link'>News & Event</a></li>
                    <li> <a href='/maintenance' className='health-link'>Research</a></li>
                </ul>
                <div className="health-ham-btn">
                    <img 
                        src={hmbdwn}
                        width="100%"
                        height="100%"
                        alt='icon'
                        onClick={toggleMenu}
                    />
                </div>
            </div>
            <div className='healthbground-text'>
                <p>Using Technology to provide</p>
                <h1>Digital</h1>
                <p>Healthcare Solutions</p>
            </div>
        </div>
        <div className='health-about'>
            <h1>
                We are dedicated to enhancing healthcare delivery 
                for both patients and healthcare providers with innovative, 
                user-friendly products designed to streamline workflows,
                reduce administrative burdens, and improve communication, empowering 
                patients to take control of their health conveniently and efficiently
            </h1>
        </div>
        <div className='health-main-container'>
            <div className='health-content-child1'>
                <div className='health-child-title'>
                    <h1>Deebug in Pharmacy</h1>
                    <h2>Experience healthcare on your terms with our user- friendly products, enabling you to connect with your pharmacist for personalized care whenever and wherever you need it.</h2>
                    <div className="health-learn-btn"><a className="section_learn_more" href="/maintenance"> Learn more about our product</a><img src={ChevronRight} className="learn_more_icon" /></div>
                </div>
                <div className='health-child-content'>                        
                    <div className='health-child-image'>
                        <img
                            src={healthpic}
                            height='100%'
                            width='100%'
                            alt='icon'
                            className='health-child-icon1'
                        />
                    </div>
                </div>
            </div>
            <div className='health-content-child'>
                <div className='health-child-content'>                        
                    <div className='health-child-image'>
                        <img
                            src={healthpic2}
                            height='100%'
                            width='100%'
                            alt='icon'
                            className='health-child-icon'
                        />
                    </div>
                </div>
                <div className='health-child-title'>
                    <h1>Continue Patient Care at Home</h1>
                    <h2>
                        We are committed to improving healthcare outcomes and 
                        making healthcare more accessible, affordable, and patient- 
                        centered regardless of location, economic status, or health condition.
                    </h2>
                    <div className="health-learn-btn"><a className="section_learn_more" href="/maintenance"> Learn more about our product</a><img src={ChevronRight} className="learn_more_icon" /></div>
                </div>
            </div>
            <div className='health-content-child1'>
                <div className='health-child-title'>
                    <h1>Enhancing Healthcare  Connectivity</h1>
                    <h2>An amazing platform brings pharmacists together, fostering a vibrant community that facilitates learning, networking, and abundant job opportunities.</h2>
                    <div className="health-learn-btn"><a className="section_learn_more" href="/maintenance"> Learn more about our product</a><img src={ChevronRight} className="learn_more_icon" /></div>
                </div>
                <div className='health-child-content'>                        
                    <div className='health-child-image'>
                        <img
                            src={healthpic3}
                            height='100%'
                            width='100%'
                            alt='icon'
                            className='health-child-icon1'
                        />
                    </div>
                </div>
            </div>
            <h1>Transforming Healthcare </h1>
            <div className='health-transform'>
                <div className='health-trans-props'>
                    <div className='health-trans-img'>
                        <img
                            src={healthmb}
                            width="100%"
                            height="100%"
                            alt='icon'
                        />
                    </div>
                    <h1>Telemedicine</h1>
                    <p>We are committed to making healthcare more accessible through our innovative products.</p>
                </div>
                <div className='health-trans-props'>
                    <div className='health-trans-img'>
                        <img
                            src={healthmb}
                            width="100%"
                            height="100%"
                            alt='icon'
                        />
                    </div>
                    <h1>Personalized care</h1>
                    <p>We prioritize providing tailored healthcare solutions that meet patients' needs with patient-centered customizable features.</p>
                </div>
                <div className='health-trans-props'>
                    <div className='health-trans-img'>
                        <img
                            src={healthmb}
                            width="100%"
                            height="100%"
                            alt='icon'
                        />
                    </div>
                    <h1>Telemedicine</h1>
                    <p>Making learning accessible by providing medical educational tools and resources.</p>
                </div>
            </div>
            <div className='health-content-child'>
                <div className='health-child-content'>                        
                    <div className='health-child-image'>
                        <img
                            src={healthpic1}
                            height='100%'
                            width='100%'
                            alt='icon'
                            className='health-child-icon'
                        />
                    </div>
                </div>
                <div className='health-child-title'>
                    <p>Research</p>
                    <h1>Discover Insights from our Research in Digital health</h1>
                    <h2>
                    Our research arm is dedicated to advancing digital health through cutting-edge innovation and scientific exploration, with the ultimate goal of improving healthcare outcomes and transforming the way we approach patient care.
                    </h2>
                    <div className="health-learn-btn"><a className="section_learn_more" href="/maintenance"> Learn more</a><img src={ChevronRight} className="learn_more_icon" /></div>
                </div>
            </div>
            <div className='health-content-child1'>
                <div className='health-child-title'>
                    <p>Events</p>
                    <h1>Accelerating Digital Innovation in Healthcare in Africa (ADIHA)</h1>
                    <h2>Join us in accelerating digital innovation in African healthcare at our annual event where healthcare professionals, tech experts, and entrepreneurs connect, collaborate and showcase their latest innovations.</h2>
                    <div className="health-learn-btn"><a className="section_learn_more" href="/maintenance"> Learn more</a><img src={ChevronRight} className="learn_more_icon" /></div>
                </div>
                <div className='health-child-content'>                        
                    <div className='health-child-image'>
                        <img
                            src={healthpic4}
                            height='100%'
                            width='100%'
                            alt='icon'
                            className='health-child-icon1'
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Healthcare