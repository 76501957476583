import React from "react";
import "./Leadership.css";
import Default from "../../images/mrp.jpg";
import KingMote from "../../images/e_king.png";
import DrLisa from "../../images/e_lisa.png";
import MrP from "../../images/e_mrp.png";
// import DirectorM from "../../images/e_martins.png";
// import KingMote from "../../images/exe_king.png";
// import DrLisa from "../../images/exe_lisa.png";
// import MrP from "../../images/exe_mrp.png";
// import DirectorM from "../../images/exe_martins.png";

import { useNavigate } from "react-router-dom";

export default function Leadership() {
  return (
    <>
      <div className="leadership_page_container">
        <div className="leadership_page_parent">
          <div className="leadership_header_container">
            <p>Deebug Leadership</p>
          </div>

          <p className="profiles_header">Executive Profiles</p>

          <div className="profiles_container">
            <Profile
              className={"profile_frame1"}
              image={KingMote}
              name="Engr. King J. N. Mote"
              designation="President, Founder"
            />
            <Profile
              className={"profile_frame2"}
              image={DrLisa}
              name="Dr. Lisa Mote"
              designation="Senior Executive Assistant, HOD Administration, Chief Healthcare Officer"
            />
            <Profile
              className={"profile_frame3"}
              image={MrP}
              name="Promise Osagie"
              designation="HOD Academics, HOD Projects"
            />
            {/* <Profile
              className={"profile_frame4"}
              image={DirectorM}
              name="Martins Usigbe"
              designation="HOD Socials, HOD Human Resource,HOD Operations"
            /> */}
          </div>
        </div>
      </div>
    </>
  );
}

const Profile = ({ image, name, designation, className }) => {
  const navigate = useNavigate();

  return (
    <div className={className}>
      <div className="img_frame">
        <img src={image} style={{ objectFit: "contain" }} />
      </div>
      <p
        className="profile_name"
        onClick={() => navigate("/deebug_leadership_view")}
      >
        {name}
      </p>
      <p className="profile_designation">{designation}</p>
    </div>
  );
};
