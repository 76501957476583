import React, {useState} from 'react'
import "./ecourses.css";
import badge from "../../images/badgeeeee.png";
import backend from "../../images/backendprev.webp";
import history from "../../images/history.png";
import cert from "../../images/smallcert.png";
import check from "../../images/check.png";
import frontend1 from "../../images/frontendangular.webp";
import frontendprt from "../../images/frontendangularprt.png";
import frontend from "../../images/frontendprev.webp";
import user from "../../images/courseuser.png";
import yellow from "../../images/star.png";
import white from "../../images/ratewhite.png";

const E_FullstackWeb = () => {

    const [isYellow, setisYellow] = useState(true);

    const handleClick = () => {
        setisYellow(prevState => !prevState);
    };


    const [isYellow3, setisYellow3] = useState(true);

    const handleClick3 = () => {
        setisYellow3(prevState => !prevState);
    };

    const [isMobile1] = useState(window.innerWidth < 768);
    
  return (
    <div className='e-course-main'>
        <div className='courses-bground'>
            <div className='courses-bground-text'>
                <img
                    src={badge}
                    width="auto"
                    height='70px'
                    alt='icon'
                />
                <h1>Deebug Institute Certified: Full Stack (MERN) Software Development</h1>
                <h2>Become a proficient Full Stack (MERN) Software Developer with our comprehensive professional course.</h2>
                <button><a href='https://elearning.deebug.org'>Apply now</a></button>

            </div>
            <div className='courses-bground-img'>
                <img
                    src={isMobile1 ? frontendprt : frontend1}
                    width="100%"
                    height='100%'
                    alt='icon'
                />
            </div>
        </div>
        <div className='course-details'>
            <h1>Course details</h1>
            <div className='course-duration'>
                <div className='course-duration-child'>
                    <div className='course-duration-props'>
                        <div className='course-duration-content'>
                            <img
                                src={history}
                                width="auto"
                                height="auto"
                                alt='icon'
                            />
                            <h1>Level</h1>
                        </div>
                        <div className='course-duration-props-child'>
                            <p>Beginner</p>
                        </div>
                    </div>
                    <div className='course-duration-props'>
                        <div className='course-duration-content'>
                            <img
                                src={history}
                                width="auto"
                                height="auto"
                                alt='icon'
                            />
                            <h1>Price</h1>
                        </div>
                        <div className='course-duration-props-child'>
                            <p> ₦ 150,000</p>
                        </div>
                    </div>
                </div>
                <div className='course-duration-child'>
                    <div className='course-duration-props'>
                        <div className='course-duration-content'>
                            <img
                                src={history}
                                width="auto"
                                height="auto"
                                alt='icon'
                            />
                            <h1>Duration</h1>
                        </div>
                        <div className='course-duration-props-child'>
                            <p>3 months @ 6 hrs per week</p>
                        </div>
                    </div>
                    <div className='course-duration-props'>
                        <div className='course-duration-content'>
                            <img
                                src={history}
                                width="auto"
                                height="auto"
                                alt='icon'
                            />
                            <h1>Prerequisites</h1>
                        </div>
                        <div className='course-duration-props-child'>
                            <li>No prior experience required</li>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='course-overview'>
            <h1>Course overview</h1>
            <p>
                This course covers all aspects of web development, from frontend 
                essentials like HTML, CSS, and JavaScript to backend fundamentals 
                like RESTful API development and database management. You'll learn 
                to build responsive and scalable web applications using popular 
                frameworks like React.js and Express.js, along with mastering DevOps 
                practices, testing methodologies, and emerging technologies like AI 
                integration and blockchain development.
            </p>
            <p>
                With hands-on projects, real-world case studies, and expert guidance, 
                you'll gain the skills and confidence to succeed as a Full Stack Software 
                Developer in today's competitive tech industry. By successfully completing 
                this comprehensive program, you'll be well-equipped to:
            </p>
        </div>
        <div className="course-content">
            <h1>Course content</h1>
            <p>13 Modules</p>
            <div className='course-week'>
                <div className='course-header'>
                    <span>Fundamentals of Web Development</span>
                    <span>Frontend Development Essentials</span>
                    <span>Data Structures and Algorithms</span>
                    <span>Testing and Quality Assurance</span>
                    <span>Agile Development and DevOps Practices</span>
                    <span>Advanced Frontend Development</span>
                    <span>Emerging Technologies and Specializations</span>
                    <span>Backend Development Fundamentals</span>
                    <span>DevOps Practices</span>
                    <span>Database Management</span>
                    <span>Backend Testing and Security</span>
                    <span>Advanced Backend Development</span>
                    <span>Version Control and Collaboration</span>
                </div>
            </div>
        </div>
        <div className='course-certificate'>
            <div className='course-skill'>
                <h1>Learning outcome</h1>
                <p>By successfully completing this comprehensive program, you will be well-equipped to:</p>
                <div className='course-skill-content'>
                    <img
                        src={check}
                        width="auto"
                        height="auto"
                        alt='check'
                    />
                    <p>Understanding programming concepts and web technologies</p>
                </div>
                <div className='course-skill-content'>
                    <img
                        src={check}
                        width="auto"
                        height="auto"
                        alt='check'
                    />
                    <p>Working with industry standard version control systems like Git and GitHub</p>
                </div>
                <div className='course-skill-content'>
                    <img
                        src={check}
                        width="auto"
                        height="auto"
                        alt='check'
                    />
                    <p>Capable of building full-fledged web applications from scratch using the MERN stack</p>
                </div>
                <div className='course-skill-content'>
                    <img
                        src={check}
                        width="auto"
                        height="auto"
                        alt='check'
                    />
                    <p>Understand both frontend and backend development concepts.</p>
                </div>
                <div className='course-skill-content'>
                    <img
                        src={check}
                        width="auto"
                        height="auto"
                        alt='check'
                    />
                    <p>Utilize industry-standard tools and technologies for building modern web applications.</p>
                </div>
                <div className='course-skill-content'>
                    <img
                        src={check}
                        width="auto"
                        height="auto"
                        alt='check'
                    />
                    <p>Implement best practices for testing, security, and version control.</p>
                </div>
                <div className='course-skill-content'>
                    <img
                        src={check}
                        width="auto"
                        height="auto"
                        alt='check'
                    />
                    <p>Collaborate effectively within a software development team.</p>
                </div>
                <div className='course-skill-content'>
                    <img
                        src={check}
                        width="auto"
                        height="auto"
                        alt='check'
                    />
                    <p>Confident in applying for Full-Stack Developer positions in the job market.</p>
                </div>
            </div>
            <div className='course-certificate-img'>
                <img
                    src={cert}
                    width="auto"
                    height="auto"
                    alt='icon'
                />
                <button><a href='https://elearning.deebug.org'>Apply now</a></button>
            </div>
        </div>
        <div className='course-related'>
            <h1>Related Courses</h1>
            <div className='e-courses-container'>
                <div className='explore-courses-ctn'>
                    <div className='explore-courses-ctn-img'>
                        <img
                            src={frontend}
                            width="100%"
                            height="100%"
                            alt='logo'
                        />
                    </div>
                    <div className='explore-courses-ctn-text'>
                        <h2> <a href='frontend-web-development'>Frontend Web Development</a></h2>
                        <p>Learn how to craft interactive, visually engaging websites using HTML, CSS, and ReactJS.</p>
                    </div>
                    <div className='explore-course-about'>
                        <p>₦ 75,000</p>
                        <div className='explore-course-review'>
                            <img
                                src={user}
                                width="22px"
                                height="22px"
                                alt='icon'
                            />
                            <p>210+</p>
                            <div className='wishlist-container' onClick={handleClick}>
                                <img
                                    src={isYellow ? white : yellow}
                                    alt={isYellow ? 'rating' : 'rating'}
                                    className={`rate-image ${isYellow ? 'rate-fade-in' : 'rate-fade-out'}`}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='explore-courses-ctn'>
                    <div className='explore-courses-ctn-img'>
                        <img
                            src={backend}
                            width="100%"
                            height="100%"
                            alt='logo'
                        />
                    </div>
                    <div className='explore-courses-ctn-text'>
                        <h2> <a href='/backend-development'>Backend Development</a></h2>
                        <p>Learn to create server-side logic, databases, and APIs that make applications function efficiently behind the scenes.</p>
                    </div>
                    <div className='explore-course-about'>
                        <p>₦ 75,000</p>
                        <div className='explore-course-review'>
                            <img
                                src={user}
                                width="22px"
                                height="22px"
                                alt='icon'
                            />
                            <p>210+</p>
                            <div className='wishlist-container' onClick={handleClick3}>
                                <img
                                    src={isYellow3 ? white : yellow}
                                    alt={isYellow3 ? 'rating' : 'rating'}
                                    className={`rate-image ${isYellow3 ? 'rate-fade-in' : 'rate-fade-out'}`}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default E_FullstackWeb