import React, { useState, useEffect, useRef } from "react";
import "./general_gaming.css";
import gamescroll2 from "../../images/mk11.png";
import gamescroll3 from "../../images/codm.png";
import game1 from "../../images/gamingtriplet.webp";
import modernwarfare from "../../images/modernwarfare.webp";
import pubg from "../../images/pubg.webp";
import chess from "../../images/chess.webp";
import scrabble from "../../images/scrabble.webp";
import gaming4 from "../../images/gaming4.webp";
import eafc from "../../images/eafc.png";
import princewill from "../../images/princewill.png";
import stanley from "../../images/stanley.png";
import land1 from "../../images/gamingland1.webp";
import land2 from "../../images/gamingland2.webp";
import land3 from "../../images/gamingland3.webp";
import land4 from "../../images/gamingland4.webp";
import ChevronRight from "../../images/chevron_right_blue.png";

const GeneralGaming = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const slideshowRef = useRef(null);

  const images = [land1, land2, land3, land4];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (currentIndex === images.length) {
      const timeout = setTimeout(() => {
        slideshowRef.current.style.transition = "none";
        setCurrentIndex(0);
        setTimeout(() => {
          slideshowRef.current.style.transition = "transform 1s ease-in-out";
          setCurrentIndex(1);
        }, 50); // Slight delay to re-enable transition
      }, 1000); // Time for the transition to the duplicated slide
      return () => clearTimeout(timeout);
    }
  }, [currentIndex, images.length]);

  const textRef = useRef(null);
  const [isInView, setIsInView] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsInView(true);
          } else {
            setIsInView(false); // Reset animation when out of view
          }
        });
      },
      { threshold: 0.1 } // Adjust the threshold as needed
    );

    if (textRef.current) {
      observer.observe(textRef.current);
    }

    return () => {
      if (textRef.current) {
        observer.unobserve(textRef.current);
      }
    };
  }, []);

  const text = "Unparelled Fun";

  return (
    <div className="game-main">
      <div className="game">
        <div className="gaming-slideshow-container">
          <div
            className="gaming-slideshow"
            style={{
              transform: `translateX(-${
                (currentIndex % (images.length + 1)) * 100
              }%)`,
              transition: "transform 1s ease-in-out",
            }}
            ref={slideshowRef}
          >
            {images.concat(images[0]).map((image, index) => (
              <div className="gaming-slide" key={`${image}-${index}`}>
                <div className="gaming-image-wrapper">
                  <img src={image} alt={`gaming-Slide ${index + 1}`} />
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="game-content">
          <h1 className="game-event-title">Epic Event</h1>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: 0,
              padding: 0,
            }}
          >
            {" "}
            {/* Create scroll space and centralize */}
            <h1
              ref={textRef}
              className={`gaming-animatedText ${isInView ? "gaming-animate" : ""}`}
            >
              {text.split("").map((char, index) => (
                <span key={index} style={{ "--char-index": index }}>
                  {char}
                </span>
              ))}
            </h1>
          </div>
          <div className="more-game-images">
            <div className="game-image">
              <img src={game1} width="100%" height="100%" alt="icon" />
            </div>
          </div>
          <p className="game-event-subtitle">
            Students immerse themselves in unparalleled fun as they engage in a
            day packed with exciting activities and thrilling challenges. From
            high-energy gaming sessions to interactive experiences, a day that
            promises to non-stop entertainment and reation of unforgettable
            memories.
          </p>
          <div className="game-battle-royale">
            <h1>Battle Royale</h1>
            <p>
              Watch as game enthusiasts gather to showcase their skills. From
              lightning-fast reflexes to strategic brilliance, witness the
              intensity as competitors battle it out for gaming supremacy.
            </p>
            <button>Coming soon</button>
          </div>
        </div>
        <div className="more-games">
          <h1>Featured Games</h1>
          <div className="featured-games-parent">
            <div className="featured-games-container">
              <div className="featured-games">
                <img
                  src={gamescroll3}
                  width="100%"
                  height="100%"
                  className="gaming-pics"
                  alt="icon"
                />
              </div>
              <div className="featured-games">
                <img
                  src={gamescroll2}
                  width="100%"
                  height="100%"
                  className="gaming-pics"
                  alt="icon"
                />
              </div>
              <div className="featured-games">
                <img
                  src={eafc}
                  width="100%"
                  height="100%"
                  className="gaming-pics"
                  alt="icon"
                />
              </div>
              <div className="featured-games">
                <img
                  src={modernwarfare}
                  width="100%"
                  height="100%"
                  className="gaming-pics"
                  alt="icon"
                />
              </div>
              <div className="featured-games">
                <img
                  src={chess}
                  width="100%"
                  height="100%"
                  className="gaming-pics"
                  alt="icon"
                />
              </div>
              <div className="featured-games">
                <img
                  src={pubg}
                  width="100%"
                  height="100%"
                  className="gaming-pics"
                  alt="icon"
                />
              </div>
              <div className="featured-games">
                <img
                  src={scrabble}
                  width="100%"
                  height="100%"
                  className="gaming-pics"
                  alt="icon"
                />
              </div>
              <div className="featured-games">
                <img
                  src={gaming4}
                  width="100%"
                  height="100%"
                  className="gaming-pics"
                  alt="icon"
                />
              </div>

              {/* DUPLICATES START FROM HERE NOTE THAT */}

              <div className="featured-games">
                <img
                  src={gamescroll3}
                  width="100%"
                  height="100%"
                  className="gaming-pics"
                  alt="icon"
                />
              </div>
              <div className="featured-games">
                <img
                  src={gamescroll2}
                  width="100%"
                  height="100%"
                  className="gaming-pics"
                  alt="icon"
                />
              </div>
              <div className="featured-games">
                <img
                  src={eafc}
                  width="100%"
                  height="100%"
                  className="gaming-pics"
                  alt="icon"
                />
              </div>
              <div className="featured-games">
                <img
                  src={modernwarfare}
                  width="100%"
                  height="100%"
                  className="gaming-pics"
                  alt="icon"
                />
              </div>
              <div className="featured-games">
                <img
                  src={chess}
                  width="100%"
                  height="100%"
                  className="gaming-pics"
                  alt="icon"
                />
              </div>
              <div className="featured-games">
                <img
                  src={pubg}
                  width="100%"
                  height="100%"
                  className="gaming-pics"
                  alt="icon"
                />
              </div>
              <div className="featured-games">
                <img
                  src={scrabble}
                  width="100%"
                  height="100%"
                  className="gaming-pics"
                  alt="icon"
                />
              </div>
              <div className="featured-games">
                <img
                  src={gaming4}
                  width="100%"
                  height="100%"
                  className="gaming-pics"
                  alt="icon"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="champions-container">
          <h1>Our Champions</h1>
          <div className="champions-box">
            <div className="champions-details">
              <div className="champions-pic">
                <img
                  src={princewill}
                  width="100%"
                  height="100%"
                  alt="icon"
                  className="gaming-pics1"
                />
              </div>
              <h2>Princewill</h2>
              <p>FIFA Champion 2024</p>
            </div>
            <div className="champions-details">
              <div className="champions-pic">
                <img
                  src={stanley}
                  width="100%"
                  height="100%"
                  alt="icon"
                  className="gaming-pics1"
                />
              </div>
              <h2>Stanley</h2>
              <p>Mortal Kombat Champion 2024</p>
            </div>
          </div>
        </div>
        <div className="game-more">
          <h1>
            Learn more about our <br /> Events
          </h1>
          <div className="more-events">
            <a href="/events">Go to events</a>
            <img src={ChevronRight} className="learn_more_icon" alt="icon" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralGaming;
