import React, {useState, useEffect,useRef} from 'react';
import './backend.css';
import Popup from '../popup/PopUp';
import { Link } from 'react-router-dom';
import backendbground from '../../images/backendbground.png';
import embededpic1 from '../../images/embededpic1.png';
import backendcheck from '../../images/backendcheck.png';
import backendapply from '../../images/backendapply.png';
import previous from '../../images/backblack.png';
import next from '../../images/forwardblack.png';
import backendletter from '../../images/backendletter.png';
import backendcall from '../../images/backendcall.png';
import teststory3 from '../../images/teststory3.webp';
import teststory4 from '../../images/teststory4.webp';
import teststory5 from '../../images/teststory5.webp';
import teststory7 from '../../images/teststory7.webp';      
import backendchat from '../../images/backendchat.png';
import ChevronRight from "../../images/chevron_right_blue.png";
import arrow_down from "../../images/arrow_down.png"

const Backend = () => {
    
const testimonials = [
    {
      text: "“My time at Deebug taking the Frontend Web Development course was worth it. The knowledgeable instructors were always helpful. I gained valuable skills, making it a rewarding learning experience.”",
      name: "Ononyiwita Godsglory",
      class: "Class of 2024",
      image: teststory4
    },
    {
      text: "“It was a wonderful experience learning my first tech skill at Deebug Institute. What amazed me the most was how the tutors broke down every aspect of what was been taught. They made it seem so easy.”",
      name: "Tunde Oriyomi",
      class: "Class of 2024",
      image: teststory7
    },
    {
      text: "“My time at Deebug taking the Frontend Web Development course was worth it. The knowledgeable instructors were always helpful. I gained valuable skills, making it a rewarding learning experience.”",
      name: "Precious",
      class: "Class of 2024",
      image: teststory3
    },    
    {
      text: "“The projects at Deebug helped me build a solid portfolio. I feel prepared to tackle any frontend development challenge.”",
      name: "Michael Brown",
      class: "Class of 2024",
      image: teststory5
    }
  ];
  
  
    const [currentIndex, setCurrentIndex] = useState(0);
    const containerRef = useRef(null);
    const containerRef1 = useRef(null);
  
    const handleScroll = (direction) => {
      const container = containerRef.current;
      const containerWidth = container.clientWidth;
      const newIndex = direction === 'left'
        ? Math.max(currentIndex - 1, 0)
        : Math.min(currentIndex + 1, testimonials.length - 1);
  
      setCurrentIndex(newIndex);
      container.scrollTo({
        left: newIndex * containerWidth,
        behavior: 'smooth'
      });
    };
    
    const [showPopup, setShowPopup] = useState(false);

    const openPopup = () => {
        setShowPopup(true);
    };

    const closePopup = () => {
        setShowPopup(false);
    };

    const handleProceed = () => {
        window.location.href = '/RegularTrainingRegistration';
        // Handle proceed action
    };

    
    useEffect(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              entry.target.classList.add('animate');
            } else {
              entry.target.classList.remove('animate');
            }
          });
        },
        { threshold: 0.1 } // Adjust threshold as needed
      );
  
      if (containerRef1.current) {
        observer.observe(containerRef1.current);
      }
  
      return () => {
        if (containerRef1.current) {
          observer.unobserve(containerRef1.current);
        }
      };
    }, []);


    

   
const AnimateText = ({ text }) => {
    const textRef = useRef(null);
    const [isInView, setIsInView] = useState(false);
  
    useEffect(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setIsInView(true);
            } else {
              setIsInView(false); // Reset animation when out of view
            }
          });
        },
        { threshold: 0.1 } // Adjust the threshold as needed
      );
  
      if (textRef.current) {
        observer.observe(textRef.current);
      }
  
      return () => {
        if (textRef.current) {
          observer.unobserve(textRef.current);
        }
      };
    }, []);
  
    return (
      <div className="animate-container">
        <h1
          ref={textRef}
          className={`backend-animatedText ${isInView ? 'backend-animate' : ''}`}
        >
          {text.split('').map((char, index) => (
            <span key={index} style={{ '--char-index': index }}>
              {char}
            </span>
          ))}
        </h1>
      </div>
    );
  };

  return (
    <div>
        <div className="backend-course-sub-navbar">
            <p>BACKEND DEVELOPMENT</p>
            <div className='backend-nav-apply'>
                <span  onClick={openPopup} className="backend-course-sub-navbar-selection">Apply</span>
                {showPopup && (
                    <Popup
                    title="Regular Training Registration"
                    content="Please note that during the registration process for our Regular Training program, you will be required to provide some information."
                    onClose={closePopup}
                    onProceed={handleProceed}
                    />
                )}
            </div>
        </div>
        <div className='network-course-background'>
            <div className='backend-course-bgimg'>
                <div className="App">
                    <AnimateText text="BACKEND " />
                    <AnimateText text="DEVELOPMENT" />
                </div>
                <Link to="/RegularTrainingRegistration" className='apply-now-link'>
                    <div className='course-apply-now' ref={containerRef1}> <button>Apply now</button> 
                        <img
                            src={arrow_down}
                            width="35px"
                            height="35px"
                            alt='icon'
                        /> 
                    </div>                
                </Link>
            </div>
        </div>
        <div className='backend-sys-course-content'>
            <p>Course details</p>
            <h1>Everything you need to kick-start your journey</h1>
            <div className='backend-sys-price-content'>
                <div className='backend-sys-price-details'>
                    <h1>75<span>k</span></h1>
                    <span>Price(Regular)</span>
                </div>
                <div className='backend-sys-price-details'>
                    <h1>54<span>k</span></h1>
                    <span>Price(IT)</span>
                </div>
                <div className='backend-sys-price-details'>
                    <h1>3<span>:</span>4</h1>
                    <span>Months:hrs per week</span>
                </div>
            </div>
            <div className='backend-sys-course-about'>
                <h1>Overview</h1>
                <p>
                    Congratulations on embarking on the comprehensive backend Software Engineering course using the C++ programming language. This course, designed with no prerequisites, is an ideal starting point for absolute beginners keen on venturing into the realm of backend systems development. Throughout the course, you will gain a solid foundation in C++ programming and delve into the intricacies of backend software engineering.

                </p>
                <p>
                    You'll learn the essential concepts and techniques used in backend system development. Through hands-on projects and real-world applications, you'll gain practical experience in designing backend systems.
                </p>
            </div>
            <div className='ui-ux-background-image'>
                <img
                    src={backendbground}
                    width="100%"
                    height="100%"
                    alt='icon'
                    className='course-background-attract'
                />
            </div>
            <div className='backend-course-highlights'>
                <h1>Course Highlights</h1>
                <p>
                    You are about to embark on an exciting journey of 
                    learning how to program. For the duration of this course 
                    you will be learning;
                </p>
                <div className='backend-course-content-highlight'>
                    <div className='course-content-ux'>
                        <img
                            src={backendcheck}
                            alt='icon'
                            width="25px"
                            height="20px"
                        />
                        <p>The web & The internet</p>
                    </div>
                    <div className='course-content-ux'>
                        <img
                            src={backendcheck}
                            alt='icon'
                            width="25px"
                            height="20px"
                        />
                        <p>Javascript Fundamentals</p>
                    </div>
                    <div className='course-content-ux'>
                        <img
                            src={backendcheck}
                            alt='icon'
                            width="25px"
                            height="20px"
                        />
                        <p>JavaScript Arrays & Objects, Loops, Functions</p>
                    </div>
                    <div className='course-content-ux'>
                        <img
                            src={backendcheck}
                            alt='icon'
                            width="25px"
                            height="20px"
                        />
                        <p>Introduction to NodeJS, More Objects</p>
                    </div>
                    <div className='course-content-ux'>
                        <img
                            src={backendcheck}
                            alt='icon'
                            width="25px"
                            height="20px"
                        />
                        <p>Maps, Sets, JavaScript OOP</p>
                    </div>
                    <div className='course-content-ux'>
                        <img
                            src={backendcheck}
                            alt='icon'
                            width="25px"
                            height="20px"
                        />
                        <p>NPM Deep Dive</p>
                    </div>
                    <div className='course-content-ux'>
                        <img
                            src={backendcheck}
                            alt='icon'
                            width="25px"
                            height="20px"
                        />
                        <p>Understanding REST APIs</p>
                    </div>
                    <div className='course-content-ux'>
                        <img
                            src={backendcheck}
                            alt='icon'
                            width="25px"
                            height="20px"
                        />
                        <p>Introduction to NoSQL, Firebase & MongoDB</p>
                    </div>
                    <div className='course-content-ux'>
                        <img
                            src={backendcheck}
                            alt='icon'
                            width="25px"
                            height="20px"
                        />
                        <p>MongoDB Database Modeling</p>
                    </div>
                    <div className='course-content-ux'>
                        <img
                            src={backendcheck}
                            alt='icon'
                            width="25px"
                            height="20px"
                        />
                        <p>Security, Deployment & Backup and Restore</p>
                    </div>
                    <div className='course-content-ux'>
                        <img
                            src={backendcheck}
                            alt='icon'
                            width="25px"
                            height="20px"
                        />
                        <p>Above and Beyond</p>
                    </div>
                    <div className='course-content-ux'>
                        <img
                            src={backendcheck}
                            alt='icon'
                            width="25px"
                            height="20px"
                        />
                        <p>Performance optimization, Payment gateway and Mailing </p>
                    </div>
                    <div className='course-content-ux'>
                        <img
                            src={backendcheck}
                            alt='icon'
                            width="25px"
                            height="20px"
                        />
                        <p>Testing and Debugging</p>
                    </div>
                    <div className='course-content-ux'>
                        <img
                            src={backendcheck}
                            alt='icon'
                            width="25px"
                            height="20px"
                        />
                        <p>NodeJS MySQL Connectivity</p>
                    </div>
                </div>
            </div>
            <div className='ui-ux-background-image'>
                <img
                    src={embededpic1}
                    width="100%"
                    height="100%"
                    alt='icon'
                    className='course-background-attract'
                />
            </div>
            <div className='backend-course-highlights1'>
                <div className='backend-course-highlights'>
                    <h1>
                    Learning Outcome
                    </h1>
                    <p>Upon completion, graduates will be able to:</p>
                    <div className='backend-course-content-highlight'>
                        <div className='course-content-ux'>
                            <img
                                src={backendcheck}
                                alt='icon'
                                width="25px"
                                height="20px"
                            />
                            <p>Understand and utilize JavaScript for web development</p>
                        </div>
                        <div className='course-content-ux'>
                            <img
                                src={backendcheck}
                                alt='icon'
                                width="25px"
                                height="20px"
                            />
                            <p>Develop applications using NodeJS and advanced JavaScript concepts</p>
                        </div>
                        <div className='course-content-ux'>
                            <img
                                src={backendcheck}
                                alt='icon'
                                width="25px"
                                height="20px"
                            />
                            <p>Work with NoSQL databases like Firebase and MongoDB</p>
                        </div>
                        <div className='course-content-ux'>
                            <img
                                src={backendcheck}
                                alt='icon'
                                width="25px"
                                height="20px"
                            />
                            <p>Implement security measures, optimize performance, and conduct testing</p>
                        </div>
                    </div>
                </div>
                <div className='ui-ux-course-content-highlight'>
                    <div className='course-content-ux1'>
                        <p>Establish connectivity with MySQL databases using NodeJS.</p>
                        <img
                            src={backendapply}
                            alt='icon'
                            width="70px"
                            height="70px"
                            className='checkmark-apply'
                            onClick={openPopup}
                        />
                    </div>
                </div>
            </div>
        </div>
        <div className='experience-story'>
            <h1>The Experience</h1>
            <p>Don't just take our word for it! Hear what our past students have to say about their experience</p>
            <div className='testimonial-main-container'>
                <div className='testimonial-sub-main-container'>
                    <button className='testimonial-btn' onClick={() => handleScroll('left')}>
                        <img 
                            src={previous} 
                            width="100%" 
                            height="100%" 
                            alt='next'                             
                        />
                    </button>
                    <div className='testimonial-container' ref={containerRef}>
                        {testimonials.map((testimonial, index) => (
                            <div className='testimonial-story' key={index}>
                                <div className='testimonial-text'>
                                    <h1>{testimonial.text}</h1>
                                    <div className='testimonial-personal'>
                                    <h2>{testimonial.name}</h2>
                                    <p>{testimonial.class}</p>
                                    </div>
                                </div>
                                <div className='testimonial-image'>
                                    <img
                                    src={testimonial.image}
                                    width="100%"
                                    height="100%"
                                    alt='testimonial'
                                    className='testy-image'
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                    <button className='testimonial-btn' onClick={() => handleScroll('right')}>
                        <img 
                            src={next} 
                            width="100%" 
                            height="100%" 
                            alt='next'                             
                        />
                    </button>
                    <div className='testimonial-btn-prt-ctn'>
                        <button className='testimonial-btn1' onClick={() => handleScroll('left')}>
                            <img 
                                src={previous} 
                                width="100%" 
                                height="100%" 
                                alt='next'                             
                            />
                        </button>
                        <button className='testimonial-btn1' onClick={() => handleScroll('right')}>
                            <img 
                                src={next} 
                                width="100%" 
                                height="100%" 
                                alt='next'                             
                            />
                        </button>
                    </div>
                </div>
                <div className='testimonial-btn-container2'>
                    {testimonials.map((_, index) => (
                    <button
                        key={index}
                        className={`indicator-btn ${index === currentIndex ? 'active' : ''}`}
                        onClick={() => setCurrentIndex(index)}
                    >
                        <span className="indicator" />
                    </button>
                    ))}
                </div>
            </div>
            <div className='call-course-enquiries'>
                <h1>For More Inquiries</h1>
                    <div className='contact-course-container'>
                    <div className='contact-course1'>
                        <div className='call-course-image'>
                            <img
                                src={backendchat}
                                width="100%"
                                height="100%"
                                alt='icon'
                            />
                        </div>
                        <h3>chat</h3>
                        <p>deebug.linkedin</p>
                    </div>
                    <div className='contact-course1'>
                        <div className='call-course-image'>
                            <img
                                src={backendcall}
                                width="100%"
                                height="100%"
                                alt='icon'
                            />
                        </div>
                        <h3>Call</h3>
                        <p>+2349162627333</p>
                    </div>
                    <div className='contact-course1'>
                        <div className='call-course-image'>
                            <img
                                src={backendletter}
                                width="100%"
                                height="100%"
                                alt='icon'
                            />
                        </div>
                        <h3>Email</h3>
                        <p>Info@deebug.org</p>
                    </div>
                </div>
            </div>
            <div className='course-inquiries'>
                <h1>Learn more about education <br /> at Deebug</h1>
                <div className="edu-learn-btn"><Link to="/IndustrialTrainingCourses" className="section_learn_more"> Go to Industrial Training Overview</Link><img src={ChevronRight} className="learn_more_icon" /></div>
                <div className="edu-learn-btn"><Link to="/RegularStudents" className="section_learn_more"> Go to Regular Students</Link><img src={ChevronRight} className="learn_more_icon" /></div>
            </div>
        </div>
    </div>
  )
}

export default Backend