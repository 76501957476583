import React from 'react';
import './heurekka.css';
import heurekka_vid from "../../videos/heurekka_vid.mp4";
import heurekkaspot from "../../images/heurekkaspot.png";
import heurekkapixel from "../../images/heurekkapixel.webp"
import heurekkapixel2 from "../../images/heurekkapixel2.webp"
import heurekkaplat from "../../images/heurekkaplat.webp"
import heurekkaplat2 from "../../images/heurekkaplat2.webp"
import heurekkaplat3 from "../../images/heurekkaplat3.webp"
import heurekkaget1 from "../../images/heurekkaget1.webp"
import heurekkaget2 from "../../images/heurekkaget2.webp"
import heurekkaget3 from "../../images/heurekkaget3.webp"
import googleplay from "../../images/googleplay.png"
import appstore from "../../images/appstore.png"
import feature1 from "../../images/feature1.png";
import feature2 from "../../images/feature2.png";
import feature3 from "../../images/feature3.png";
import feature4 from "../../images/feature4.png";

const Heurekka = () => {
  return ( 
    <div className='heurekka-main'>
        <div className='heurekka-title'>
            <h1>Heurekka</h1>
            <p>
                A safe space for developers and designers to connect, 
                share ideas and resources and find tech jobs
            </p>
        </div>
        <div className="heurekka-vid">
            <video autoPlay loop muted className="heurekka-vid-style">
                <source src={heurekka_vid} type="video/mp4" />
            </video>
        </div>
        <div  className='heurekka-spotlight'>
            <h1>Spotlight</h1>
            <div className='spotlight-content'>
                <div className='spotlight-pic'>
                    <img
                        src={heurekkaspot}
                        width="100%"
                        height="100%"
                        alt='icon'
                    />
                </div>
                <h2>Connect with others who share your passions and interests.</h2>
            </div>
            <h1>The Experience</h1>
            <div className='heurekka-share'>
                <div className='heurekka-share-content'>
                    <h1>Find your community, <br/> Connect and share your ideas</h1>
                    <p>Don't just chat, connect. This goes beyond simple messaging. It's an opportunity to find your community, share your ideas and passions, and thrive in a space built around what interests you.</p>
                    <div className='heurekka-share-image'>
                        <img
                            src={heurekkapixel}
                            width="100%"
                            height="100%"
                            alt='icon'
                        />
                    </div>
                </div>
                <div className='heurekka-share-content2'>
                    <p>
                        Build a community around your interests. <span>Create your own 
                        channel</span> to share ideas, host discussions, and connect with like-minded people.
                    </p>
                    <h2>
                        Explore a <span>variety of channels</span> covering diverse topics. 
                        Find your niche and connect with others who share your enthusiasm.
                    </h2>
                    <div className='heurekka-share-content2-props'>
                        <h1>Never miss a bit of the conversation</h1>
                        <p>Pin important channels for easy access.</p>
                    </div>
                </div>
                <div className='heurekka-share-content3'>
                    <div className='heurekka-share-image1'>
                        <img
                            src={heurekkapixel2}
                            width="100%"
                            height="100%"
                            alt='icon'
                        />
                    </div>
                    <div className='heurekka-share-content3-text'>
                        <p>
                            Send <span>private messages</span> to your friends. Collaborate on projects, 
                            discuss assignments, Share updates, or bounce ideas off each other 
                            in a private space.
                        </p>
                        <p>
                            <span>Making chatting fun</span> liven up your conversations 
                            with a burst of emotions. React to messages with a fun selection 
                            of emojis to add personality and express yourself in a fun way.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div className='heurekka-platform'>
            <h1>Use across multiple <span>Devices.</span></h1>
            <p>
                Enjoy the <span>flexibility</span> of accessing Heurekka on <span>multiple 
                devices.</span> Whether you're on your phone or PC, our user-friendly 
                interface ensures a consistent and seamless experience.
            </p>
            <div className='heurekka-platform-images'>
                <div className='heurekka-plat-img'>
                    <img
                        src={heurekkaplat}
                        width="100%"
                        height="100%"
                        alt='icon'
                    />
                </div>
                <div className='heurekka-plat-img1'>
                    <img
                        src={heurekkaplat2}
                        width="100%"
                        height="100%"
                        alt='icon'
                    />
                </div>
                <div className='heurekka-plat-img2'>
                    <img
                        src={heurekkaplat3}
                        width="100%"
                        height="100%"
                        alt='icon'
                    />
                </div>
            </div>
            <h2>
                Stay connected with Heurekka's <span>versatile accessibility</span> across various devices.
                Whether you're on the go with your smartphone or comfortably seated at your PC, 
                our intuitive user interface guarantees a <span>consistent</span> and <span>effortless experience.</span> 
                Transition effortlessly between devices, maintaining seamless connectivity and 
                control to suit your preferences and lifestyle.
            </h2>
        </div>
        <div className='heurekka-amazing-features'>
            <h1>Other amazing features</h1>
            <div className='heurekka-features-ctn'>
                <div className='heurekka-features-prop'>
                    <img
                        src={feature1}
                        width="90px"
                        height="80px"
                        alt='icon'
                    />
                    <h3>
                        <span>Network</span> connect with a vibrant community of developers, 
                        designers, and creatives, to share ideas, collaborate on projects, 
                        and grow together.
                    </h3>
                    <button>Coming soon</button>
                </div>
                <div className='heurekka-features-prop'>
                    <img
                        src={feature2}
                        width="90px"
                        height="80px"
                        alt='icon'
                    />
                    <h3>
                        <span>Hackathon</span> Push your creativity to the limit 
                        by joining various competitions. Compete alongside the best, solve 
                        real-world problems, and showcase your innovative solutions.
                    </h3>
                    <button>Coming soon</button>
                </div>
                <div className='heurekka-features-prop'>
                    <img
                        src={feature3}
                        width="90px"
                        height="80px"
                        alt='icon'
                    />
                    <h3>
                        <span>Polls</span> ask any tech-related question and receive a variety 
                        of answers from the community. Learn from diverse perspectives, and stay
                        ahead of the tech curve.
                    </h3>
                    <button>Coming soon</button>
                </div>
                <div className='heurekka-features-prop'>
                    <img
                        src={feature4}
                        width="90px"
                        height="80px"
                        alt='icon'
                    />
                    <h3>
                        <span>Collaborate</span> find your perfect tech job through Heurekka. 
                        Showcase your skills and connect with potential employers or companies.
                    </h3>
                    <button>Coming soon</button>
                </div>
            </div>
        </div>
        <div className='heurekka-design'>
            <h1>Designed to be Different</h1>
            <p>
                Heurekka stands out as a <span> beacon of meaningful impact.</span> We've crafted every 
                feature with a singular purpose to make a positive difference. Enhancing your 
                experience, and productivity.
            </p>
        </div>
        <div className='heurekka-download'>
            <div className='heurekka-download-details'>
                <h1>Get Heurekka</h1>
                <p>Heurekka is available both on apple store and google play store</p>
                <div className='download-heurekka-ctn'>
                    <button className='download-heurekka-btn'>
                        <img
                            src={googleplay}
                            width="100%"
                            height="100%"
                            alt='icon'
                        />
                    </button>
                    <button className='download-heurekka-btn'>
                        <img
                            src={appstore}
                            width="100%"
                            height="100%"
                            alt='icon'
                        />
                    </button>
                </div>
            </div>
            <div className='heurekka-download-platform'>
                <div className='heurekka-download-img'>
                    <img
                        src={heurekkaget1}
                        width="100%"
                        height="100%"
                        alt='icon'
                    />
                </div>
                <div className='heurekka-download-img1'>
                    <img
                        src={heurekkaget2}
                        width="100%"
                        height="100%"
                        alt='icon'
                    />
                </div>
                <div className='heurekka-download-img2'>
                    <img
                        src={heurekkaget3}
                        width="100%"
                        height="100%"
                        alt='icon'
                    />
                </div>
            </div>                    
        </div>
    </div>
  )
}

export default Heurekka