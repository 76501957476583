import React, { useState } from "react";
import "./events.css";
import eventpic2 from "../../images/eventpic2.webp";
import awardbground from "../../images/awardbground.webp";
import Exclude from "../../images/Exclude.png";
import ChevronRight from "../../images/chevron_right_blue.png";
import eventpast from "../../images/standard3.webp";
import eventpast1 from "../../images/eventpast1.webp";
import eventpast2 from "../../images/eventpast2.webp";
import blueplay from "../../images/blueplay.png";
import previousblk from "../../images/previousblk.png";
import nextblk from "../../images/nextblk.png";
import Award_Night from "../award_night/Award_Night";
import Game_Tournament from "../game_tournament/Game_Tournament";

const Events = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen1, setIsModalOpen1] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openModal1 = () => {
    setIsModalOpen1(true);
  };

  const closeModal1 = () => {
    setIsModalOpen1(false);
  };

  const handleScroll = (direction) => {
    const container = document.querySelector(".past-event-container");
    const scrollWidth = container.scrollWidth;
    const containerWidth = container.clientWidth;
    const scrollStep = containerWidth / 2;

    if (direction === "left") {
      container.scrollLeft -= scrollStep;
    } else {
      container.scrollLeft += scrollStep;
    }
    setScrollPosition(container.scrollLeft);
  };

  return (
    <div className="event-main">
      <div className="event-background">
        <div className="event-video">
          <img src={Exclude} width="100%" height="100%" alt="icon" />
        </div>
        <h1>Deebug Event</h1>
        <p>
          Introducing the ultimate showdown! An epic Game Tournament where
          players from all walks of gaming unite for a day of fierce
          competition, camaraderie, and unbeatable fun.
        </p>
      </div>
      <div className="events-main-container">
        <div className="event-content">
          <h1>Upcoming Events</h1>
          <div className="events-main-container">
            <div className="event-content-child">
              <div className="event-child-image">
                <img
                  src={eventpic2}
                  height="100%"
                  width="100%"
                  alt="icon"
                  className="event-child-icon"
                />
              </div>
              <div className="event-child-title">
                <h1>Esport Tournament</h1>
                <p>
                  A heart-pounding gaming event, where skill, strategy, and
                  passion collide. Whether you're a seasoned player or a rising
                  star, it is an electrifying stage to showcase your gaming
                  prowess. its nor just a tournament is a celebration of the
                  gaming spirit
                </p>
                <div className="event-learn-btn">
                  <p onClick={openModal1} className="section_learn_more">
                    {" "}
                    Learn More
                  </p>
                  <img src={ChevronRight} className="learn_more_icon" />
                </div>
                {isModalOpen1 && <Game_Tournament closeModal={closeModal1} />}
              </div>
            </div>
            <div className="event-content-child">
              <div className="event-child-image">
                <img
                  src={awardbground}
                  height="100%"
                  width="100%"
                  alt="icon"
                  className="event-child-icon"
                />
              </div>
              <div className="event-child-title">
                <h1>2024 Dinner & Award Ceremony</h1>
                <p>
                  A night dedicated to celebrating the innovation, dedication,
                  and achievements that powered another incredible year.
                </p>
                <div className="event-learn-btn">
                  <p onClick={openModal} className="section_learn_more">
                    {" "}
                    Learn More
                  </p>
                  <img src={ChevronRight} className="learn_more_icon" />
                </div>
                {isModalOpen && <Award_Night closeModal={closeModal} />}
              </div>
            </div>
          </div>
        </div>
        <div className="past-event">
          <h1>Recent Events</h1>
          <div className="past-event-container">
            <div className="past-event-child">
              <div className="past-event-image">
                <img src={eventpast} width="100%" height="100%" alt="icon" />
              </div>
              <div className="past-event-summary">
                <h1>IT Week</h1>
                <p>August 10, 2024</p>
                <p>
                  An exhilarating week-long event where students where students
                  participate in diverse activities, from game day to
                  hackathons, career expeditions, and innovation showcases.
                </p>
                {/* <div className="event-learn-btn"><a className="section_learn_more" href="/CareerExpedition"> Watch Highlights</a><img src={blueplay} className="learn_more_icon" /></div> */}
              </div>
            </div>
            <div className="past-event-child">
              <div className="past-event-image">
                <img src={eventpast1} width="100%" height="100%" alt="icon" />
              </div>
              <div className="past-event-summary">
                <h1>Game Day</h1>
                <p>August 10, 2023</p>
                <p>
                  An unforgettable event where gamers showcased their gaming
                  skills, creating an electrifying atmosphere of camaraderie and
                  friendly rivalry.
                </p>
                {/* <div className="event-learn-btn"><a className="section_learn_more" href="/gaming"> Watch Highlights</a><img src={blueplay} className="learn_more_icon" /></div> */}
              </div>
            </div>
            <div className="past-event-child">
              <div className="past-event-image">
                <img src={eventpast2} width="100%" height="100%" alt="icon" />
              </div>
              <div className="past-event-summary">
                <h1>2023 Dinner & Award Ceremony</h1>
                <p>December 10, 2023</p>
                <p>
                  A night dedicated to celebrating the innovation, dedication,
                  and achievements that powered another incredible year.
                </p>
                {/* <div className="event-learn-btn"><a className="section_learn_more" href="/events"> Watch Highlights</a><img src={blueplay} className="learn_more_icon" /></div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="testimonial-btn-container">
        <button
          className="testimonial-btn"
          onClick={() => handleScroll("left")}
        >
          <img src={previousblk} width="100%" height="100%" alt="icon" />
        </button>
        <button
          className="testimonial-btn"
          onClick={() => handleScroll("right")}
        >
          <img src={nextblk} width="100%" height="100%" alt="icon" />
        </button>
      </div>
    </div>
  );
};

export default Events;
