// endpoint to parse phone number
const phoneNumberParser = (phone_no) => {
    // Convert the input to a string in case it's not already
    phone_no = phone_no.toString().trim();

    // Check if the number starts with '0' and remove it
    if (phone_no.startsWith('0')) {
        phone_no = phone_no.slice(1);
    }

    // Prepend the country code "+234"
    return "+234" + phone_no;
};

module.exports = { phoneNumberParser };