import React, {useState, useEffect, useRef} from 'react';
import "./bootcamppage.css";
import bootcert1 from "../../images/bootcert1.webp";
import bootcert2 from "../../images/bootcert2.webp";
import bootcert3 from "../../images/regulargrad.png";
import bootcamppage from "../../images/bootcamppage.webp";
import hmbbtn from "../../images/hmbbtn.png";
import bootreact from "../../images/bootreact.png";
import bootfigma from "../../images/bootfigma.png";
import bootembedded from "../../images/bootembedded.png";
import bootjs from "../../images/bootjs.png";
import bootflutter from "../../images/bootflutter.png";
import bootui from "../../images/bootui.png";
import { fontSize } from '@mui/system';

const Bootcamp_Page = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
      setIsOpen(!isOpen);
    };

   
    const headingRef = useRef(null);
    const headingRef1 = useRef(null);
    const headingRef2 = useRef(null);
    const [isInView, setIsInView] = useState(false);
    const [isInView1, setIsInView1] = useState(false);
    const [isInView2, setIsInView2] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setIsInView(true);
                    } else {
                        setIsInView(false);
                    }
                });
            },
            { threshold: 0.1 } // Adjust the threshold as needed
        );

        if (headingRef.current) {
            observer.observe(headingRef.current);
        }

        return () => {
            if (headingRef.current) {
                observer.unobserve(headingRef.current);
            }
        };
    }, []);

    useEffect(() => {
        const observer1 = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setIsInView1(true);
                    } else {
                        setIsInView1(false);
                    }
                });
            },
            { threshold: 0.1 } // Adjust the threshold as needed
        );

        if (headingRef1.current) {
            observer1.observe(headingRef1.current);
        }

        return () => {
            if (headingRef1.current) {
                observer1.unobserve(headingRef1.current);
            }
        };
    }, []);

    useEffect(() => {
        const observer2 = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setIsInView2(true);
                    } else {
                        setIsInView2(false);
                    }
                });
            },
            { threshold: 0.1 } // Adjust the threshold as needed
        );

        if (headingRef2.current) {
            observer2.observe(headingRef2.current);
        }

        return () => {
            if (headingRef2.current) {
                observer2.unobserve(headingRef2.current);
            }
        };
    }, []);


const splitText = (text) => {
    return text.split('').map((char, index) => (
        <span key={index}> {char} </span>
    ));
};

      

  return (
    <div className='bootcamp-page'>
        <div className='bootcamp-page-topbar'>
            <h1>Bootcamp</h1>
            <ul className={isOpen ? "bootcamp-page-menu active" : "bootcamp-page-menu"}>
                <li> <a href='/BootcampPage' className='bootcamp-page-link'>Overview</a></li>
                <li> <a href='/maintenance' className='bootcamp-page-link'>Courses</a></li>
                <li> <a href='/maintenance' className='bootcamp-page-link'>Apply</a></li>
            </ul>
            <div className="bootcamp-page-ham-btn">
                <img 
                    src={hmbbtn}
                    width="100%"
                    height="100%"
                    alt='icon'
                    onClick={toggleMenu}
                />
            </div>
        </div>
        <div className='bootcamp-page-title'>
            <div className='boot-camp-bgground-ctn-1'>
                <div className='boot-camp-bgground1'>
                    <img 
                        src={bootreact}
                        width="100%"
                        height="100%"
                        alt='icon'
                    />
                </div>
                <div className='boot-camp-bgground2'>
                    <img 
                        src={bootfigma}
                        width="100%"
                        height="100%"
                        alt='icon'
                    />
                </div>
                <div className='boot-camp-bgground3'>
                    <img 
                        src={bootui}
                        width="100%"
                        height="100%"
                        alt='icon'
                    />
                </div>
            </div>
            <div className='boot-camp-bgground-ctn-2'>
            
                <h1 ref={headingRef} className={`animated-heading ${isInView ? 'animate' : ''}`}>
                    Learn{' '}
                    <span className={`bootcamp-yellow ${isInView ? 'animate' : ''}`}>
                        {splitText(' Everything ')}
                    </span>{' '} <br/>
                    you{' '}
                    <span className={`bootcamp-underline ${isInView ? 'animate' : ''}`}>
                        Need.
                    </span>
                </h1>

                <p>
                    We believe in providing you with precisely what you 
                    need to kickstart your career journey. We equip you with 
                    the essential skills and knowledge required in the professional 
                    landscape. Experience hands-on training, engage with industry-relevant 
                    content, and emerge ready to take on new challenges. 
                </p>
            </div>
            <div className='boot-camp-bgground-ctn-1'>
                <div className='boot-camp-bgground4'>
                    <img 
                        src={bootflutter}
                        width="100%"
                        height="100%"
                        alt='icon'
                    />
                </div>
                <div className='boot-camp-bgground5'>
                    <img 
                        src={bootjs}
                        width="100%"
                        height="100%"
                        alt='icon'
                    />
                </div>
                <div className='boot-camp-bgground6'>
                    <img 
                        src={bootembedded}
                        width="100%"
                        height="100%"
                        alt='icon'
                    />
                </div>
            </div>
        </div>
        <div className='bootcamp-page-content'>
            <div className='bootcamp-page-content-text'>
                <h1>Equal Opportunity for All</h1>
                
                <p ref={headingRef1} className={`animated-heading1 ${isInView1 ? 'animate1' : ''}`}>
                    We strive to break down barriers and create a level 
                    playing field for everyone aspiring to enter the tech 
                    profession. We believe in providing equal opportunities, 
                    <span className={`bootcamp-red ${isInView1 ? 'animate1' : ''}`}>
                        without cost constraints.
                    </span>
                </p>
            </div>
            <div className='bootcamp-page-content-text'>
                <h1 ref={headingRef2} className={`animated-heading2 ${isInView2 ? 'animate2' : ''}`}>
                    <span className={`bootcamp-blue ${isInView2 ? 'animate2' : ''}`}>
                       What you'll gain
                    </span>{' '}
                    during this program
                </h1>
                <p>
                    With our comprehensive curriculum, you'll be 
                    equipped with the right knowledge and skills 
                    needed to confidently thrive your chosen field. 
                    You will also gain
                </p>            
                <h2>24/7 Mentorship. </h2>
                <div className='bootcamp-page-img'>
                    <img
                        src={bootcert1}
                        width="100%"
                        height="100%"
                        alt='icon'
                    />
                </div>
                <p>
                    Our experienced instructors are dedicated to supporting your 
                    journey, offering insights, and helping you overcome.
                </p>          
                <h2>Hand on Experience </h2>
                <div className='bootcamp-page-img'>
                    <img
                        src={bootcert2}
                        width="100%"
                        height="100%"
                        alt='icon'
                    />
                </div>
                <p>
                    The bootcamp is designed to provide you with real-world scenarios, 
                    ensuring you gain the practical skills and confidence needed in your chosen field. 
                </p>          
                <h2>Certification</h2>
                <div className='bootcamp-page-img'>
                    <img
                        src={bootcert3}
                        width="100%"
                        height="100%"
                        alt='icon'
                    />
                </div>
                <p>
                    Upon successful completion of the bootcamp, you'll receive a 
                    certificate validating your skill & opening doors to 
                    new opportunities  for you.
                </p>
            </div>
        </div>
        <div className='bootcamp-students'>
            <img
                src={bootcamppage}
                width="100%"
                height="100%"
                alt='icon'
            />
        </div>
        {/* <div className='bootcamp-success'>
            <h1>Rise to Excellence</h1>
            <p>
                At Deebug, we believe in launching successful careers, 
                not just completing programs.  That's why you won't be doing it 
                alone. We'll provide the support you need to not just start your 
                journey, but thrive and climb to the top of your chosen field.
            </p>
            <button>Explore success story</button>
        </div> */}
        <div className='bootcamp-success'>
            <h1>Join the next Bootcamp class</h1>
            <p>
                Are you ready to immerse yourself in a transformative 
                journey of learning? Don't miss out on the chance to be 
                part of a community dedicated to continuous growth and success.
            </p>
            <button>Applications Closed for 2024</button>
        </div>
    </div>
  )
}

export default Bootcamp_Page