import React, { useEffect, useState } from "react";
import "./NavBar.css";
import { useWindowWidth, useWindowHeight } from "@react-hook/window-size";
import Logo from "../../images/logo.png";
import LogoWhite from "../../images/logo_white.png";
import SearchIconW from "../../images/search_icon_white.png";
import SearchIconB from "../../images/search_icon_dark.png";
import MenuIcon from "../../images/menu_icon.png";
import menublack from "../../images/menublack.png";
import menuwhite from "../../images/menuwhite.png";
import ChevronRight from "../../images/chevron_right.png";
import ChevronLeft from "../../images/chevron_left.png";
import Close from "../../images/close.png";
import ArrowIcon from "../../images/arrow_icon.png";
import MegaMenuEngineering from "./MegaMenuEngineering";
import MegaMenuEducation from "./MegaMenuEducation";
import MegaMenuHealthcare from "./MegaMenuHealthcare";
import MegaMenuGaming from "./MegaMenuGaming";
import MegaMenuSearch from "./MegaMenuSearch";
import { useNavigate, useLocation } from "react-router-dom";

export default function NavBar() {
  const navigate = useNavigate();

  let [mobile, setMobile] = useState(false);
  const [navItem, setNavItem] = useState("close");

  const location = useLocation();

  const width = useWindowWidth();
  useEffect(() => {
    if (width <= 767) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, [width]);

  // to handle searching
  const [searchString, setSearchString] = useState("");

  const handleSearch = (e) => {
    e.preventDefault();
    setSearchString(e.target.value);
  };
  // console.log(`${location.pathname}`);

  const use_white =
    location.pathname === "/IndustrialTraining" ||
    location.pathname === "/RegularStudents" ||
    location.pathname === "/engineering" ||
    location.pathname === "/bootcamp" ||
    location.pathname === "/hackathon" ||
    location.pathname === "/Heurekka" ||
    location.pathname === "/Healthcare" ||
    location.pathname === "/allcourses" ||
    location.pathname === "/FrontendDevelopment" ||
    location.pathname === "/BackendDevelopment" ||
    location.pathname === "/EmbeddedSystems" ||
    location.pathname === "/MobileAppDevelopment" ||
    location.pathname === "/FullstackWebDevelopment" ||
    location.pathname === "/productdesign" ||
    location.pathname === "/DataScience" ||
    location.pathname === "/CyberSecurity" ||
    location.pathname === "/FullstackProductDesign" ||
    location.pathname === "/FullstackMobileApp" ||
    location.pathname === "/Bootcamp/BackendDevelopment" ||
    location.pathname === "/Bootcamp/FrontendDevelopment" ||
    location.pathname === "/Bootcamp/EmbeddedSystems" ||
    location.pathname === "/Bootcamp/MobileAppDevelopment" ||
    location.pathname === "/Bootcamp/FullstackWebDevelopment" ||
    location.pathname === "/Bootcamp/FullstackProductDesign" ||
    location.pathname === "/Bootcamp/FullstackMobileApp" ||
    location.pathname === "/Bootcamp/ProductDesign" ||
    location.pathname === "/Bootcamp/DataScience" ||
    location.pathname === "/Bootcamp/CyberSecurity" ||
    location.pathname === "/IndustrialTraining/BackendDevelopment" ||
    location.pathname === "/IndustrialTraining/FrontendDevelopment" ||
    location.pathname === "/IndustrialTraining/EmbeddedSystems" ||
    location.pathname === "/IndustrialTraining/MobileAppDevelopment" ||
    location.pathname === "/ComputerNetworking" ||
    location.pathname === "/IndustrialTraining/FullstackWebDevelopment" ||
    location.pathname === "/IndustrialTraining/FullstackProductDesign" ||
    location.pathname === "/IndustrialTraining/FullstackMobileApp" ||
    location.pathname === "/IndustrialTraining/ProductDesign" ||
    location.pathname === "/IndustrialTraining/DataScience" ||
    location.pathname === "/IndustrialTraining/CyberSecurity" ||
    location.pathname === "/EngineeringServices" ||
    location.pathname === "/GameTournament" ||
    location.pathname === "/explore-gaming" ||
    location.pathname === "/gaming" ||
    location.pathname === "/events" ||
    location.pathname === "/IndustrialTrainingCourses" ||
    location.pathname === "/AwardNight" ||
    location.pathname === "/allcourses"
      ? true
      : false;



  const navbar_bg_color =
    location.pathname === "/education"
      ? "white"
      : location.pathname === "/IndustrialTraining" ||
        location.pathname === "/bootcamp" ||
        location.pathname === "/Healthcare" ||
        location.pathname === "/hackathon"
      ? "#101010"
      : location.pathname === "/RegularStudents"
      ? "rgba(16, 16, 16, 0.9)"
      : location.pathname === "/" ||
        location.pathname === "/maintenance" ||
        location.pathname === "/deebug_leadership" ||
        location.pathname === "/deebug_leadership_view" ||
        location.pathname === "/contact" ||
        location.pathname === "/ITregistration" ||
        location.pathname === "/StandardCourses" ||
        location.pathname === "/Medplan" ||
        location.pathname === "/naijapharm" ||
        location.pathname === "/RegularTrainingRegistration" ||
        location.pathname === "/BootcampRegistration" ||
        location.pathname === "/Ethics" ||
        location.pathname === "/ThirdParties" ||
        location.pathname === "/frontend-web-development" ||
        location.pathname === "/backend-development" ||
        location.pathname === "/ux-ui-design" ||
        location.pathname === "/flutter-app-development" ||
        location.pathname === "/fullstack-web-development" ||
        location.pathname === "/BootcampPage"
      ? "rgba(249, 249, 249, 1)"
      : location.pathname === "/CareerExpedition" ||
        location.pathname === "/explore-course" ||
        location.pathname === "/InnovativeShowcase"
      ? "rgba(249, 249, 249, 1)"
      : "rgb(16, 16, 16, 0.9)";
      

  return mobile ? (
    <div
      className="navbar_container"
      style={{
        position:
          location.pathname === "/" ||
          location.pathname === "/allcourses" ||
          location.pathname === "/maintenance" ||
          location.pathname === "/BootcampPage" ||
          location.pathname === "/contact" ||
          location.pathname === "/Healthcare" ||
          location.pathname === "/engineering" ||
          location.pathname === "/Healthcare" ||
          location.pathname === "/Heurekka" ||
          location.pathname === "/allcourses" ||
          location.pathname === "/EmbeddedSystems" ||
          location.pathname === "/education" ||
          location.pathname === "/BackendDevelopment" ||
          location.pathname === "/FullstackProductDesign" ||
          location.pathname === "/InnovativeShowcase" ||
          location.pathname === "/EngineeringServices" ||
          location.pathname === "/GameTournament" ||
          location.pathname === "/gaming" ||
          location.pathname === "/FrontendDevelopment" ||
          location.pathname === "/IndustrialTraining" ||
          location.pathname === "/RegularStudents" ||
          location.pathname === "/engineering" ||
          location.pathname === "/bootcamp" ||
          location.pathname === "/hackathon" ||
          location.pathname === "/Heurekka" ||
          location.pathname === "/Healthcare" ||
          location.pathname === "/allcourses" ||
          location.pathname === "/FrontendDevelopment" ||
          location.pathname === "/BackendDevelopment" ||
          location.pathname === "/EmbeddedSystems" ||
          location.pathname === "/MobileAppDevelopment" ||
          location.pathname === "/FullstackWebDevelopment" ||
          location.pathname === "/productdesign" ||
          location.pathname === "/DataScience" ||
          location.pathname === "/CyberSecurity" ||
          location.pathname === "/FullstackProductDesign" ||
          location.pathname === "/FullstackMobileApp" ||
          location.pathname === "/Bootcamp/BackendDevelopment" ||
          location.pathname === "/Bootcamp/FrontendDevelopment" ||
          location.pathname === "/Bootcamp/EmbeddedSystems" ||
          location.pathname === "/Bootcamp/MobileAppDevelopment" ||
          location.pathname === "/Bootcamp/FullstackWebDevelopment" ||
          location.pathname === "/Bootcamp/FullstackProductDesign" ||
          location.pathname === "/Bootcamp/FullstackMobileApp" ||
          location.pathname === "/Bootcamp/ProductDesign" ||
          location.pathname === "/Bootcamp/DataScience" ||
          location.pathname === "/Bootcamp/CyberSecurity" ||
          location.pathname === "/IndustrialTraining/BackendDevelopment" ||
          location.pathname === "/IndustrialTraining/FrontendDevelopment" ||
          location.pathname === "/IndustrialTraining/EmbeddedSystems" ||
          location.pathname === "/IndustrialTraining/MobileAppDevelopment" ||
          location.pathname === "/IndustrialTraining/FullstackWebDevelopment" ||
          location.pathname === "/IndustrialTraining/FullstackProductDesign" ||
          location.pathname === "/IndustrialTraining/FullstackMobileApp" ||
          location.pathname === "/IndustrialTraining/ProductDesign" ||
          location.pathname === "/IndustrialTraining/DataScience" ||
          location.pathname === "/IndustrialTraining/CyberSecurity" ||
          location.pathname === "/EngineeringServices" ||
          location.pathname === "/GameTournament" ||
          location.pathname === "/explore-gaming" ||
          location.pathname === "/gaming" ||
          location.pathname === "/events" ||
          location.pathname === "/AwardNight" ||
          location.pathname === "/ComputerNetworking" ||
          location.pathname === "/MobileAppDevelopment" ||
          location.pathname === "/FullstackMobileApp" ||
          location.pathname === "/Ethics" ||
          location.pathname === "/ThirdParties" ||
          location.pathname === "/allcourses" ||
          location.pathname === "/explore-course" ||
          location.pathname === "/deebug_leadership"
            ? "fixed"
            : "static",
        backgroundColor: navbar_bg_color,
      }}
    >
      <div className="navbar_parent" onClick={() => navigate("/")}>
        <div className="navbar-icon-title-container">
          <div>
            {use_white ? (
              <img src={LogoWhite} className="logo_img" />
            ) : (
              <img src={Logo} className="logo_img" />
            )}
          </div>
          <span style={{ color: use_white ? "white" : "rgba(85, 85, 85, 1)" }}>
            Deebug
          </span>
        </div>
        <div className="right">
          {navItem == "close" ? (
            <></>
          ) : navItem == "search" ? (
            <MobileSearchNav
              searchString={searchString}
              handleSearch={handleSearch}
              navItem={navItem}
              setNavItem={(navItem) => setNavItem(navItem)}
            />
          ) : (
            <MobileNavLinks
              navItem={navItem}
              setNavItem={(navItem) => setNavItem(navItem)}
            />
          )}

          <ul className="nav_icons">
            <div className="nav_right_icons_parent">
              <a href="#">
                {use_white ? (
                  <img
                    src={menuwhite}
                    onClick={() => setNavItem("")}
                    className="menu_icon"
                  />
                ) : (
                  <img
                    src={menublack}
                    onClick={() => setNavItem("")}
                    className="menu_icon"
                  />
                )}
              </a>
            </div>
          </ul>
        </div>
      </div>
    </div>
  ) : (
    <div
      className="navbar_container"
      style={{
        position:
          location.pathname === "/" ||
          location.pathname === "/maintenance" ||
          location.pathname === "/allcourses" ||
          location.pathname === "/contact" ||
          location.pathname === "/engineering" ||
          location.pathname === "/BootcampPage" ||
          location.pathname === "/IndustrialTrainingCourses" ||
          location.pathname === "/hackathon" ||
          location.pathname === "/Healthcare" ||
          location.pathname === "/Heurekka" ||
          location.pathname === "/allcourses" ||
          location.pathname === "/EmbeddedSystems" ||
          location.pathname === "/education" ||
          location.pathname === "/BackendDevelopment" ||
          location.pathname === "/FullstackProductDesign" ||
          location.pathname === "/InnovativeShowcase" ||
          location.pathname === "/EngineeringServices" ||
          location.pathname === "/GameTournament" ||
          location.pathname === "/gaming" ||
          location.pathname === "/FrontendDevelopment" ||
          location.pathname === "/IndustrialTraining" ||
          location.pathname === "/RegularStudents" ||
          location.pathname === "/engineering" ||
          location.pathname === "/bootcamp" ||
          location.pathname === "/hackathon" ||
          location.pathname === "/Heurekka" ||
          location.pathname === "/Healthcare" ||
          location.pathname === "/allcourses" ||
          location.pathname === "/FrontendDevelopment" ||
          location.pathname === "/BackendDevelopment" ||
          location.pathname === "/EmbeddedSystems" ||
          location.pathname === "/MobileAppDevelopment" ||
          location.pathname === "/FullstackWebDevelopment" ||
          location.pathname === "/productdesign" ||
          location.pathname === "/DataScience" ||
          location.pathname === "/CyberSecurity" ||
          location.pathname === "/FullstackProductDesign" ||
          location.pathname === "/FullstackMobileApp" ||
          location.pathname === "/Bootcamp/BackendDevelopment" ||
          location.pathname === "/Bootcamp/FrontendDevelopment" ||
          location.pathname === "/Bootcamp/EmbeddedSystems" ||
          location.pathname === "/Bootcamp/MobileAppDevelopment" ||
          location.pathname === "/Bootcamp/FullstackWebDevelopment" ||
          location.pathname === "/Bootcamp/FullstackProductDesign" ||
          location.pathname === "/Bootcamp/FullstackMobileApp" ||
          location.pathname === "/Bootcamp/ProductDesign" ||
          location.pathname === "/Bootcamp/DataScience" ||
          location.pathname === "/Bootcamp/CyberSecurity" ||
          location.pathname === "/IndustrialTraining/BackendDevelopment" ||
          location.pathname === "/IndustrialTraining/FrontendDevelopment" ||
          location.pathname === "/IndustrialTraining/EmbeddedSystems" ||
          location.pathname === "/IndustrialTraining/MobileAppDevelopment" ||
          location.pathname === "/IndustrialTraining/FullstackWebDevelopment" ||
          location.pathname === "/IndustrialTraining/FullstackProductDesign" ||
          location.pathname === "/IndustrialTraining/FullstackMobileApp" ||
          location.pathname === "/IndustrialTraining/ProductDesign" ||
          location.pathname === "/IndustrialTraining/DataScience" ||
          location.pathname === "/ThirdParties" ||
          location.pathname === "/IndustrialTraining/CyberSecurity" ||
          location.pathname === "/EngineeringServices" ||
          location.pathname === "/GameTournament" ||
          location.pathname === "/explore-gaming" ||
          location.pathname === "/gaming" ||
          location.pathname === "/events" ||
          location.pathname === "/IndustrialTrainingCourses" ||
          location.pathname === "/AwardNight" ||
          location.pathname === "/ComputerNetworking" ||
          location.pathname === "/MobileAppDevelopment" ||
          location.pathname === "/FullstackMobileApp" ||
          location.pathname === "/allcourses" ||
          location.pathname === "/explore-course" ||
          location.pathname === "/Ethics" ||
          location.pathname === "/frontend-web-development" ||
          location.pathname === "/backend-development" ||
          location.pathname === "/ux-ui-design" ||
          location.pathname === "/flutter-app-development" ||
          location.pathname === "/fullstack-web-development" ||
          location.pathname === "/deebug_leadership"
            ? "fixed"
            : "static",
        backgroundColor: navbar_bg_color,
      }}
    >
      <div className="navbar_parent" onClick={() => navigate("/")}>
        <div className="navbar-icon-title-container">
          <div>
            {use_white ? (
              <img src={LogoWhite} className="logo_img" />
            ) : (
              <img src={Logo} className="logo_img" />
            )}
          </div>
          <span style={{ color: use_white ? "white" : "rgba(85, 85, 85, 1)",}}>
            DEEBUG
          </span>
        </div>

        <div className="right">
          <DesktopNavLinks />
        </div>
        {/* <div className="navbar-sign-ctn">
          <a href="e-learning.deebug.org" className="navbar-signin">Sign in</a>
          <a href="e-learning.deebug.org" className="navbar-register">Register</a>
        </div> */}
        {/* <ul className="nav_icons">
          <li>
            <div className="nav_right_icons_parent">
              <a href="#">
                <img
                  onClick={() => setNavItem("search")}
                  src={use_white ? SearchIconW : SearchIconB}
                  className="search_icon"
                />
                <MegaMenuSearch />
              </a>

              <a href="#">
                {use_white ? (
                  <img
                    src={menuwhite}
                    onClick={() => setNavItem("")}
                    className="menu_icon"
                  />
                ) : (
                  <img
                    src={menublack}
                    onClick={() => setNavItem("")}
                    className="menu_icon"
                  />
                )}
              </a>
            </div>
          </li>
        </ul> */}
      </div>
    </div>
  );
}

const DesktopNavLinks = () => {
  const location = useLocation();

  const linkColor =
    location.pathname === "/IndustrialTraining" ||
    location.pathname === "/RegularStudents" ||
    // location.pathname === "/deebug_leadership" ||
    // location.pathname === "/deebug_leadership_view" ||
    location.pathname === "/engineering" ||
    location.pathname === "/bootcamp" ||
    location.pathname === "/gaming" ||
    location.pathname === "/hackathon" ||
    location.pathname === "/Healthcare" ||
    location.pathname === "/FrontendDevelopment" ||
    location.pathname === "/BackendDevelopment" ||
    location.pathname === "/EmbeddedSystems" ||
    location.pathname === "/MobileAppDevelopment" ||
    location.pathname === "/FullstackWebDevelopment" ||
    location.pathname === "/productdesign" ||
    location.pathname === "/FullstackProductDesign" ||
    location.pathname === "/DataScience" ||
    location.pathname === "/CyberSecurity" ||
    location.pathname === "/FullstackMobileApp" ||
    location.pathname === "/Bootcamp/BackendDevelopment" ||
    location.pathname === "/Bootcamp/FrontendDevelopment" ||
    location.pathname === "/Bootcamp/EmbeddedSystems" ||
    location.pathname === "/Bootcamp/MobileAppDevelopment" ||
    location.pathname === "/Bootcamp/FullstackWebDevelopment" ||
    location.pathname === "/Bootcamp/FullstackProductDesign" ||
    location.pathname === "/Bootcamp/FullstackMobileApp" ||
    location.pathname === "/Bootcamp/ProductDesign" ||
    location.pathname === "/Bootcamp/DataScience" ||
    location.pathname === "/Bootcamp/CyberSecurity" ||
    location.pathname === "/IndustrialTraining/BackendDevelopment" ||
    location.pathname === "/IndustrialTraining/FrontendDevelopment" ||
    location.pathname === "/IndustrialTraining/EmbeddedSystems" ||
    location.pathname === "/IndustrialTraining/MobileAppDevelopment" ||
    location.pathname === "/ComputerNetworking" ||
    location.pathname === "/IndustrialTraining/FullstackWebDevelopment" ||
    location.pathname === "/IndustrialTraining/FullstackProductDesign" ||
    location.pathname === "/IndustrialTraining/FullstackMobileApp" ||
    location.pathname === "/IndustrialTraining/ProductDesign" ||
    location.pathname === "/IndustrialTraining/DataScience" ||
    location.pathname === "/IndustrialTraining/CyberSecurity" ||
    location.pathname === "/EngineeringServices" ||
    location.pathname === "/Heurekka" ||
    location.pathname === "/events" ||
    location.pathname === "/GameTournament" ||
    location.pathname === "/explore-gaming" ||
    location.pathname === "/IndustrialTrainingCourses" ||
    location.pathname === "/AwardNight" ||
    location.pathname === "/allcourses"
      ? "white"
      : "rgba(85, 85, 85, 1)";
  // : "rgba(243, 243, 243, 0.75)";

  return (
    <ul className="nav_links">
      {/* <li>
        <a href="/engineering" style={{ color: linkColor }}>
          Engineering
        </a>
        <img src={ChevronRight} className="chevron_right" />
        <MegaMenuEngineering />
      </li> */}
      {/* <li>
        <a href="/education" style={{ color: linkColor }}>
          Education
        </a>
        <img src={ChevronRight} className="chevron_right" />
        <MegaMenuEducation />
      </li> */}
      {/* <li>
        <a href="/maintenance" style={{ color: linkColor }}>
          Online Courses
        </a>
      </li>
      <li>
        <a href="/maintenance" style={{ color: linkColor }}>
          Career Courses
        </a>
      </li>
      <li>
        <a href="/IndustrialTraining" style={{ color: linkColor }}>
          Industrial Training
        </a>
      </li>
      <li>
        <a href="/RegularStudents" style={{ color: linkColor }}>
          Skill Sprints
        </a>
      </li> */}
{/* 
      <li>
        <a href="/Healthcare" style={{ color: linkColor }}>
          Healthcare
        </a>
        <img src={ChevronRight} className="chevron_right" />
        <MegaMenuHealthcare />
      </li> */}
{/* 
      <li>
        <a href="/gaming" style={{ color: linkColor }}>
          Gaming
        </a>
        <img src={ChevronRight} className="chevron_right" />
        <MegaMenuGaming />
      </li> */}

      <li>
        <a href="/contact" style={{ color: linkColor }}>
          Contact Us
        </a>
        <img src={ChevronRight} className="chevron_right" />
      </li>
    </ul>
  );
};

const MobileNavLinks = ({ navItem, setNavItem }) => {
  return (
    <ul className="nav_links">
      <li id="close_list_item">
        {navItem == "" || navItem == "close" ? (
          <div></div>
        ) : (
          <img
            onClick={() => setNavItem("")}
            src={ChevronLeft}
            className="back_icon"
          />
        )}

        <img
          onClick={() => setNavItem("close")}
          src={Close}
          className="close_icon"
        />
      </li>

      {/* {navItem == "" ? (
        <NavElement title="Engineering" setNavItem={setNavItem} />
      ) : (
        <></>
      )} */}

      {navItem == "Engineering" ? <MegaMenuEngineering /> : <></>}

      {/* {navItem == "" ? (
        <NavElement title="Education" setNavItem={setNavItem} />
      ) : (
        <></>
      )} */}

      {/* {navItem == "Education" ? <MegaMenuEducation /> : <></>} */}
      {/* <li>
        <a href="/maintenance">
          Online Courses
        </a>
      </li>
      <li>
        <a href="/maintenance">
          Career Courses
        </a>
      </li>
      <li>
        <a href="/IndustrialTraining">
          Industrial Training
        </a>
      </li>
      <li>
        <a href="/RegularStudents">
          Skill Sprints
        </a>
      </li> */}

      {/* {navItem == "" ? (
        <NavElement title="Healthcare" setNavItem={setNavItem} />
      ) : (
        <></>
      )} */}

      {/* {navItem == "Healthcare" ? <MegaMenuHealthcare /> : <></>}

      {navItem == "" ? (
        <NavElement title="Gaming" setNavItem={setNavItem} />
      ) : (
        <></>
      )} */}

      {/* {navItem == "Gaming" ? <MegaMenuGaming /> : <></>} */}

      {navItem == "" ? (
        <NavElement
          title="Contact Us"
          setNavItem={setNavItem}
          href={"/contact"}
        />
      ) : (
        <></>
      )}
        {/* <div className="navbar-sign-ctn">
          <a href="https://elearning.deebug.org" className="navbar-signin">Sign in</a>
          <a href="https://elearning.deebug.org" className="navbar-register">Register</a>
        </div> */}
    </ul>
  );
};

const MobileSearchNav = ({
  navItem,
  setNavItem,
  searchString,
  handleSearch,
}) => {
  return (
    <ul className="nav_links">
      <li id="close_list_item_search">
        <img
          onClick={() => setNavItem("close")}
          src={Close}
          className="close_icon"
        />
      </li>

      <div
        style={{
          paddingLeft: 26,
          paddingRight: 26,
        }}
        className="text_field"
      >
        <img src={SearchIconW} className="prefix_icon" />
        <input
          name="search"
          placeholder="Search"
          value={searchString}
          onChange={handleSearch}
          className="search_field"
        />
      </div>

      <header>Quick links</header>

      {navItem == "search" ? (
        <MobileSearchLink title="About Deebug" setNavItem={setNavItem} />
      ) : (
        <></>
      )}

      {navItem == "search" ? (
        <MobileSearchLink title="Upcoming games" setNavItem={setNavItem} />
      ) : (
        <></>
      )}

      {navItem == "search" ? (
        <MobileSearchLink title="MedPlan" setNavItem={setNavItem} />
      ) : (
        <></>
      )}

      {navItem == "search" ? (
        <MobileSearchLink title="Education" setNavItem={setNavItem} />
      ) : (
        <></>
      )}

      {navItem == "search" ? (
        <MobileSearchLink title="Events" setNavItem={setNavItem} />
      ) : (
        <></>
      )}
    </ul>
  );
};

const NavElement = ({ title, setNavItem, href }) => {
  return (
    <li onClick={() => setNavItem(title)}>
      <a href={href}>{title}</a>
      <img src={ChevronRight} className="chevron_right" />
    </li>
  );
};

const MobileSearchLink = ({ title, setNavItem }) => {
  return (
    <li onClick={() => setNavItem(title)}>
      <a
        style={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          fontWeight: 400,
        }}
        href="#"
      >
        <img src={ArrowIcon} className="arrow_icon" />
        {title}
      </a>
    </li>
  );
};
