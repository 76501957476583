import React, { useState } from 'react';
import "./registration.css";
import Modal from 'react-modal';
import { useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { phoneNumberParser } from '../../functions/number_parser';
import { formatAmount } from '../../functions/amountFromater';



const Intern_Registration = () => {
    const [currentStage, setCurrentStage] = useState(1);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [is_loading, setIsLoading] = useState(false);
    const [fullname, setFullname] = useState("");
    const [email, setEmail] = useState("");
    const [phone_no, setPhonNo] = useState("");
    const [whatsapp_no, setWhatsappNo] = useState("");
    const [gender, setGender] = useState("male");
    const [school, setSchool] = useState("");
    const [course, setCourse] = useState("Fullstack Web Development");
    const [learning_mode, setLearningMode] = useState("onsite");
    const [class_name, setClassName] = useState("registration-input");
    const [class_name2, setClassName2] = useState("registration-input");
    const [class_name3, setClassName3] = useState("registration-input");
    const [class_name4, setClassName4] = useState("registration-input");
    const [class_name5, setClassName5] = useState("registration-input");
    const [course_fee, setCourseFee] = useState(99000);
    const navigate = useNavigate();

    const nextStage = () => {
        if (currentStage < 3) {
            setCurrentStage(currentStage + 1);
        } else {
            setModalIsOpen(true);
        }
    };

    const prevStage = () => {
        if (currentStage > 1) {
            setCurrentStage(currentStage - 1);
        }
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    const getStageClass = (stage) => {
        if (stage === currentStage) {
            return 'stage active';
        } else if (stage < currentStage) {
            return 'stage completed';
        } else {
            return 'stage';
        }
    };

    const stageDescriptions = {
        1: 'Please enter your details',
        2: 'Please enter course details',
        3: 'Please confirm your entry',
    };


    const data = [
        { course_name: course, learning_mode: learning_mode, program: 'Industrial Training', course_fee: course_fee }
    ];

    const handleChange = (e) => {
        e.preventDefault();
        if (e.target.name === "fullname") {
            setFullname(e.target.value);
        } else if (e.target.name === "email") {
            setEmail(e.target.value);
        } else if (e.target.name === "phone") {
            setPhonNo(e.target.value);
        } else if (e.target.name === "whatsapp_no") {
            setWhatsappNo(e.target.value);
        } else if (e.target.name === "gender") {
            setGender(e.target.value);
        } else if (e.target.name === "school") {
            setSchool(e.target.value);
        } else if (e.target.name === "courses") {
            setCourse(e.target.value);
            if (e.target.value === "Fullstack Web Development") {
                setCourseFee(99000)
            } else if (e.target.value === "Fullstack Product Design") {
                setCourseFee(97000)
            } else if (e.target.value === "Cybersecurity") {
                setCourseFee(65000)
            } else if (e.target.value === "Fullstack Mobile App Development") {
                setCourseFee(99000)
            } else if (e.target.value === "Frontend Web Development") {
                setCourseFee(54000)
            } else if (e.target.value === "Backend Web Development") {
                setCourseFee(54000)
            } else if (e.target.value === "Mobile App Development") {
                setCourseFee(56000)
            } else if (e.target.value === "Product Design") {
                setCourseFee(52000)
            } else if (e.target.value === "Data Science") {
                setCourseFee(54000)
            }
        } else if (e.target.name === "learning_mode") {
            setLearningMode(e.target.value);
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (currentStage === 3) {
            setIsLoading(true);
            console.log( fullname, email, phone_no, course_fee, learning_mode, school, whatsapp_no, gender, data)
            try {
                const res = await fetch(
                    "https://server-deebug-vgka.onrender.com/user_registration/it_student_course_registration",
                    {
                        method: "POST",
                        body: JSON.stringify({
                            fullname,
                            email,
                            phone_no: phoneNumberParser(phone_no),
                            gender,
                            courses: data,
                            learning_mode,
                            course_fee,
                            school,
                            total_course_fee: course_fee,
                            whatsapp_no: phoneNumberParser(whatsapp_no)
                        }),
                        headers: {
                            "Content-Type": "application/json",
                            Accept: "application/json, text/plain, */*",
                        },
                    }
                );
                const result = await res.json();
                console.log(result);
                if (result.msg === "success") {
                    setIsLoading(false);
                    nextStage();
                } else {
                    setIsLoading(false);
                    alert("oOps! An error occured. Please try again later");
                }

            } catch (error) {
                setIsLoading(false);
                alert("oOps! An error occured. Please try again later");
                console.log(error);
            }

        } else {
            // form validation
            let validated = false;
            if(currentStage === 1) {
                setClassName("registration-input");
                setClassName2("registration-input");
                setClassName3("registration-input");
                setClassName4("registration-input");
                setClassName5("registration-input");
                if(fullname === "") {
                    setClassName("unvalidated-registration-input");
                    validated = true;
                }
                if(email === "") {
                    setClassName2("unvalidated-registration-input");
                    validated = true;
                }
                if(phone_no === "") {
                    setClassName3("unvalidated-registration-input");
                    validated = true;
                }
                if(whatsapp_no === "") {
                    setClassName4("unvalidated-registration-input");
                    validated = true;
                }
                if(school === "") {
                    setClassName5("unvalidated-registration-input");
                    validated = true;
                }
            }
            if(validated === true) return;

            nextStage();
        }
    }


    return (
        <div className='form-main-container'>
            <div className="form-container">
                <h1>Industrial Training</h1>
                <p>{stageDescriptions[currentStage]}</p>
                <div className="stages">
                    <div className={getStageClass(1)}>
                        <p className='registration-step'>Step 1</p>
                        <div className="bar"></div>
                    </div>
                    <div className={getStageClass(2)}>
                        <p className='registration-step'>Step 2</p>
                        <div className="bar"></div>
                    </div>
                    <div className={getStageClass(3)}>
                        <p className='registration-step'>Step 3</p>
                        <div className="bar"></div>
                    </div>
                </div>
                <div className="form-content">
                    {currentStage === 1 && <div>
                        <form className='registration-form'>
                            <input
                                type="text"
                                id="fullname"
                                name="fullname"
                                placeholder='Fullname'
                                className={class_name}
                                value={fullname}
                                onChange={handleChange}
                            />
                            <input
                                type="text"
                                id="email"
                                name="email"
                                placeholder='Email'
                                className={class_name2}
                                value={email}
                                onChange={handleChange}
                            />
                            <input
                                type="tel"
                                id="phone"
                                name="phone"
                                placeholder='Phone number'
                                className={class_name3}
                                value={phone_no}
                                onChange={handleChange}
                            />
                            <input
                                type="tel"
                                id="phone"
                                name="whatsapp_no"
                                placeholder='WhatsApp number'
                                className={class_name4}
                                value={whatsapp_no}
                                onChange={handleChange}
                            />
                            <select
                                id="gender"
                                name="gender"
                                className='registration-multiple-input'
                                value={gender}
                                onChange={handleChange}
                            >
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                            </select>
                            <input
                                type="text"
                                id="school"
                                name="school"
                                placeholder='Name of University, College or Polytechnic'
                                className={class_name5}
                                value={school}
                                onChange={handleChange}
                            />
                        </form>
                    </div>}
                    {currentStage === 2 && <div>
                        <form className='registration-form'>
                            <select
                                id="courses"
                                name="courses"
                                className='registration-multiple-input'
                                value={course}
                                onChange={handleChange}
                            >
                                <option className='multiple-reg-value' value="Fullstack Web Development">Fullstack Web Development (Frontend and Backend)</option>
                                <option className='multiple-reg-value' value="Fullstack Product Design">Fullstack Product Design (Frontend and UX/UI) </option>
                                <option className='multiple-reg-value' value="Fullstack Mobile App Development">Fullstack Mobile App Development (Mobile App and Backend) </option>
                                <option className='multiple-reg-value' value="Frontend Web Development">Frontend Web Development</option>
                                <option className='multiple-reg-value' value="Backend Web Development">Backend Development</option>
                                <option className='multiple-reg-value' value="Mobile App Development">Mobile App Development</option>
                                <option className='multiple-reg-value' value="Product Design">UX/UI Design</option>
                                <option className='multiple-reg-value' value="Data Science">Data analysis/Data Science</option>
                                <option className='multiple-reg-value' value="Cybersecurity">Cybersecurity</option>
                            </select>
                            <select
                                id="learning"
                                name="learning_mode"
                                className='registration-multiple-input'
                                value={learning_mode}
                                onChange={handleChange}
                            >
                                <option value="onsite">Onsite</option>
                            </select>
                        </form>
                    </div>}
                    {currentStage === 3 && <div>
                        <div className='register-final-details'>
                            <table className="register-table">
                                <thead>
                                    <tr>
                                        <th>Course</th>
                                        <th>Mode</th>
                                        <th>Program type</th>
                                        <th>Course fee</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((row, index) => (
                                        <tr key={index}>
                                            <td>{row.course_name}</td>
                                            <td>{row.learning_mode === "onsite" ? "Onsite" : row.learning_mode}</td>
                                            <td>{row.program}</td>
                                            <td>{formatAmount(row.course_fee)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className='register-price-details'>
                                <hr className='register-hr' />
                                <div className='register-price'>
                                    <p>Total Course Fee</p>
                                    <p>{formatAmount(course_fee)}</p>
                                </div>
                            </div>
                        </div>
                    </div>}
                </div>
                <div className="registration-btns">
                    <button className='register-btn' onClick={handleSubmit} disabled={is_loading}>
                        <span>
                            {is_loading ? (
                                <ClipLoader color={"white"} loading={is_loading} size={30} />
                            ) : (
                                currentStage < 3 ? 'Continue' : 'Submit'
                            )}
                        </span>
                    </button>                    
                    <button className='register-btn' onClick={prevStage} disabled={currentStage === 1 || is_loading}>
                        Back
                    </button>
                </div>
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    contentLabel="Registration Successful"
                    className="modal"
                    overlayClassName="overlay"
                >
                    <h2>Successfully Registered</h2>
                    <button className='register-finish-btn' onClick={() => navigate("/IndustrialTraining")}>Close</button>
                </Modal>
            </div>
        </div>
    )
}


export default Intern_Registration