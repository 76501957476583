import React from 'react';
import "./indstories.css"
import sammy from "../../images/sammytop.webp"
import sammy1 from "../../images/sammytopprt.webp"
import sammybtm from "../../images/sammbtm.webp"
import sammybtm1 from "../../images/sammybtmprt.webp";

const SamStory = ({closeModal1}) => {
  return (
    <div className='story-modal-overlay'>
        <div className="story-modal-content">
            <button className="story-close-button" onClick={closeModal1}>X</button>
            <div className="story-content-main">
                <div className='story-bground2'>
                    <img
                        src={sammy}
                        width="100%"
                        height="100%"
                        alt='background'
                    />
                </div>
                <img
                    src={sammy1}
                    width="100%"
                    height="100%"
                    alt='background'
                />
                <div className='story-content-text'>
                    <h5 className='sammy-title'>
                        Samuel Oghamomwan
                    </h5>
                    <p className='sammy-expert'>
                        Backend Developer
                    </p>
                    <h4>Studied computer science</h4>
                    <h4>University of Benin, class 2023</h4>
                    <h1>
                        “Deebug Institute was not just a place where I learned to code, 
                        it was the crucible where my passion forged into expertise, and 
                        my dreams evolved into a roadmap for success .”
                    </h1>
                    <h2>As a backend development student at the institute, Samuel was not just seeking an education, he was on a quest to turn his passion for coding into a lifelong career.</h2>
                    <h2>During his time at Debug Institute, Samuel demonstrated an insatiable curiosity and a natural flair for problem-solving. His tutor noticed his exceptional aptitude for backend development, and his peers admired his collaborative spirit. In the bustling halls of the institute, Samuel found an environment that nurtured his technical skills while encouraging creativity.</h2>
                    <h2>One of the defining moments of Samuel’s time at Deebug Institute was his involvement in a groundbreaking project. Assigned to develop the backend for a real-world logistics application, Samuel and his team not only met but exceeded expectations. This early success fueled Samuel’s confidence and ignited a spark of entrepreneurship within him.</h2>
                    <h3>As a student, Samuel was not content with just attending classes and completing assignments. He actively participated in hackathons, and extracurricular activities that broadened his skill set. His commitment to excellence earned him accolades within the institute and set the stage for what was to come.</h3>
                    <h2>Beyond technical skills, Samuel’s time at Deebug Institute played a crucial role in shaping his mindset. The institute emphasized not only the technical aspects of software development but also the importance of collaboration, communication, and a holistic understanding of the industry. Samuel absorbed these lessons and incorporated them into his budding entrepreneurial vision.</h2>
                </div>
                <div className='story-bground3'>
                        <img
                            src={sammybtm}
                            width="100%"
                            height="100%"
                            alt='background'
                        />
                </div>
                <img
                    src={sammybtm1}
                    width="100%"
                    height="100%"
                    alt='background'
                />
                <h2>Upon graduation, armed with the knowledge gained at Deebug Institute and fueled by a desire to make a mark in the tech industry, Samuel founded his software development startup. The values instilled in him during his time at the institute, such as perseverance and a commitment to excellence, became the guiding principles for his company.</h2>
                <h2>As Samuel company began to gain traction, he has remained connected to Deebug Institute, becoming an advocate for the institute's programs and initiatives. His success story became an inspiration for current students, showcasing that a strong foundation in backend development coupled with an entrepreneurial mindset could lead to unprecedented achievements.</h2>
                <h2>Samuel’s journey from a backend development student at Deebug Institute to a successful entrepreneur exemplifies the  power of education, passion, and perseverance. His story continues to motivate aspiring developers within and beyond the institute, proving that with the right education and mindset, one can turn dreams into thriving realities.</h2>
                <hr className="story-tourny-line" />
                <a href="https://www.instagram.com/_x.amuel/" className="story-tourny-sm">
                    https://www.instagram.com/_x.amuel/
                </a>
            </div>
        </div>
    </div>
  )
}

export default SamStory