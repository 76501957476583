import React, { useEffect, useState, useRef } from "react";
import "./AllCourses.css";
import { Link } from "react-router-dom";
import tick from "../../images/tick.png";
import react from '../../images/react.png';
import figma from '../../images/figma.png';
import node from '../../images/node.png';
import flutter from '../../images/flutter.png';
import flutterjs from '../../images/flutterjs.png';
import reactnode from '../../images/reactnode.png';
import reactfigma from '../../images/reactfigma.png';
import embeddedeng from '../../images/embeddedeng.png';
import ChevronRight from "../../images/chevron_right_blue.png";
import Courses from "../../images/coursebground.webp";
import { useNavigate } from "react-router-dom";
import responsivebtn from '../../images/hmbdwn.png';

import { useWindowWidth, useWindowHeight } from "@react-hook/window-size";
import { red } from "@mui/material/colors";

export default function BootcampCourses() {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const [mobile, setMobile] = useState(false);
  const width = useWindowWidth();
  useEffect(() => {
    if (width <= 767) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, [width]);

    
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };


  return (
    <>
        <div className='intern-courses-top-parent'>
            <div className="rg-sub-navbar">
                <p>E-Learning</p>
                <ul className={isOpen ? "intern-menu active" : "intern-menu"}>
                    <li><span onClick={() => navigate("/IndustrialTraining")}>IT</span></li>
                    <li><span onClick={() => navigate("/RegularStudents")}>Regular</span></li>
                    <li><span onClick={() => navigate("/BootcampPage")}>BootCamp</span></li>
                </ul>
                <div className="intern-ham-btn">
                    <img 
                        src={responsivebtn}
                        width="100%"
                        height="100%"
                        alt='icon'
                        onClick={toggleMenu}
                    />
                </div>
            </div>
            <div className='intern-courses-bgimage'>
                <img
                    src={Courses}
                    width="100%"
                    height="100%"
                    alt='background'
                    style={{objectFit: "cover"}}
                    
                />
            </div>
            <div className='intern-background-text'>
                <h1>Our Curated Courses</h1>
            </div>
        </div>

      <div className="ind-tr-black-bg">
        <div className="all-courses-title-container">
          <p className="all-courses-title" style={{ color: "white" }}>
            Affordable Prices for you
          </p>
          <p className="all-courses-subtitle" style={{ color: "white" }}>
            We believe in making education accessible to all by bring you high
            quality courses at affordable rates
          </p>
        </div>
            <div className='courses-main-container'>
                <div className='all-courses-card'>
                    <p className='course-title'>PRODUCT DESIGN</p>
                    <div className='course-image-and-title'>
                        <img 
                            src={figma}
                            width="100%"
                            height="100%"
                            alt='icon'
                            className='course-image'
                        />
                        <p>Figma</p>
                    </div>
                    <div className='amount-list'>
                        <span className='amount-style'>FREE</span>
                    </div>
                    <Link to="/Bootcamp/ProductDesign" className='course-btn-style'>Get started</Link>
                    <div className="all-courses-program-container">
                        <div className="all-courses-program">
                            <img src={tick} className='tick_style' />
                            <span>Includes user research, design thinking principles, design tools like Figma, </span>
                        </div>
                        <div className="all-courses-program">
                            <img src={tick} className='tick_style' />
                            <span>8 weeks Intensive + Practical Classes</span>
                        </div>
                        <div className="all-courses-program">
                            <img src={tick} className='tick_style' />
                            <span>Certificates are awarded at the end of the program.</span>
                        </div>
                    </div>
                </div>
                <div className='all-courses-card'>
                    <p className='course-title'>BACKEND DEVELOPMENT</p>
                    <div className='course-image-and-title'>
                        <img 
                            src={node}
                            width="100%"
                            height="100%"
                            alt='icon'
                            className='course-image'
                        />
                        <p>NodeJS</p>
                    </div>
                    <div className='amount-list'>
                        <span className='amount-style'>FREE</span>
                    </div>
                    <Link to="/Bootcamp/BackendDevelopment" className='course-btn-style'>Get started</Link>
                    <div className="all-courses-program-container">
                        <div className="all-courses-program">
                            <img src={tick} className='tick_style' />
                            <span>Includes Node js, Express, Javascript. Databases like MySQL and Mongo db</span>
                        </div>
                        <div className="all-courses-program">
                            <img src={tick} className='tick_style' />
                            <span>8 weeks Intensive + Practical Classes</span>
                        </div>
                        <div className="all-courses-program">
                            <img src={tick} className='tick_style' />
                            <span>Full access to the interactive community to aid with assignments</span>
                        </div>
                        <div className="all-courses-program">
                            <img src={tick} className='tick_style' />
                            <span>Certificates are awarded at the end of the program.</span>
                        </div>
                    </div>
                </div>
                <div className='all-courses-card'> 
                    <p className='course-title'>MOBILE APP DEVELOPMENT</p>
                    <div className='course-image-and-title'>
                        <img 
                            src={flutter}
                            width="100%"
                            height="100%"
                            alt='icon'
                            className='course-image'
                        />
                        <p>Flutter</p>
                    </div>
                    <div className='amount-list'>
                        <span className='amount-style'>FREE</span>
                    </div>
                    <Link to="/Bootcamp/MobileAppDevelopment" className='course-btn-style'>Get started</Link>
                    <div className="all-courses-program-container">
                        <div className="all-courses-program">
                            <img src={tick} className='tick_style' />
                            <span>Includes flutter development framework using the dart programming language.</span>
                        </div>
                        <div className="all-courses-program">
                            <img src={tick} className='tick_style' />
                            <span>8 weeks Intensive + Practical Classes</span>
                        </div>
                        <div className="all-courses-program">
                            <img src={tick} className='tick_style' />
                            <span>Certificates are awarded at the end of the program.</span>
                        </div>
                    </div>
                </div> 
                <div className='all-courses-card'>
                    <p className='course-title'>FRONTEND</p>
                    <div className='course-image-and-title'>
                        <img 
                            src={react}
                            width="100%"
                            height="100%"
                            alt='icon'
                            className='course-image'
                        />
                        <p>React</p>
                    </div>
                    <div className='amount-list'>
                        <span className='amount-style'>FREE</span>
                    </div>
                    <Link to="/Bootcamp/FrontendDevelopment" className='course-btn-style'>Get started</Link>
                    <div className="all-courses-program-container">
                        <div className="all-courses-program">
                            <img src={tick} className='tick_style' />
                            <span>Includes HTML, CSS, Javascript</span>
                        </div>
                        <div className="all-courses-program">
                            <img src={tick} className='tick_style' />
                            <span>8 weeks Intensive + Practical Classes</span>
                        </div>
                        <div className="all-courses-program">
                            <img src={tick} className='tick_style' />
                            <span>Certificates are awarded at the end of the program.</span>
                        </div>
                    </div>
                </div>
                <div className='all-courses-card'>
                    <p className='course-title'>FULLSTACK MOBILE APP DEVELOPMENT</p>
                    <div className='course-image-and-title'>
                        <img 
                            src={flutterjs}
                            width="100%"
                            height="100%"
                            alt='icon'
                            className='course-image'
                        />
                    </div>
                    <div className='amount-list'>
                        <span className='amount-style'>FREE</span>
                    </div>
                    <Link to="/Bootcamp/FullstackMobileApp" className='course-btn-style'>Get started</Link>
                    <div className="all-courses-program-container">
                        <div className="all-courses-program">
                            <img src={tick} className='tick_style' />
                            <span>Includes HTML, CSS, Javascript</span>
                        </div>
                        <div className="all-courses-program">
                            <img src={tick} className='tick_style' />
                            <span>8 weeks Intensive + Practical Classes</span>
                        </div>
                        <div className="all-courses-program">
                            <img src={tick} className='tick_style' />
                            <span>Certificates are awarded at the end of the program.</span>
                        </div>
                    </div>
                </div>
                <div className='all-courses-card'>
                    <p className='course-title'>EMBEDDED SYSTEMS</p>
                    <div className='course-image-and-title'>
                        <img 
                            src={embeddedeng}
                            width="100%"
                            height="100%"
                            alt='icon'
                            className='course-image'
                        />
                    </div>
                    <div className='amount-list'>
                        <span className='amount-style'>FREE</span>
                    </div>
                    <Link to="/Bootcamp/EmbeddedSystems" className='course-btn-style'>Get started</Link>
                    <div className="all-courses-program-container">
                        <div className="all-courses-program">
                            <img src={tick} className='tick_style' />
                            <span>Atmega328p microcontroller using Arduino Uno Development Board</span>
                        </div>
                        <div className="all-courses-program">
                            <img src={tick} className='tick_style' />
                            <span>8 weeks Intensive + Practical Classes</span>
                        </div>
                        <div className="all-courses-program">
                            <img src={tick} className='tick_style' />
                            <span>Certificates are awarded at the end of the program.</span>
                        </div>
                    </div>
                </div>
                <div className='all-courses-card'>
                    <p className='course-title'>FULL STACK SOFTWARE PRODUCT DESIGN</p>
                    <div className='course-image-and-title'>
                        <img 
                            src={reactfigma}
                            width="100%"
                            height="100%"
                            alt='icon'
                            className='course-image'
                        />
                    </div>
                    <div className='amount-list'>
                        <span className='amount-style'>FREE</span>
                    </div>
                    <Link to="/Bootcamp/FullstackProductDesign" className='course-btn-style'>Get started</Link>
                    <div className="all-courses-program-container">
                        <div className="all-courses-program">
                            <img src={tick} className='tick_style' />
                            <span>Includes HTML, CSS, Javascript, React js, Figma</span>
                        </div>
                        <div className="all-courses-program">
                            <img src={tick} className='tick_style' />
                            <span>8 weeks Intensive + Practical Classes</span>
                        </div>
                        <div className="all-courses-program">
                            <img src={tick} className='tick_style' />
                            <span>Certificates are awarded at the end of the program.</span>
                        </div>
                    </div>
                </div>
                <div className='all-courses-card'>
                    <p className='course-title'>FULLSTACK WEB DEVELOPMENT</p>
                    <div className='course-image-and-title'>
                        <img 
                            src={reactnode}
                            width="100%"
                            height="100%"
                            alt='icon'
                            className='course-image'
                        />
                    </div>
                    <div className='amount-list'>
                        <span className='amount-style'>FREE</span>
                    </div>
                    <Link to="/Bootcamp/FullstackWebDevelopment" className='course-btn-style'>Get started</Link>
                    <div className="all-courses-program-container">
                        <div className="all-courses-program">
                            <img src={tick} className='tick_style' />
                            <span>Includes HTML, CSS, Javascript, React js, Node js, Express</span>
                        </div>
                        <div className="all-courses-program">
                            <img src={tick} className='tick_style' />
                            <span>8 weeks Intensive + Practical Classes</span>
                        </div>
                        <div className="all-courses-program">
                            <img src={tick} className='tick_style' />
                            <span>Full access to the interactive community to aid with assignments</span>
                        </div>
                        <div className="all-courses-program">
                            <img src={tick} className='tick_style' />
                            <span>Certificates are awarded at the end of the program.</span>
                        </div>
                    </div>
                    </div>
                <div className='all-courses-card'>
                    <p className='course-title'>Data Science</p>
                    <div className='course-image-and-title'>
                        {/* <img 
                            src={reactnode}
                            width="100%"
                            height="100%"
                            alt='icon'
                            className='course-image'
                        /> */}
                    </div>
                    <div className='amount-list'>
                        <span className='amount-style'>FREE</span>
                    </div>
                    <Link to="/Bootcamp/DataScience" className='course-btn-style'>Get started</Link>
                    <div className="all-courses-program-container">
                        <div className="all-courses-program">
                            <img src={tick} className='tick_style' />
                            <span>Includes HTML, CSS, Javascript</span>
                        </div>
                        <div className="all-courses-program">
                            <img src={tick} className='tick_style' />
                            <span>8 weeks Intensive + Practical Classes</span>
                        </div>
                        <div className="all-courses-program">
                            <img src={tick} className='tick_style' />
                            <span>Full access to the interactive community to aid with assignments</span>
                        </div>
                        <div className="all-courses-program">
                            <img src={tick} className='tick_style' />
                            <span>Certificates are awarded at the end of the program.</span>
                        </div>
                    </div>
                </div>
                <div className='all-courses-card'>
                    <p className='course-title'>Cyber Security</p>
                    <div className='course-image-and-title'>
                        {/* <img 
                            src={reactnode}
                            width="100%"
                            height="100%"
                            alt='icon'
                            className='course-image'
                        /> */}
                    </div>
                    <div className='amount-list'>
                        <span className='amount-style'>FREE</span>
                    </div>
                    <Link to="/Bootcamp/CyberSecurity" className='course-btn-style'>Get started</Link>
                    <div className="all-courses-program-container">
                        <div className="all-courses-program">
                            <img src={tick} className='tick_style' />
                            <span>Includes HTML, CSS, Javascript</span>
                        </div>
                        <div className="all-courses-program">
                            <img src={tick} className='tick_style' />
                            <span>8 weeks Intensive + Practical Classes</span>
                        </div>
                        <div className="all-courses-program">
                            <img src={tick} className='tick_style' />
                            <span>Full access to the interactive community to aid with assignments</span>
                        </div>
                        <div className="all-courses-program">
                            <img src={tick} className='tick_style' />
                            <span>Certificates are awarded at the end of the program.</span>
                        </div>
                    </div>
                </div>
                <div className='all-courses-card'>
                    <p className='course-title'>Computer Networking</p>
                    <div className='course-image-and-title'>
                        {/* <img 
                            src={reactnode}
                            width="100%"
                            height="100%"
                            alt='icon'
                            className='course-image'
                        /> */}
                    </div>
                    <div className='amount-list'>
                        <span className='amount-style'>FREE</span>
                    </div>
                    <Link to="/Bootcamp/ComputerNetworking" className='course-btn-style'>Get started</Link>
                    <div className="all-courses-program-container">
                        <div className="all-courses-program">
                            <img src={tick} className='tick_style' />
                            <span>Includes HTML, CSS, Javascript</span>
                        </div>
                        <div className="all-courses-program">
                            <img src={tick} className='tick_style' />
                            <span>8 weeks Intensive + Practical Classes</span>
                        </div>
                        <div className="all-courses-program">
                            <img src={tick} className='tick_style' />
                            <span>Full access to the interactive community to aid with assignments</span>
                        </div>
                        <div className="all-courses-program">
                            <img src={tick} className='tick_style' />
                            <span>Certificates are awarded at the end of the program.</span>
                        </div>
                    </div>
                </div>
            </div>
      </div>
    </>
  );
}
