import React, {useState, useRef} from 'react';
import "./explorecourse.css";
import deebug from "../../images/deebug3d.webp";
// import react from "../../images/reactdrk.png";
// import node from "../../images/nodejsdrk.png";
// import git from "../../images/github.png";
// import mongo from "../../images/mongodb.png";
// import figma from "../../images/Figmadrk.png";
// import embed from "../../images/embeddrk.png";
// import user from "../../images/courseuser.png";
// import repo from "../../images/reporeview.webp";
// import point from "../../images/point.png";
// import star from "../../images/star.png";
// import figview from "../../images/figmapreview.webp";
// import resreview from "../../images/resreview.webp";
// import wire from "../../images/wirereview.webp";
// import mernrev from "../../images/mernpreview.webp"
// import noderev from "../../images/nodepreview.webp"
// import reactrev from "../../images/reactpreview.webp";
// import webskillprev from "../../images/webskillprev.png";
// import uiskillprev from "../../images/uiskillprev.png";
// import embskillpreview from "../../images/embskillprev.png";
// import mernindprev from "../../images/mernindprev.png";
// import uindprev from "../../images/uindprev.png";
// import cyberindpreview from "../../images/cyberindprev.png";
// import reactblue from "../../images/reactblue.png";
// import mongodblue from "../../images/mongodblue.png";
// import githublue from "../../images/githublue.png";
// import nodejsblue from "../../images/nodejsblue.png";
import teststory9 from '../../images/teststory9.webp';
import teststory8 from '../../images/teststory8.webp';
import teststory3 from '../../images/teststory3.webp';
import teststory5 from '../../images/teststory5.webp';
import teststory10 from '../../images/teststory10.webp'; 
import previous from '../../images/eduprevious.png';
import next from '../../images/forwardblack.png';     



const ExploreCourse = () => {

    const testimonials = [
        {
            text: "“I had a great experience at Deebug Institute learning full-stack web development. The curriculum was comprehensive, and the instructors were knowledgeable and supportive. I highly recommend Deebug Institute for advancing your tech career.”",
            name: "Daniel Success Daudu",
            class: "Class of 2024",
            image: teststory8
        },
        {
            text: "“Learning at Deebug has been a milestone in my tech journey. Using industry tools, sharing ideas, and working with mentors and colleagues has been an amazing experience. Step out of your comfort zone and join the Deebug family.”",
            name: "Kayode Obiora",
            class: "Class of 2024",
            image: teststory10
        },
        {
            text: "“Deebug Institute sets the gold standard for tech education with top-notch knowledge, expert guidance, hands-on learning, and unparalleled support. Deebug provides the perfect platform to realize your aspirations in the dynamic world of technology.”",
            name: "Thomas Tunde",
            class: "Class of 2024",
            image: teststory9
        },    
        {
            text: "“I had the privilege of learning at Debug Institute, and it was an incredible experience. The team felt like a big family, supportive and encouraging. Debug Institute offers great opportunities for growth and development. I highly recommend it.”",
            name: "Becky",
            class: "Class of 2024",
            image: teststory5
        },
        {
            text: "“My time at Deebug Institute was amazing! The well-organized courses and experienced instructors were incredibly helpful. The practical training provided valuable, immediately usable skills. I highly recommend Deebug Institute.”",
            name: "Precious",
            class: "Class of 2024",
            image: teststory3
        }
    ];

    const [currentIndex, setCurrentIndex] = useState(0);
    const containerRef = useRef(null);
    const containerRef1 = useRef(null);
  
    const handleScroll = (direction) => {
      const container = containerRef.current;
      const containerWidth = container.clientWidth;
      const newIndex = direction === 'left'
        ? Math.max(currentIndex - 1, 0)
        : Math.min(currentIndex + 1, testimonials.length - 1);
  
      setCurrentIndex(newIndex);
      container.scrollTo({
        left: newIndex * containerWidth,
        behavior: 'smooth'
      });
    };


    
  return (
    <div className='explore-course-main'>
        <div className='explore-top'>
            <div className='explore-top-text'>
                <h1>Launch Your <br /> 
                 Dream <span>Career</span></h1>
                <p>
                    Build new skills. Take the first step towards your dream career by exploring our 
                    diverse courses and master in-demand skills with expert-led training. 
                </p>
                <div className="explore-search-container">
                    <input
                        type="text"
                        className="explore-search-input"
                        placeholder="What do you want to learn?"
                    />
                </div>
            </div>
            <div className='explore-top-img'>
                <img
                    src={deebug}
                    width="100%"
                    height="100%"
                    alt='Deebug'
                />
            </div>
        </div>
        {/* <div className='explore-course-learn'>
            <h1>Learn Everything You Need</h1>
            <p>10+ courses, Guided Projects, Specializations, and <br /> Professional Certificates</p>
            <div className='exp-cse-lrn-img'>
                <img
                    src={react}
                    width="55px"
                    height="55px"
                    alt='logo'
                />
                <img
                    src={node}
                    width="55px"
                    height="55px"
                    alt='logo'
                />
                <img
                    src={git}
                    width="55px"
                    height="55px"
                    alt='logo'
                />
                <img
                    src={mongo}
                    width="55px"
                    height="55px"
                    alt='logo'
                />
                <img
                    src={figma}
                    width="55px"
                    height="55px"
                    alt='logo'
                />
                <img
                    src={embed}
                    width="55px"
                    height="55px"
                    alt='logo'
                />
            </div>
        </div>
        <div className='explore-section-course'>
            <div className='explore-sec-cse1'>
                <h2>ONLINE COURSES</h2>
                <h1>Master Essential Skills</h1>
                <p>
                    Our self-paced online courses offer concise, expert-led tutorials designed to <br/>
                    help you quickly master new skills and advance your career.
                </p>
                <div className='explore-courses-ctn-props'>
                    <div className='explore-courses-ctn'>
                        <div className='explore-courses-ctn-img'>
                            <img
                                src={repo}
                                width="100%"
                                height="100%"
                                alt='logo'
                            />
                        </div>
                        <div className='explore-courses-ctn-text'>
                            <h2>How to create a repository </h2>
                            <p>Learn how to create and manage repositories in Git with our step-by-step guide.</p>
                            <div className='explore-courses-ctn-details'>
                                <img
                                    src={user}
                                    width="18px"
                                    height="18px"
                                    alt='icon'
                                />
                                <p>100+</p>
                                <img
                                    src={star}
                                    width="15px"
                                    height="15px"
                                    alt='icon'
                                />
                                <p>4.5</p>
                            </div>
                            <div className='explore-courses-ctn-price'>
                                <p>₦ 12,000</p>
                                <div className='explore-courses-ctn-level'>
                                    <p>Level</p>
                                <img
                                    src={point}
                                    width="5px"
                                    height="5px"
                                    alt='icon'
                                />
                                <p>Advanced</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='explore-courses-ctn'>
                        <div className='explore-courses-ctn-img'>
                            <img
                                src={figview}
                                width="100%"
                                height="100%"
                                alt='logo'
                            />
                        </div>
                        <div className='explore-courses-ctn-text'>
                            <h2>UX Design</h2>
                            <p>Learn to create intuitive and user-friendly interfaces for exceptional user experiences.</p>
                            <div className='explore-courses-ctn-details'>
                                <img
                                    src={user}
                                    width="18px"
                                    height="18px"
                                    alt='icon'
                                />
                                <p>40+</p>
                                <img
                                    src={star}
                                    width="15px"
                                    height="15px"
                                    alt='icon'
                                />
                                <p>4.5</p>
                            </div>
                            <div className='explore-courses-ctn-price'>
                                <p>₦ 45,000</p>
                                <div className='explore-courses-ctn-level'>
                                    <p>Level</p>
                                <img
                                    src={point}
                                    width="5px"
                                    height="5px"
                                    alt='icon'
                                />
                                <p>Intermediate</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='explore-courses-ctn'>
                        <div className='explore-courses-ctn-img'>
                            <img
                                src={resreview}
                                width="100%"
                                height="100%"
                                alt='logo'
                            />
                        </div>
                        <div className='explore-courses-ctn-text'>
                            <h2>Understanding Qualitative and Quantitative Research</h2>
                            <p>Learn to create intuitive and user-friendly interfaces for exceptional user experiences.</p>
                            <div className='explore-courses-ctn-details'>
                                <img
                                    src={user}
                                    width="18px"
                                    height="18px"
                                    alt='icon'
                                />
                                <p>26+</p>
                                <img
                                    src={star}
                                    width="15px"
                                    height="15px"
                                    alt='icon'
                                />
                                <p>4.9</p>
                            </div>
                            <div className='explore-courses-ctn-price'>
                                <p>₦ 24,000</p>
                                <div className='explore-courses-ctn-level'>
                                    <p>Level</p>
                                <img
                                    src={point}
                                    width="5px"
                                    height="5px"
                                    alt='icon'
                                />
                                <p>Beginner</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='explore-courses-ctn'>
                        <div className='explore-courses-ctn-img'>
                            <img
                                src={wire}
                                width="100%"
                                height="100%"
                                alt='logo'
                            />
                        </div>
                        <div className='explore-courses-ctn-text'>
                            <h2>Wireframing in UX Design. </h2>
                            <p>Master wireframing in UX Design to create effective user interface blueprints</p>
                            <div className='explore-courses-ctn-details'>
                                <img
                                    src={user}
                                    width="18px"
                                    height="18px"
                                    alt='icon'
                                />
                                <p>200+</p>
                                <img
                                    src={star}
                                    width="15px"
                                    height="15px"
                                    alt='icon'
                                />
                                <p>5.0</p>
                            </div>
                            <div className='explore-courses-ctn-price'>
                                <p>₦ 15,000</p>
                                <div className='explore-courses-ctn-level'>
                                    <p>Level</p>
                                <img
                                    src={point}
                                    width="5px"
                                    height="5px"
                                    alt='icon'
                                />
                                <p>Beginner</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <a href='/maintenance' className='explore-cse-view'>View More</a>
            </div>
            <div className='explore-sec-cse2'>
                <h2>CAREER COURSES</h2>
                <h1>Professional Certifications</h1>
                <p>
                    Earn industry-recognized certifications and enhance your professional skills <br />
                    with our expert-led courses.
                </p>
                <div className='explore-courses-ctn-props'>
                    <div className='explore-courses-ctn'>
                        <div className='explore-courses-ctn-img'>
                            <img
                                src={mernrev}
                                width="100%"
                                height="100%"
                                alt='logo'
                            />
                        </div>
                        <div className='explore-courses-ctn-text'>
                            <h3>Professional Certification:</h3>
                            <h2>Full Stack (MERN) Software Development</h2>
                            <div className='explore-courses-ctn-text-img'>
                                <img
                                    src={reactblue}
                                    width="14px"
                                    height="14px"
                                    alt='icon'
                                />
                                <img
                                    src={nodejsblue}
                                    width="14px"
                                    height="14px"
                                    alt='icon'
                                />
                                <img
                                    src={githublue}
                                    width="14px"
                                    height="14px"
                                    alt='icon'
                                />
                                <img
                                    src={mongodblue}
                                    width="14px"
                                    height="14px"
                                    alt='icon'
                                />                                
                            </div>
                        </div>
                    </div>
                    <div className='explore-courses-ctn'>
                        <div className='explore-courses-ctn-img'>
                            <img
                                src={noderev}
                                width="100%"
                                height="100%"
                                alt='logo'
                            />
                        </div>
                        <div className='explore-courses-ctn-text'>
                            <h3>Professional Certification:</h3>
                            <h2>Backend (NodeJS & Express) Software Developer</h2>
                            <div className='explore-courses-ctn-text-img'>
                                <img
                                    src={reactblue}
                                    width="14px"
                                    height="14px"
                                    alt='icon'
                                />
                                <img
                                    src={nodejsblue}
                                    width="14px"
                                    height="14px"
                                    alt='icon'
                                />
                                <img
                                    src={githublue}
                                    width="14px"
                                    height="14px"
                                    alt='icon'
                                />
                                <img
                                    src={mongodblue}
                                    width="14px"
                                    height="14px"
                                    alt='icon'
                                />                                
                            </div>
                        </div>
                    </div>
                    <div className='explore-courses-ctn'>
                        <div className='explore-courses-ctn-img'>
                            <img
                                src={reactrev}
                                width="100%"
                                height="100%"
                                alt='logo'
                            />
                        </div>
                        <div className='explore-courses-ctn-text'>
                            <h3>Professional Certification:</h3>
                            <h2>Frontend (ReactJS) Software Developer</h2>
                            <div className='explore-courses-ctn-text-img'>
                                <img
                                    src={reactblue}
                                    width="14px"
                                    height="14px"
                                    alt='icon'
                                />
                                <img
                                    src={nodejsblue}
                                    width="14px"
                                    height="14px"
                                    alt='icon'
                                />
                                <img
                                    src={githublue}
                                    width="14px"
                                    height="14px"
                                    alt='icon'
                                />
                                <img
                                    src={mongodblue}
                                    width="14px"
                                    height="14px"
                                    alt='icon'
                                />                                
                            </div>
                        </div>
                    </div>
                    <div className='explore-courses-ctn'>
                        <div className='explore-courses-ctn-img'>
                            <img
                                src={figview}
                                width="100%"
                                height="100%"
                                alt='logo'
                            />
                        </div>
                        <div className='explore-courses-ctn-text'>
                            <h3>Professional Certification:</h3>
                            <h2>UX Design</h2>
                            <div className='explore-courses-ctn-text-img'>
                                <img
                                    src={reactblue}
                                    width="14px"
                                    height="14px"
                                    alt='icon'
                                />
                                <img
                                    src={nodejsblue}
                                    width="14px"
                                    height="14px"
                                    alt='icon'
                                />
                                <img
                                    src={githublue}
                                    width="14px"
                                    height="14px"
                                    alt='icon'
                                />
                                <img
                                    src={mongodblue}
                                    width="14px"
                                    height="14px"
                                    alt='icon'
                                />                                
                            </div>
                        </div>
                    </div>
                </div>
                <a href='/maintenance' className='explore-cse-view'>View More</a>
            </div>
            <div className='explore-sec-cse1'>
                <h2>SKILL SPRINTS</h2>
                <h1>Skill Builder Courses.</h1>
                <p>
                    Gain new skills with our focused and practical courses, designed for maximum <br />
                    impact in a short time.
                </p>
                <div className='explore-courses-ctn-props'>
                    <div className='explore-courses-ctn1'>
                        <div className='explore-courses-ctn-img1'>
                            <img
                                src={webskillprev}
                                width="100%"
                                height="100%"
                                alt='logo'
                            />
                        </div>
                        <div className='explore-courses-ctn-text1'>
                            <h1>Fullstack Web Development </h1>
                            <h2>Introduction to basic programming: HTML, CSS, Javascript.</h2>
                            <p>Deebug Certification</p>
                        </div>
                    </div>
                    <div className='explore-courses-ctn1'>
                        <div className='explore-courses-ctn-img1'>
                            <img
                                src={embskillpreview}
                                width="100%"
                                height="100%"
                                alt='logo'
                            />
                        </div>
                        <div className='explore-courses-ctn-text1'>
                            <h1>Embedded Systems</h1>
                            <h2>Introduction to basic programming: HTML, CSS, Javascript.</h2>
                            <p>Deebug Certification</p>
                        </div>
                    </div>
                    <div className='explore-courses-ctn1'>
                        <div className='explore-courses-ctn-img1'>
                            <img
                                src={uiskillprev}
                                width="100%"
                                height="100%"
                                alt='logo'
                            />
                        </div>
                        <div className='explore-courses-ctn-text1'>
                            <h1>Fullstack Product Design</h1>
                            <h2>Introduction to basic programming: HTML, CSS, Javascript.</h2>
                            <p>Deebug Certification</p>
                        </div>
                    </div>
                </div>
                <a href='/maintenance' className='explore-cse-view'>View More</a>
            </div>
            <div className='explore-sec-cse2'>
                <h2>CAREER COURSES</h2>
                <h1>Professional Certifications</h1>
                <p>
                    Earn industry-recognized certifications and enhance your professional skills <br />
                    with our expert-led courses.
                </p>
                <div className='explore-courses-ctn-props'>
                    <div className='explore-courses-ctn1'>
                        <div className='explore-courses-ctn-img1'>
                            <img
                                src={mernindprev}
                                width="100%"
                                height="100%"
                                alt='logo'
                            />
                        </div>
                        <div className='explore-courses-ctn-text1'>
                            <h1>Fullstack Web <br/> Development </h1>
                            <h2>Introduction to basic <br/> programming: HTML, CSS, Javascript.</h2>
                            <p>Deebug Certification</p>
                        </div>
                    </div>
                    <div className='explore-courses-ctn1'>
                        <div className='explore-courses-ctn-img1'>
                            <img
                                src={uindprev}
                                width="100%"
                                height="100%"
                                alt='logo'
                            />
                        </div>
                        <div className='explore-courses-ctn-text1'>
                            <h1>UX/UI Design</h1>
                            <h2>Introduction to basic <br/> programming: HTML, CSS, Javascript.</h2>
                            <p>Deebug Certification</p>
                        </div>
                    </div>
                    <div className='explore-courses-ctn1'>
                        <div className='explore-courses-ctn-img1'>
                            <img
                                src={cyberindpreview}
                                width="100%"
                                height="100%"
                                alt='logo'
                            />
                        </div>
                        <div className='explore-courses-ctn-text1'>
                            <h1>Cybersecurity</h1>
                            <h2>Introduction to basic <br/> programming: HTML, CSS, Javascript.</h2>
                            <p>Deebug Certification</p>
                        </div>
                    </div>
                </div>
                <a href='/maintenance' className='explore-cse-view'>View More</a>
            </div>
        </div>
        <div className='testimonial-main-container1'>
            <h1>Success Stories</h1>
            <div className='testimonial-sub-main-container1'>
                <button className='testimonial-btn1' onClick={() => handleScroll('left')}>
                    <img 
                        src={previous} 
                        width="100%" 
                        height="100%" 
                        alt='next'                             
                    />
                </button>
                <div className='testimonial-container1' ref={containerRef}>
                    {testimonials.map((testimonial, index) => (
                        <div className='testimonial-story1' key={index}>
                            <div className='testimonial-text1'>
                                <h1>{testimonial.text}</h1>
                                <div className='testimonial-personal1'>
                                <h2>{testimonial.name}</h2>
                                <p>{testimonial.class}</p>
                                </div>
                            </div>
                            <div className='testimonial-image1'>
                                <img
                                src={testimonial.image}
                                width="100%"
                                height="100%"
                                alt='testimonial'
                                className='testy-image'
                                />
                            </div>
                        </div>
                    ))}
                </div>
                <button className='testimonial-btn1' onClick={() => handleScroll('right')}>
                    <img 
                        src={next} 
                        width="100%" 
                        height="100%" 
                        alt='next'                             
                    />
                </button>
                <div className='testimonial-btn-prt-ctn'>
                    <button className='testimonial-btn1' onClick={() => handleScroll('left')}>
                        <img 
                            src={previous} 
                            width="100%" 
                            height="100%" 
                            alt='next'                             
                        />
                    </button>
                    <button className='testimonial-btn1' onClick={() => handleScroll('right')}>
                        <img 
                            src={next} 
                            width="100%" 
                            height="100%" 
                            alt='next'                             
                        />
                    </button>
                </div>
            </div>
            <div className='testimonial-btn-container3'>
                {testimonials.map((_, index) => (
                <button
                    key={index}
                    className={`indicator-btn1 ${index === currentIndex ? 'active' : ''}`}
                    onClick={() => setCurrentIndex(index)}
                >
                    <span className="indicator1" />
                </button>
                ))}
            </div>
        </div> */}
    </div>
  )
}

export default ExploreCourse