import React, {useState, useEffect,useRef} from 'react';
import '../../components/courses/cybersecurity.css';
import Popup from '../popup/PopUp';
import { Link } from 'react-router-dom';
import testyimage from '../../images/testyimage.webp';
import cybersecwallpaper from '../../images/cybersecwallpaper.webp';
import cybersecbground from '../../images/datasciencebground.webp';
import cybersecbground2 from "../../images/fullwebdev.webp";
import cyberseccheck from '../../images/cyberseccheck.png';
import cybersecapply from '../../images/cybersecapply.png';
import previous from '../../images/backblack.png';
import next from '../../images/forwardblack.png';
import cybersecletter from '../../images/cybersecletter.png';
import cyberseccall from '../../images/cyberseccall.png';
import teststory1 from '../../images/teststory1.webp';
import teststory2 from '../../images/teststory2.webp';
import teststory3 from '../../images/teststory3.webp';
import teststory4 from '../../images/teststory4.webp';
import teststory5 from '../../images/teststory5.webp';
import teststory6 from '../../images/teststory6.webp';
import teststory7 from '../../images/teststory7.webp';      
import cybersecchat from '../../images/cybersecchat.png';
import ChevronRight from "../../images/chevron_right_blue.png";
import arrow_down from "../../images/arrow_down.png"


const BootcampCyberSecurity = () => {
    const [scrollPosition, setScrollPosition] = useState(0);
    const [isInView, setIsInView] = useState(false);
  
    
   
const testimonials = [
    {
      text: "My time at Deebug taking the Frontend Web Development course was worth it. The knowledgeable instructors were always helpful. I gained valuable skills, making it a rewarding learning experience.",
      name: "Ononyiwita Godsglory",
      class: "Class of 2024",
      image: teststory4
    },
    {
      text: "“It was a wonderful experience learning my first tech skill at Deebug Institute. What amazed me the most was how the tutors broke down every aspect of what was been taught. They made it seem so easy.”",
      name: "Tunde Oriyomi",
      class: "Class of 2024",
      image: teststory7
    },
    {
      text: "“My time at Deebug taking the Frontend Web Development course was worth it. The knowledgeable instructors were always helpful. I gained valuable skills, making it a rewarding learning experience.”",
      name: "Precious",
      class: "Class of 2024",
      image: teststory3
    },    
    {
      text: "The projects at Deebug helped me build a solid portfolio. I feel prepared to tackle any frontend development challenge.",
      name: "Michael Brown",
      class: "Class of 2024",
      image: teststory5
    }
  ];
  
  
    const [currentIndex, setCurrentIndex] = useState(0);
    const containerRef = useRef(null);
    const containerRef1 = useRef(null);
  
    const handleScroll = (direction) => {
      const container = containerRef.current;
      const containerWidth = container.clientWidth;
      const newIndex = direction === 'left'
        ? Math.max(currentIndex - 1, 0)
        : Math.min(currentIndex + 1, testimonials.length - 1);
  
      setCurrentIndex(newIndex);
      container.scrollTo({
        left: newIndex * containerWidth,
        behavior: 'smooth'
      });
    };


    
    const [showPopup, setShowPopup] = useState(false);

    const openPopup = () => {
        setShowPopup(true);
    };

    const closePopup = () => {
        setShowPopup(false);
    };

    const handleProceed = () => {
        window.location.href = '/BootcampRegistration';
        // Handle proceed action
    };
    
    useEffect(() => {
        const observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                entry.target.classList.add('animate');
              } else {
                entry.target.classList.remove('animate');
              }
            });
          },
          { threshold: 0.1 } // Adjust threshold as needed
        );
    
        if (containerRef1.current) {
          observer.observe(containerRef1.current);
        }
    
        return () => {
          if (containerRef1.current) {
            observer.unobserve(containerRef1.current);
          }
        };
      }, []);

         
    const AnimateText = ({ text }) => {
        const textRef = useRef(null);
        const [isInView, setIsInView] = useState(false);
    
        useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                setIsInView(true);
                } else {
                setIsInView(false); // Reset animation when out of view
                }
            });
            },
            { threshold: 0.1 } // Adjust the threshold as needed
        );
    
        if (textRef.current) {
            observer.observe(textRef.current);
        }
    
        return () => {
            if (textRef.current) {
            observer.unobserve(textRef.current);
            }
        };
        }, []);
    
        return (
        <div className="animate-container">
            <h1
            ref={textRef}
            className={`cybersec-animatedText ${isInView ? 'cybersec-animate' : ''}`}
            >
            {text.split('').map((char, index) => (
                <span key={index} style={{ '--char-index': index }}>
                {char}
                </span>
            ))}
            </h1>
        </div>
        );
    };



  return (
    <div>
        <div className="cybersec-course-sub-navbar">
            <p>CYBER SECURITY</p>
            <div className='cybersec-nav-apply'>
                <span  onClick={openPopup} className="cybersec-course-sub-navbar-selection">Apply</span>
                {showPopup && (
                    <Popup
                    title="Bootcamp Training Registration"
                    content="Please note that during the registration process for our Bootcamp Training program, you will be required to provide some information."
                    onClose={closePopup}
                    onProceed={handleProceed}
                    />
                )}
            </div>
        </div>
        <div className='network-course-background'>
            <div className='cybersec-course-bgimg'>
                <div className="App">
                    <AnimateText text="CYBER" />
                    <AnimateText text="SECURITY" />
                </div>
                <Link to="/BootcampRegistration" className='apply-now-link'>
                    <div className='course-apply-now' ref={containerRef1}> <button >Apply now</button> 
                        <img
                            src={arrow_down}
                            width="35px"
                            height="35px"
                            alt='icon'
                        /> 
                    </div>                
                </Link>
            </div>
        </div>
        <div className='cybersec-sys-course-content'>
            <p>Course details</p>
            <h1>Everything you need to kick-start your journey</h1>
            <div className='cybersec-sys-price-content'>
                <div className='cybersec-sys-price-details'>
                    <h1>75<span>k</span></h1>
                    <span>Price(Regular)</span>
                </div>
                <div className='cybersec-sys-price-details'>
                    <h1>65<span>k</span></h1>
                    <span>Price(IT)</span>
                </div>
                <div className='cybersec-sys-price-details'>
                    <h1>3<span>:</span>4</h1>
                    <span>Months:hrs per week</span>
                </div>
            </div>
            <div className='cybersec-sys-course-about'>
                <h1>Overview</h1>
                <p>
                    Congratulations on taking this course. Cyber security is arguably one of the most important
                    courses anyone would ever take, especially in the 21st century. The security of information and
                    the networks through which information flows is paramount. As cyber security is the application
                    of technologies, processes, and controls to protect systems, networks, programs, devices and
                    data from cyber attacks.

                </p>
                <p>
                    As a cyber security expert, your job would entail the reduction of the risk of cyber attacks and
                    the establishment of protection protocols against the unauthorized exploitation of these
                    systems, networks and technologies. In this course, you’ll learn not only how to be a pen tester -
                    Ethical hacker, but also how to function in the office of a CISO (Chief Information Security
                    Officer).
                </p>
            </div>
            <div className='ui-ux-background-image'>
                <img
                    src={cybersecbground}
                    width="100%"
                    height="100%"
                    alt='icon'
                    className='course-background-attract'
                />
            </div>
            <div className='cybersec-course-highlights'>
                <h1>Course Highlights</h1>
                <p>
                    You are about to embark on an exciting journey of 
                    learning how to program. For the duration of this course 
                    you will be learning;
                </p>
                <div className='cybersec-course-content-highlight'>
                    <div className='course-content-ux'>
                        <img
                            src={cyberseccheck}
                            alt='icon'
                            width="25px"
                            height="20px"
                        />
                        <p>Introduction to Cyber Security</p>
                    </div>
                    <div className='course-content-ux'>
                        <img
                            src={cyberseccheck}
                            alt='icon'
                            width="25px"
                            height="20px"
                        />
                        <p>Network Security Fundamentals</p>
                    </div>
                    <div className='course-content-ux'>
                        <img
                            src={cyberseccheck}
                            alt='icon'
                            width="25px"
                            height="20px"
                        />
                        <p>Exploratory Data Analysis</p>
                    </div>
                    <div className='course-content-ux'>
                        <img
                            src={cyberseccheck}
                            alt='icon'
                            width="25px"
                            height="20px"
                        />
                        <p>Cyber Threats and Attack Vectors</p>
                    </div>
                    <div className='course-content-ux'>
                        <img
                            src={cyberseccheck}
                            alt='icon'
                            width="25px"
                            height="20px"
                        />
                        <p>Secure Coding Practices</p>
                    </div>
                    <div className='course-content-ux'>
                        <img
                            src={cyberseccheck}
                            alt='icon'
                            width="25px"
                            height="20px"
                        />
                        <p>Vulnerability Assessment and Penetration Testing</p>
                    </div>
                    <div className='course-content-ux'>
                        <img
                            src={cyberseccheck}
                            alt='icon'
                            width="25px"
                            height="20px"
                        />
                        <p>Incident Response and Management</p>
                    </div>
                    <div className='course-content-ux'>
                        <img
                            src={cyberseccheck}
                            alt='icon'
                            width="25px"
                            height="20px"
                        />
                        <p>Security Information and Event Management (SIEM)</p>
                    </div>
                    <div className='course-content-ux'>
                        <img
                            src={cyberseccheck}
                            alt='icon'
                            width="25px"
                            height="20px"
                        />
                        <p>Ethical Hacking and Countermeasures</p>
                    </div>
                    <div className='course-content-ux'>
                        <img
                            src={cyberseccheck}
                            alt='icon'
                            width="25px"
                            height="20px"
                        />
                        <p>Cyber Security Policies and Compliance</p>
                    </div>
                    <div className='course-content-ux'>
                        <img
                            src={cyberseccheck}
                            alt='icon'
                            width="25px"
                            height="20px"
                        />
                        <p>Cloud Security Essentials</p>
                    </div>
                    <div className='course-content-ux'>
                        <img
                            src={cyberseccheck}
                            alt='icon'
                            width="25px"
                            height="20px"
                        />
                        <p>Advanced Persistent Threats (APT) and Threat Intelligence</p>
                    </div>
                    <div className='course-content-ux'>
                        <img
                            src={cyberseccheck}
                            alt='icon'
                            width="25px"
                            height="20px"
                        />
                        <p>Capstone Project</p>
                    </div>
                </div>
            </div>
            <div className='ui-ux-background-image'>
                <img
                    src={cybersecbground2}
                    width="100%"
                    height="100%"
                    alt='icon'
                    className='course-background-attract'
                />
            </div>
            <div className='cybersec-course-highlights1'>
                <div className='cybersec-course-highlights'>
                    <h1>
                    Learning Outcome
                    </h1>
                    <p>Upon completion, graduates will be able to:</p>
                    <div className='cybersec-course-content-highlight'>
                        <div className='course-content-ux'>
                            <img
                                src={cyberseccheck}
                                alt='icon'
                                width="25px"
                                height="20px"
                            />
                            <p>Identify and mitigate various types of cyber threats and attack vectors.</p>
                        </div>
                        <div className='course-content-ux'>
                            <img
                                src={cyberseccheck}
                                alt='icon'
                                width="25px"
                                height="20px"
                            />
                            <p>Implement and manage network security measures.</p>
                        </div>
                        <div className='course-content-ux'>
                            <img
                                src={cyberseccheck}
                                alt='icon'
                                width="25px"
                                height="20px"
                            />
                            <p>Utilize cryptographic techniques to secure data.</p>
                        </div>
                        <div className='course-content-ux'>
                            <img
                                src={cyberseccheck}
                                alt='icon'
                                width="25px"
                                height="20px"
                            />
                            <p>Conduct vulnerability assessments and penetration tests to identify security weaknesses.</p>
                        </div>
                        <div className='course-content-ux'>
                            <img
                                src={cyberseccheck}
                                alt='icon'
                                width="25px"
                                height="20px"
                            />
                            <p>Develop and execute effective incident response plans and manage cyber security incidents.</p>
                        </div>
                    </div>
                </div>
                <div className='ui-ux-course-content-highlight'>
                    <div className='course-content-ux1'>
                        <p>Ready to embark on this exciting journey? Take the first step toward shaping your future in tech.</p>
                        <img
                            src={cybersecapply}
                            alt='icon'
                            width="70px"
                            height="70px"
                            className='checkmark-apply'
                            onClick={openPopup}
                        />
                    </div>
                </div>
            </div>
        </div>
        <div className='experience-story'>
            <h1>The Experience</h1>
            <p>Don't just take our word for it! Hear what our past students have to say about their experience</p>
            <div className='testimonial-main-container'>
                <div className='testimonial-sub-main-container'>
                    <button className='testimonial-btn' onClick={() => handleScroll('left')}>
                        <img 
                            src={previous} 
                            width="100%" 
                            height="100%" 
                            alt='next'                             
                        />
                    </button>
                    <div className='testimonial-container' ref={containerRef}>
                        {testimonials.map((testimonial, index) => (
                            <div className='testimonial-story' key={index}>
                                <div className='testimonial-text'>
                                    <h1>"{testimonial.text}"</h1>
                                    <div className='testimonial-personal'>
                                    <h2>{testimonial.name}</h2>
                                    <p>{testimonial.class}</p>
                                    </div>
                                </div>
                                <div className='testimonial-image'>
                                    <img
                                    src={testimonial.image}
                                    width="100%"
                                    height="100%"
                                    alt='testimonial'
                                    className='testy-image'
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                    <button className='testimonial-btn' onClick={() => handleScroll('right')}>
                        <img 
                            src={next} 
                            width="100%" 
                            height="100%" 
                            alt='next'                             
                        />
                    </button>
                    <div className='testimonial-btn-prt-ctn'>
                        <button className='testimonial-btn1' onClick={() => handleScroll('left')}>
                            <img 
                                src={previous} 
                                width="100%" 
                                height="100%" 
                                alt='next'                             
                            />
                        </button>
                        <button className='testimonial-btn1' onClick={() => handleScroll('right')}>
                            <img 
                                src={next} 
                                width="100%" 
                                height="100%" 
                                alt='next'                             
                            />
                        </button>
                    </div>
                </div>
                <div className='testimonial-btn-container2'>
                    {testimonials.map((_, index) => (
                    <button
                        key={index}
                        className={`indicator-btn ${index === currentIndex ? 'active' : ''}`}
                        onClick={() => setCurrentIndex(index)}
                    >
                        <span className="indicator" />
                    </button>
                    ))}
                </div>
            </div>
            <div className='call-course-enquiries'>
                <h1>For More Inquiries</h1>
                    <div className='contact-course-container'>
                    <div className='contact-course1'>
                        <div className='call-course-image'>
                            <img
                                src={cybersecchat}
                                width="100%"
                                height="100%"
                                alt='icon'
                            />
                        </div>
                        <h3>chat</h3>
                        <p>deebug.linkedin</p>
                    </div>
                    <div className='contact-course1'>
                        <div className='call-course-image'>
                            <img
                                src={cyberseccall}
                                width="100%"
                                height="100%"
                                alt='icon'
                            />
                        </div>
                        <h3>Call</h3>
                        <p>+2349162627333</p>
                    </div>
                    <div className='contact-course1'>
                        <div className='call-course-image'>
                            <img
                                src={cybersecletter}
                                width="100%"
                                height="100%"
                                alt='icon'
                            />
                        </div>
                        <h3>Email</h3>
                        <p>Info@deebug.org</p>
                    </div>
                </div>
            </div>
            <div className='course-inquiries'>
                <h1>Learn more about education <br /> at Deebug</h1>
                <div className="edu-learn-btn"><Link to="/IndustrialTrainingCourses" className="section_learn_more"> Go to Industrial Training Overview</Link><img src={ChevronRight} className="learn_more_icon" /></div>
                <div className="edu-learn-btn"><Link to="/RegularStudents" className="section_learn_more"> Go to Regular Students</Link><img src={ChevronRight} className="learn_more_icon" /></div>
            </div>
        </div>
    </div>
  )
}


export default BootcampCyberSecurity