import React from 'react';
import "./naijapharm.css"
import logo from "../../images/pharmlogo.webp";
import network from "../../images/pharmphone1.webp";
import network1 from "../../images/pharmphone5.webp";
import event from "../../images/pharmevent.webp";
import meet from "../../images/pharmmeet.webp";
import access from "../../images/pharmaccess.webp";
import authors from "../../images/pharmauthor.webp";
import vote from "../../images/pharmvote.webp";
import pharm1 from "../../images/medplanmock.png";
import poll from "../../images/pharmpoll.webp";
import feature2 from "../../images/pharmpalm.webp";
import feature1 from "../../images/pharmpay.webp";
import googleplay from "../../images/googleplay.png";
import appstore from "../../images/appstore.png";
import elderly from "../../images/pharmsuccess.webp";
import black from "../../images/readblack.png";
import black1 from "../../images/read_more_blk.png"
import success1 from "../../images/success1.webp";
import success2 from "../../images/success2.webp";
import success3 from "../../images/success3.webp";
import success4 from "../../images/success4.webp";



const NaijaPharm = () => {
  return (
    <div>
        <div className='naijapharm-top'>
            <img
                src={logo}
                width="150px"
                height="150px"
                alt='logo'
            />
            <h1>Connect. Learn. Find Jobs.</h1>
            <p>
                A  platform for pharmacists to connect with one another, 
                learn from industry experts, find job opportunities, and 
                grow their careers. With a user-friendly interface Naijapharm 
                makes it easier than ever for pharmacists to stay informed on 
                latest industry news, network with other professionals.
            </p>
        </div>
        <div className='naijapharm-content'>
            <div className='naijapharm-content-props1'>
                <h1>Expand Your Network, Empower Your Practice.</h1>
                <p>
                    Build your professional network by connecting with 
                    thousands of like-minded individuals in the pharmaceutical industry.
                </p>
                <div className='naijapharm-ctn'>                    
                    <div className='naijapharm-props-ctn'>
                        <p>Share with intention</p>
                        <h3>
                            Expand your reach, forge valuable connections, and stay updated on industry trends.
                        </h3>
                        <img
                            src={network}
                            width="90%"
                            height="100%"
                            alt='icon'
                        />
                    </div>
                    <div className='naijapharm-props-ctn'>
                        <p>Expand your connection</p>
                        <h3>
                            Join groups tailored to specific interests. Engage with 
                            like-minded individuals, share insights, and participate 
                            in focused discussions to deepen your connections and expand your knowledge base.
                        </h3>
                        <img
                            src={network}
                            width="90%"
                            height="100%"
                            alt='icon'
                        />
                    </div>
                </div>
            </div>
            <div className='naijapharm-content-props2'>
                <h1>Discover your next job opportunity.</h1>
                <p>
                    finding the perfect job has never been easier. Discover 
                    medical opportunities that matches your skills, experience, and preferences.
                </p>
                <div className='naijapharm-ctn'>                    
                    <div className='naijapharm-props-ctn2'>
                        <p>Find your perfect match</p>
                        <h3>
                            Browse through our friendly platform to find a wide range 
                            of  job opportunities tailored to your skills and career goals. 
                        </h3>
                        <img
                            src={network1}
                            width="90%"
                            height="100%"
                            alt='icon'
                        />
                    </div>
                    <div className='naijapharm-props-ctn2'>
                        <p>Share the opportunities</p>
                        <h3>
                            Share your job openings on our platform  and connect with a vast pool of qualified candidates. Our user-friendly interface makes it easy to reach potential hires and streamline the recruitment process
                        </h3>
                        <img
                            src={network1}
                            width="90%"
                            height="100%"
                            alt='icon'
                        />
                    </div>
                </div>
            </div>
            <div className='naijapharm-content-props3'>
                <h1>Attend nationwide events.</h1>
                <p>
                    Stay ahead in your field by attending exclusive medical events worldwide. Whether virtual or onsite, engage seamlessly with industry experts, share insights, and foster valuable connections. 
                </p>
                <div className='naijapharm-ctn'>                    
                    <div className='naijapharm-props-ctn-dsn1'>
                        <p>Attend Medical Events</p>
                        <h3>
                            Connect with others by seamlessly participating in upcoming medical events, conferences, workshops, or seminars virtually or in person.
                        </h3>
                        <img
                            src={event}
                            width="450px"
                            height="480px"
                            alt='icon'
                        />
                    </div>
                    <div className='naijapharm-props-ctn3'>
                        <p>Be the host with the most</p>
                        <h3>
                        Connect with others by seamlessly participating in upcoming medical events, conferences, workshops, or seminars virtually or in person.
                        </h3>
                        <img
                            src={network1}
                            width="90%"
                            height="100%"
                            alt='icon'
                        />
                    </div>
                </div>
            </div>
            <div className='naijapharm-content-props4'>
                <div className='pharm-content4-text'>
                    <p>Connect anywhere </p>
                    <h3>
                        From conferences to workshops, attend events seamlessly, 
                        physically or from the comfort of your own home. Explore 
                        opportunities, connect with peers, and stay updated on the 
                        latest in your field. Start connecting, wherever you are.
                    </h3>
                    <img
                        src={meet}
                        width="353px"
                        height="253px"
                        alt='icon'
                    />
                </div>
                <img
                    src={access}
                    width="100%"
                    height="100%"
                    alt='icon'
                />
            </div>
            <div className='naijapharm-content-props5'>
                <h1>Access educational resources to help grow your knowledge.</h1>
                <p>
                    Expand your horizons and enhance your expertise with our platform's educational tools and resources.
                </p>
                <div className='naijapharm-ctn'>                    
                    <div className='naijapharm-props-ctn5'>
                        <p>Stay ahead</p>
                        <h3>
                            Access a wealth of educational materials, including articles, 
                            videos, webinars, and courses, curated to provide valuable 
                            insights and support your professional growth.
                        </h3>
                        <img
                            src={network1}
                            width="90%"
                            height="100%"
                            alt='icon'
                        />
                    </div>
                    <div className='naijapharm-props-ctn5'>
                        <p>Connect and collaborate</p>
                        <h2>
                            Interact with professionals and experts to enhance your professional 
                            journey. Whether you're seeking advice, discussing best practices, or 
                            simply connecting with like-minded individuals
                        </h2>
                        <img
                            src={authors}
                            width="400px"
                            height="480px"
                            alt='icon'
                            className='pharmauthor'
                        />
                    </div>
                </div>
            </div>
            <div className='naijapharm-content-props6'>
                <h1>Conduct and share your pharm-based research.</h1>
                <p>
                    Contribute to groundbreaking research initiatives, by utilizing 
                    free research tool carry out surveys, and analyze data all within 
                    a supportive and accessible environment.
                </p>
                <div className='naijapharm-ctn'>                    
                    <div className='naijapharm-props-ctn-dsn2'>
                        <p>Engage for Insights.</p>
                        <h3>
                            Conduct mini surveys tailored to your interests or 
                            research needs. Engage with your audience, gather data, 
                            and gain valuable insights to inform your decisions.
                        </h3>
                        <img
                            src={vote}
                            width="80%"
                            height="480px"
                            alt='icon'
                        />
                    </div>
                    <div className='naijapharm-props-ctn6'>
                        <p>Be part of something bigger</p>
                        <h3>
                            Explore a variety of surveys created by fellow pharmacists 
                            and share your opinions on diverse topics. Your input can 
                            shape decisions and drive change
                        </h3>
                        <img
                            src={network1}
                            width="90%"
                            height="100%"
                            alt='icon'
                        />
                    </div>
                </div>
                <div className='pharm-content6-text'>
                    <p>Share your findings</p>
                    <h3>
                        Share your discoveries with the community. Showcase their 
                        research findings and insights, sparking discussion and 
                        inspiring others in the field.
                    </h3>
                    <div className='pharm-content6-img'>
                        <img
                            src={pharm1}
                            width="564px"
                            height="500px"
                            alt='icon'
                        />
                        <img
                            src={pharm1}
                            width="100%"
                            height="500px"
                            alt='icon'
                        />
                    </div>
                </div>
            </div>
            <div className='naijapharm-content-props7'>
                <h1>Ask your questions, share your curiosity</h1>
                <p>
                    Satisfy your curiosity using our friendly poll feature to hear 
                    what others think about certain matters. Connect, engage, and 
                    spark discussions within the community, fostering a culture of 
                    open dialogue and mutual learning
                </p>
                <div className='naijapharm-ctn'>   
                    <div className='naijapharm-props-ctn7'>
                        <p>Share you opinion</p>
                        <h2>
                            Create your own polls and gather valuable insights about health 
                            trends, or seek opinions from the health community. Your polls 
                            can provide you with the answers you're looking for.
                        </h2>
                        <img
                            src={poll}
                            width="400px"
                            height="480px"
                            alt='icon'
                            className='pharmauthor'
                        />
                    </div>                 
                    <div className='naijapharm-props-ctn7'>
                        <p>Your opinions count</p>
                        <h3>
                            Explore polls created by fellow members, leave comments, 
                            and share your own perspective. Engage with others, gain 
                            valuable insights, and be part of shaping our collective knowledge.
                        </h3>
                        <img
                            src={network1}
                            width="90%"
                            height="100%"
                            alt='icon'
                        />
                    </div>
                </div>
            </div>
        </div>
        <div className='naijapharm-more'>
            <h1>And so much more.</h1>            
            <div className='naijapharm-features-ctn'>
                <div className='naijapharm-features-prop'>
                    <img
                        src={feature1}
                        width="70px"
                        height="60px"
                        alt='icon'
                    />
                    <h3>
                        <span>Pharm Dues</span> Never miss a deadline. Pharmacists can 
                        conveniently pay their pharmacy dues directly through the app, 
                        ensuring seamless compliance and peace of mind.
                    </h3>
                </div>
                <div className='naijapharm-features-prop'>
                    <img
                        src={feature2}
                        width="70px"
                        height="60px"
                        alt='icon'
                    />
                    <h3>
                        <span>Drug Bank</span> Search for drugs and access comprehensive information, 
                        including drug facts, dosage, and usage guidelines. Stay informed and make 
                        confident decisions in your practice."
                    </h3>
                </div>
                <div className='naijapharm-features-prop'>
                    <img
                        src={feature2}
                        width="70px"
                        height="60px"
                        alt='icon'
                    />
                    <h3>
                        <span>News</span> Share and discover the latest happenings in the pharmaceutical 
                        world, keeping you informed and engaged.
                    </h3>
                </div>
                <div className='naijapharm-features-prop'>
                    <img
                        src={feature2}
                        width="70px"
                        height="60px"
                        alt='icon'
                    />
                    <h3>
                        <span>Collaborate</span> find your perfect tech job through naijapharm. 
                        Showcase your skills and connect with potential employers or companies.
                    </h3>
                </div>
            </div>
        </div>
        <div className='naijapharm-buttom'>
            <div className='naijapharm-download'>
                <div className='download-pharm-text'>
                    <h1>
                        Connect, empower, and elevate your pharmacy practice 
                        with the essential app for Nigerian pharmacists.
                    </h1>
                    <div className='pharm-download-btn'>
                        <button className='download-heurekka-btn'>
                            <img
                                src={googleplay}
                                width="100%"
                                height="100%"
                                alt='icon'
                            />
                        </button>
                        <button className='download-heurekka-btn'>
                            <img
                                src={appstore}
                                width="100%"
                                height="100%"
                                alt='icon'
                            />
                        </button>
                    </div>
                </div>
                <div className='download-pharm-img'>
                    <img
                        src={pharm1}
                        width="472px"
                        height="100%"
                        alt='icon'
                    />
                </div>
            </div>
            <div className='naijapharm-success'>
                <p>Success Stories</p>
                <h1>Empowering patients with chronic disease</h1>
                <h3>Everyday our solution are helping thousands of individuals and healthcare providers</h3>
                <div className='pharm-elderly-success'>
                    <img
                        src={elderly}
                        width="100%"
                        height="100%"
                        alt='icon'
                    />
                    <div className='pharm-elderly-text'>
                        <h1>Patients journey solution</h1>
                        <p>
                            See how the MedPlan app is empowering patients to take control of 
                            their health with innovative feature
                        </p>
                        <div className='read-elderly'>
                            <img
                                src={black}
                                width="30px"
                                height="30px"
                                alt='icon'
                            />
                            Read story
                        </div>
                    </div>
                </div>
            </div>
            <div className='naijapharm-succes-story'>
                <h1>See more success stories.</h1>
                <div className='pharm-success-ctn'>
                    <div className='pharm-succes-props'>
                        <img
                            src={success1}
                            width="100%"
                            height="100%"
                            alt='profile'
                        />
                        <p>A Creative Journey to Success</p>                        
                        <div className='read-success'>
                            <img
                                src={black1}
                                width="30px"
                                height="30px"
                                alt='icon'
                            />
                            Read story
                        </div>
                    </div>
                    <div className='pharm-succes-props'>
                        <img
                            src={success2}
                            width="100%"
                            height="100%"
                            alt='profile'
                        />
                        <p>A Creative Journey to Success</p>                        
                        <div className='read-success'>
                            <img
                                src={black1}
                                width="30px"
                                height="30px"
                                alt='icon'
                            />
                            Read story
                        </div>
                    </div>
                    <div className='pharm-succes-props'>
                        <img
                            src={success3}
                            width="100%"
                            height="100%"
                            alt='profile'
                        />
                        <p>A Creative Journey to Success</p>                        
                        <div className='read-success'>
                            <img
                                src={black1}
                                width="30px"
                                height="30px"
                                alt='icon'
                            />
                            Read story
                        </div>
                    </div>
                    <div className='pharm-succes-props'>
                        <img
                            src={success4}
                            width="100%"
                            height="100%"
                            alt='profile'
                        />
                        <p>A Creative Journey to Success</p>                        
                        <div className='read-success'>
                            <img
                                src={black1}
                                width="30px"
                                height="30px"
                                alt='icon'
                            />
                            Read story
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default NaijaPharm